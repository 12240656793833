import React, { useEffect, useState } from 'react';
import SettingsRowBody from './SettingsRowBody';
import { useTranslation } from 'react-i18next';
import { useMachinesQuery } from '../../hooks/queries/useMachinesQuery';
import { Machine } from '../../services/apiSLTP/materials/machines';
import { User } from '../../services/apiSLTP/users';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateMachineMutation } from '../../hooks/mutations/useMachineMutation';
import { useMachinesTypesQuery } from '../../hooks/queries/useMachinesTypesQuery';
import { MachineType } from '../../services/apiSLTP/materials/machinesTypes';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateMachineModal from '../modals/UpdateMachineModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';

interface MachinesSettingsRowProps {
    users: User[];
    usersIsSuccess?: boolean;
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const MachinesSettingsRow: React.FC<MachinesSettingsRowProps> = ({ users, usersIsSuccess = false, universalFilter, setAddFtn }) => {
    const emptyMachine: Machine = {
        crudStatus: crudStatus.OK,
        _id: '',
        codeProC: '',
        designation: '',
        machineTypeId: {
            designation: '',
            _id: '',
        },
        userId: {
            _id: '',
            codeProC: '',
            lastName: '',
            firstName: '',
            jobId: '',
            username: '',
            profileId: '',
            latitude: 0,
            longitude: 0,
            color: '',
        },
    };

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedMachine, setSelectedMachine] = useState<Machine>(emptyMachine);
    const [isEditMode, setIsEditMode] = useState(false);
    const [machines, setMachines] = useState<Machine[]>([]);
    const [machinesTypes, setMachinesTypes] = useState<MachineType[]>([]);
    const { t } = useTranslation();
    const {user} = useAuth();

    const { data, isLoading, isError, isSuccess, refetch } = useMachinesQuery({});
    const {
        data: machinesTypesData,
        isLoading: machinesTypesIsLoading,
        isError: machinesTypesIsError,
        isSuccess: machinesTypesIsSuccess,
    } = useMachinesTypesQuery({});
    const {
        mutate: mutateMachine,
        isSuccess: isSuccessMachine,
        isError: isErrorMachine,
        isLoading: isLoadingMachine,
        error: errorMachine,
        data: dataMachine,
    } = useUpdateMachineMutation({
        onSuccess: (data, variables) => {
            {
                /*setMachines((prevMachines) => {
                const newMachines = [...prevMachines];
                const tmpIndex = newMachines.findIndex((value) => value._id === variables.machineId);
                newMachines[tmpIndex].crudStatus = crudStatus.DELETED;
                return newMachines;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating machine:', error.message);
        },
    });

    const filterMachines = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (machine: Machine) => {

            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(machine?.codeProC) ||
                matchesSearchTerm(machine?.designation) ||
                matchesSearchTerm(machine?.machineTypeId?.designation) ||
                matchesSearchTerm(machine?.userId?.firstName) ||
                matchesSearchTerm(machine?.userId?.lastName)
            );
        };
        return machines.filter(matchesFilter);
    };

    const sortedMachines = () => {
        return machines.sort((a, b) => {
            const designationA = a?.designation;
            const designationB = b?.designation;
            if (designationA < designationB) return -1;
            if (designationA > designationB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        if (data && isSuccess && machinesTypesData && machinesTypesIsSuccess) {
            setMachines(data);
            setMachinesTypes(machinesTypesData);
        }
    }, [data, machinesTypesData]);

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedMachine(emptyMachine);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('codeProC'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('type'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('user'))}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedMachines().map((machine, index) => (
                            <tr key={machine._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {machine.designation}
                                </td>
                                <td>{machine.codeProC}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedMachine(machine);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={machine.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && <Icon
                                        name="delete"
                                        onClick={() => {
                                            setSelectedMachine(machine);
                                            setShowDeleteModal(true);
                                        }}
                                        disabled={machine.crudStatus === crudStatus.DELETED}
                                    />}
                                </td>
                                <td>
                                    <div className="fx-r fx-justify-c">
                                        <StateBadge backgroundColor={getStatusColor(machine.crudStatus)} title={capitalizeFirstLetter(t(getCrudStatusInfos(machine.crudStatus)))} />
                                    </div>
                                </td>
                                <td>{machine.machineTypeId.designation}</td>
                                <td>
                                    {machine.userId.firstName} {machine.userId.lastName}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateMachineModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    isEditMode={isEditMode}
                    machine={selectedMachine}
                    machines={machines}
                    setMachines={setMachines}
                    users={users}
                    machineTypes={machinesTypes}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateMachine({ machineId: selectedMachine._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedMachine.codeProC}
                />
            )}
        </div>
    );
};

export default MachinesSettingsRow;
