import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import { ChromePicker, ColorResult } from 'react-color';
import ModalBody from './ModalBody';
import { useTranslation } from 'react-i18next';
import { getStateInfos, reportState } from '../../utils/enums';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { getStateColor } from '../../utils/colors';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
}

const InfosColorsModal: React.FC<ModalProps> = ({ open = false, handleClose = () => {} }) => {
    const { t } = useTranslation();

    return (
        <ModalBody closeText={t('ok')} open={open} isOkVisible={false} okText="" handleClose={handleClose} title={t('colors')}>
            <div>
                {[reportState.NEEDS, reportState.NEEDSFILLED, reportState.NEEDSSENT, reportState.NEEDSIMPORTED].map((state) => (
                    <div key={state} className="fx-r fx-items-c">
                        <div style={{ backgroundColor: getStateColor(state), width: 15, height: 15, borderRadius: 8, marginRight: 10 }} />
                        <div>{capitalizeFirstLetter(t(getStateInfos(state)))}</div>
                    </div>
                ))}
            </div>
            <div className='h-1px w-100 bg-gray m-t-10px m-b-10px' />
            <div>
                {[reportState.PLANNING, reportState.PLANNINGSENT].map((state) => (
                    <div key={state} className="fx-r fx-items-c">
                        <div style={{ backgroundColor: getStateColor(state), width: 15, height: 15, borderRadius: 8, marginRight: 10 }} />
                        <div>{capitalizeFirstLetter(t(getStateInfos(state)))}</div>
                    </div>
                ))}
            </div>
            <div className='h-1px w-100 bg-gray m-t-10px m-b-10px' />
            <div>
                {[reportState.REPORT, reportState.REPORTFILLED].map((state) => (
                    <div key={state} className="fx-r fx-items-c">
                        <div style={{ backgroundColor: getStateColor(state), width: 15, height: 15, borderRadius: 8, marginRight: 10 }} />
                        <div>{capitalizeFirstLetter(t(getStateInfos(state)))}</div>
                    </div>
                ))}
            </div>
        </ModalBody>
    );
};

export default InfosColorsModal;
