import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLoginMutation } from '../hooks/mutations/useLoginMutation';
import { LoginResponse } from '../services/apiSLTP/auth';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../utils/capitalize';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '../components/Icon';
import CustomButton from '../components/CustomButton';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const { login, isAuthenticated, authIsLoading } = useAuth();
    const { t } = useTranslation();

    const { mutate, isSuccess, isError, isLoading, error, data } = useLoginMutation({
        onSuccess: (data: LoginResponse) => {
            login(username);
            window.location.replace('/');
        },
        onError: (error: Error) => {
            console.error('Login error', error.message);
        },
    });

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        mutate({ username, password });
        setUsername('');
        setPassword('');
    };

    return (
        <div className="fx-c w-100 bg-black h-100 fx-justify-c" style={{ position: 'fixed', marginLeft: -20, marginRight: -20 }}>
            <div className="fx-c fx-items-c">
                <img width={250} src={require('../assets/images/my-sltp-transp.png')} />
                <form onSubmit={handleSubmit} className="pad-t-15px">
                    <div className="w-400px pad-5px">
                        <TextField
                            required
                            fullWidth
                            id="username"
                            label={capitalizeFirstLetter(t('userlogin'))}
                            name="username"
                            autoFocus
                            value={username}
                            onChange={handleUsernameChange}
                            autoComplete="off"
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                },
                                '& .MuiFormLabel-root': {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                },
                                '& .MuiInputBase-root': {
                                    borderRadius: '5px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                        borderWidth: '2px',
                                    },
                                },
                                '& .MuiFormLabel-root.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        />
                    </div>
                    <div className="w-400px pad-5px">
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label={capitalizeFirstLetter(t('password'))}
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            autoComplete="new-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon name={showPassword ? 'visibility' : 'visibilityOff'} color="white" onClick={handleClickShowPassword} />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                },
                                '& .MuiFormLabel-root': {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                },
                                '& .MuiInputBase-root': {
                                    borderRadius: '5px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                        borderWidth: '2px',
                                    },
                                },
                                '& .MuiFormLabel-root.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        />
                    </div>
                    <div className="w-100 fx-r fx-justify-c pad-t-15px">
                        <CustomButton type="submit">{t('login')}</CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
