import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from '../../services/apiSLTP/sites/entities';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateEntityMutation } from '../../hooks/mutations/useEntityMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateEntityModal from '../modals/UpdateEntityModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import { useEntitiesQuery } from '../../hooks/queries/useEntitiesQuery';

interface EntitiesSettingsRowProps {
    setAddFtn: (ftn: () => void) => void;
}

const EntitiesSettingsRow: React.FC<EntitiesSettingsRowProps> = ({ setAddFtn }) => {
    const {
        data: entities,
        isLoading: isEntitiesLoading,
        isError: isEntitiesError,
        isSuccess: isEntitiesSuccess,
        refetch: refetchEntities,
    } = useEntitiesQuery({});

    const emptyEntity: Entity = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState<Entity>(emptyEntity);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const {
        mutate: mutateEntity,
        isSuccess: isSuccessEntity,
        isError: isErrorEntity,
        isLoading: isLoadingEntity,
        error: errorEntity,
        data: dataEntity,
    } = useUpdateEntityMutation({
        onSuccess: (data, variables) => {
            refetchEntities();
        },
        onError: (error) => {
            console.error('Error updating entity:', error.message);
        },
    });

    const sortedEntities = () => {
        return (
            entities?.sort((a, b) => {
                const typeA = a?.designation;
                const typeB = b?.designation;
                if (typeA < typeB) return -1;
                if (typeA > typeB) return 1;
                const designationA = a.designation;
                const designationB = b.designation;
                if (designationA < designationB) return -1;
                if (designationA > designationB) return 1;
                return 0;
            }) || []
        );
    };

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedEntity(emptyEntity);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-30px pos-sy left-0 4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedEntities().map((entity, index, array) => (
                            <tr key={entity._id}>
                                <td scope="row">{entity.designation}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedEntity(entity);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={entity.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedEntity(entity);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={entity.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <div className="fx-r fx-justify-c">
                                        <StateBadge backgroundColor={getStatusColor(entity.crudStatus)} title={capitalizeFirstLetter(t(getCrudStatusInfos(entity.crudStatus)))} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateEntityModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    entity={selectedEntity}
                    isEditMode={isEditMode}
                    refetch={refetchEntities}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateEntity({ entityId: selectedEntity._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedEntity?.designation}
                />
            )}
        </div>
    );
};

export default EntitiesSettingsRow;
