import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface Dump {
    _id: string;
    designation: string;
    volume: number;
    codeProC: string;
    crudStatus: number;
    order: number;
}

export interface DumpsResponse {
    message: string;
    data: Dump[];
}

export const getAllDumps = async (): Promise<DumpsResponse> => {
    try {
        const response: AxiosResponse<DumpsResponse> = await apiClient.get('/dumps');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateDumpBody {
    designation?: string;
    crudStatus?: number;
    volume?: number;
    codeProC?: string;
    order?: number;
}

export interface DumpResponse {
    message: string;
    data: Dump;
}

export const updateDump = async (loadNatureId: string, data: UpdateDumpBody): Promise<DumpResponse> => {
    try {
        const response: AxiosResponse<DumpResponse> = await apiClient.patch('/dumps/' + loadNatureId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateDumpBody {
    designation: string;
    codeProC: string;
    volume: number;
    order?: number;
}

export const createDump = async (data: CreateDumpBody): Promise<DumpResponse> => {
    try {
        const response: AxiosResponse<DumpResponse> = await apiClient.post('/dumps', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
