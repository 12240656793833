import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToolsQuery } from '../../hooks/queries/useToolsQuery';
import { Tool } from '../../services/apiSLTP/materials/tools';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useToolsTypesQuery } from '../../hooks/queries/useToolsTypesQuery';
import { ToolType } from '../../services/apiSLTP/materials/toolsTypes';
import { useUpdateToolMutation } from '../../hooks/mutations/useToolMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateToolModal from '../modals/UpdateToolModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';

interface ToolsSettingsRowProps {
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const ToolsSettingsRow: React.FC<ToolsSettingsRowProps> = ({ universalFilter, setAddFtn }) => {
    const emptyTool: Tool = {
        _id: '',
        codeProC: '',
        crudStatus: crudStatus.OK,
        designation: '',
        toolTypeId: { _id: '', designation: '' },
    };
    const [tools, setTools] = useState<Tool[]>([]);
    const [toolsTypes, setToolsTypes] = useState<ToolType[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTool, setSelectedTool] = useState<Tool>(emptyTool);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const { data, isLoading, isError, isSuccess, refetch } = useToolsQuery({});
    const {
        data: toolsTypesData,
        isLoading: toolsTypesIsLoading,
        isError: toolsTypesIsError,
        isSuccess: toolsTypesIsSuccess,
    } = useToolsTypesQuery({});
    const {
        mutate: mutateTool,
        isSuccess: isSuccessTool,
        isError: isErrorTool,
        isLoading: isLoadingTool,
        error: errorTool,
        data: dataTool,
    } = useUpdateToolMutation({
        onSuccess: (data, variables) => {
            {
                /*setTools((prevTools) => {
                const newTools = [...prevTools];
                const tmpIndex = newTools.findIndex((value) => value._id === variables.toolId);
                newTools[tmpIndex].crudStatus = crudStatus.DELETED;
                return newTools;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating tool:', error.message);
        },
    });

    const filterTools = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (tool: Tool) => {
            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(tool?.codeProC) ||
                matchesSearchTerm(tool?.designation) ||
                matchesSearchTerm(tool?.toolTypeId?.designation)
            );
        };

        return tools.filter(matchesFilter);
    };

    const sortedTools = () => {
        return tools.sort((a, b) => {
            const typeA = a.toolTypeId?.designation;
            const typeB = b.toolTypeId?.designation;
            if (typeA < typeB) return -1;
            if (typeA > typeB) return 1;
            const designationA = a.designation;
            const designationB = b.designation;
            if (designationA < designationB) return -1;
            if (designationA > designationB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        if (data && isSuccess && toolsTypesData && toolsTypesIsSuccess) {
            setTools(data);
            setToolsTypes(toolsTypesData);
        }
    }, [data, toolsTypesData]);

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedTool(emptyTool);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('codeProC'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('type'))}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedTools().map((tool, index, array) => (
                            <tr key={tool._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {tool.designation}
                                </td>
                                <td>{tool.codeProC}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedTool(tool);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={tool.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedTool(tool);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={tool.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <div className="fx-r fx-justify-c">
                                        <StateBadge backgroundColor={getStatusColor(tool.crudStatus)} title={capitalizeFirstLetter(t(getCrudStatusInfos(tool.crudStatus)))} />
                                    </div>
                                </td>
                                <td>{tool.toolTypeId?.designation}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateToolModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    tool={selectedTool}
                    tools={tools}
                    setTools={setTools}
                    isEditMode={isEditMode}
                    toolsTypes={toolsTypes}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateTool({ toolId: selectedTool._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedTool?.codeProC}
                />
            )}
        </div>
    );
};

export default ToolsSettingsRow;
