import React, { useEffect, useState } from 'react';
import { useActivitiesByDateQuery } from '../../hooks/queries/useActivitiesQuery';
import { useTranslation } from 'react-i18next';
import {
    cancelActivities,
    cancelActivity,
    DataItem,
    duplicateActivities,
    duplicateActivity,
    importActivitiesByDate,
} from '../../services/apiSLTP/activities';
import {
    Site,
    UserItem,
    deleteActivity,
    getActivitiesTypes,
    getSites,
    getUsersBetweenPermsLevel,
    updateActivity,
} from '../../services/apiSLTP/planning';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../../utils/capitalize';
import { getStateInfos, localStorageKeys, permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../../utils/enums';
import ActivityCell from '../cells/ActivityCell';
import StartTimeCell from '../cells/StartTimeCell';
import ObservationsCell from '../cells/ObservationsCell';
import MachinesCell from '../cells/MachinesCell';
import RentalsCell from '../cells/RentalsCell';
import TempWorkersCell from '../cells/TempWorkersCell';
import SubContractorsCell from '../cells/SubContractorsCell';
import ToolsCell from '../cells/ToolsCell';
import CreateActivityModal from '../modals/CreateActivityModal';
import PersonCell from '../cells/PersonCell';
import CalendarModal from '../modals/CalendarModal';
import { getInOneDayDate, getOneDayAgoDate, getTomorrowDate, isRollbackPossible } from '../../utils/dates';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from '../../hooks/useAuth';
import { getStateColor, getTextColor } from '../../utils/colors';
import CustomToggle from '../CustomToggle';
import { updateReportState } from '../../services/apiSLTP/dailyReports/reports';
import TruckIcon from '../TruckIcon';
import { useTrucksQuery } from '../../hooks/queries/useTrucksQuery';
import { Truck } from '../../services/apiSLTP/materials/trucks';
import ColorBar from '../ColorBar';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../../utils/localStorage';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';
import Header from '../Header';
import StateBadge from '../StateBadge';
import Icon from '../Icon';
import TrucksPlannedCell from '../cells/TrucksPlannedCell';
import RemainingPeopleModal from '../modals/RemainingPeopleModal';
import { useKeyPress } from '@xyflow/react';
import RemainingMaterialModal from '../modals/RemainingMaterialModal';

interface AmplirollTableRowsProps {
    activities: DataItem[];
    refetch: () => void;
    currentDate: Date;
    universalFilter: string;
    trucks: Truck[];
    selectedTab: number;
    sites: Site[];
    usersByPermsLevel: UserItem[];
    activitiesTypes: { label: string; value: string }[];
}

const AmplirollTableRows: React.FC<AmplirollTableRowsProps> = ({
    activities,
    refetch,
    currentDate,
    universalFilter,
    trucks,
    selectedTab,
    sites,
    usersByPermsLevel,
    activitiesTypes,
}) => {
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [selectedSiteId, setSelectedSiteId] = useState('');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState<Site | null>(null);

    const { user } = useAuth();
    const { t } = useTranslation();
    const ctrlKeyPressed = useKeyPress(['Control', 'Shift']);

    const {
        data: dataTrucks,
        isLoading: isLoadingTrucks,
        isError: isErrorTrucks,
        isSuccess: isSuccessTrucks,
        refetch: refetchTrucks,
    } = useTrucksQuery({});

    const handleDeleteActivity = async (activityId: string) => {
        const response = await deleteActivity(activityId);
        refetch();
    };

    const submitData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.PLANNING, reportState.REPORT);
        if (!activityId.length) {
            await duplicateActivities(currentDate.toISOString(), reportState.PLANNING, stepModes.PLANNING, stepModes.REPORT, reportState.REPORT);
        } else {
            await duplicateActivity(activityId, stepModes.REPORT, reportState.REPORT);
        }
        refetch();
    };

    const rollBackData = async (activityId: string) => {
        if (!activityId.length) {
            await cancelActivities(currentDate.toISOString(), reportState.REPORT, stepModes.REPORT, stepModes.PLANNING, reportState.PLANNING);
        } else {
            await cancelActivity(activityId, reportState.REPORT, stepModes.REPORT);
        }
        refetch();
    };

    const handleActivityState = async (activityId: string, reportId: string, state: number) => {
        await updateActivity(activityId, { activityState: state });
        if (state === reportState.REPORT) await updateReportState(reportId, state);
        refetch();
    };

    const handleActivityTruckManager = async (id: string, isTruckManager: boolean, siteId: string) => {
        await updateActivity(id, { isTruckManager });
        const currentTruckManager = activities.find((value) => value.siteId._id === siteId && value.isTruckManager);
        if (currentTruckManager) await updateActivity(currentTruckManager._id, { isTruckManager: false });
        refetch();
    };

    const checkEditableRow = (activity: DataItem) => {
        if (activity.activityState <= reportState.PLANNINGSENT) return false;
        return true;
    };

    const filterAmplirolls = () => {
        return activities
            .filter((activity) =>
                dataTrucks?.find(
                    (truck) =>
                        truck.userId._id === activity.reportId.userId._id &&
                        (truck.truckTypeId.designation === 'Ampliroll' || truck.truckTypeId.designation === 'Porte-char')
                )
            )
            .sort((a, b) => {
                const truckA = dataTrucks?.find(
                    (truck) =>
                        truck.userId._id === a.reportId.userId._id &&
                        (truck.truckTypeId.designation === 'Ampliroll' || truck.truckTypeId.designation === 'Porte-char')
                );
                const truckB = dataTrucks?.find(
                    (truck) =>
                        truck.userId._id === b.reportId.userId._id &&
                        (truck.truckTypeId.designation === 'Ampliroll' || truck.truckTypeId.designation === 'Porte-char')
                );
                if ((truckA?._id || '') > (truckB?._id || '')) return -1;
                if ((truckA?._id || '') < (truckB?._id || '')) return 1;

                if (a.startTime > b.startTime) return 1;
                if (a.startTime < b.startTime) return -1;
                return 0;
            });
    };
    console.log(filterAmplirolls());

    const filterUsers = (activity: DataItem) => {
        if (ctrlKeyPressed) {
            return usersByPermsLevel.filter((user) => user.profileId.permsLevel <= permissionLevels.CONDUC);
        } else {
            return usersByPermsLevel.filter((user) => user.profileId.permsLevel === activity.reportId.userId.profileId.permsLevel);
        }
    };

    useEffect(() => {
        if (user) {
            if (user?.profileId.permsLevel > permissionLevels.PLANNING) window.location.replace('/');
        }
    }, [user]);

    return (
        <>
            {filterAmplirolls().map((activity, index, array) => (
                <>
                    {!(index > 0 && array[index - 1].reportId.userId._id === activity.reportId.userId._id) && (
                        <tr className="h-30px" id="fu-exclude">
                            <td className="pos-sy left-0 pad-0-imp" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }} colSpan={12}>
                                <div className="fx-r">
                                    <div className="fx-r fx-items-c pos-sy left-0 text-white">
                                        <TruckIcon type={'Ampliroll'} dark />
                                        <div>Ampliroll {activity.reportId.userId.firstName} {activity.reportId.userId.lastName}</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                    <tr
                        key={activity._id + 'ampliroll'}
                        className="row"
                        style={{
                            borderTopWidth: !(index > 0 && array[index - 1].reportId.userId._id === activity.reportId.userId._id) ? '1px' : '',
                        }}
                    >
                        <td className="pos-sy left-0 pad-0-imp z-idx-2 h-100">
                            <ColorBar
                                backgroundColor={activity.reportId.userId?.color || 'black'}
                                isPaddingTop={!(index > 0 && array[index - 1].reportId.userId._id === activity.reportId.userId._id)}
                                isPaddingBottom={!(index < array.length - 1 && array[index + 1].reportId.userId._id === activity.reportId.userId._id)}
                                title={
                                    activity.siteId?.conducId?._id
                                        ? capitalizeFirstLetter(activity.siteId?.conducId?.firstName) +
                                          ' ' +
                                          activity.siteId?.conducId?.lastName.toUpperCase()
                                        : ''
                                }
                            />
                        </td>
                        <td className="pos-sy left-30px z-idx-2">
                            <PersonCell
                                activity={activity}
                                activityIndex={index}
                                users={usersByPermsLevel}
                                activities={array}
                                disabled={!checkEditableRow(activity)}
                                refetch={() => {
                                    refetch();
                                }}
                            />
                        </td>
                        <td className="pos-sy left-230px z-idx-2">
                            <StartTimeCell activity={activity} activityIndex={index} disabled={!checkEditableRow(activity)} refetch={refetch} />
                        </td>
                        <td className="pos-sy left-310px z-idx-2">
                            {!(index > 0 && array[index - 1].siteId._id === activity.siteId._id) && (
                                <>
                                    <div className="font-w-600">
                                        {activity.siteId.name.includes(' - ')
                                            ? activity.siteId.name.split(' - ').slice(1).join(' - ') || ''
                                            : activity.siteId.name}
                                    </div>
                                    <div className="fx-items-c fx-r">
                                        <Icon
                                            name="infoSharp"
                                            onClick={() => {
                                                setSelectedAddress(activity.siteId);
                                                setShowLocationModal(true);
                                            }}
                                            title={capitalizeFirstLetter(t('siteInformations'))}
                                        />
                                        <Icon
                                            name="addCircle"
                                            onClick={() => {
                                                setSelectedSiteId(activity.siteId._id);
                                                setShowActivityModal(true);
                                            }}
                                            title={capitalizeFirstLetter(t('add'))}
                                        />
                                        <div className="pad-l-5px one-line">
                                            {capitalizeFirstLetter(activity.siteId?.conducId?.firstName)} {activity.siteId?.conducId?.lastName}
                                        </div>
                                    </div>
                                </>
                            )}
                        </td>

                        <td>
                            <MachinesCell
                                activity={activity}
                                activityIndex={index}
                                activities={array}
                                disabled={!checkEditableRow(activity)}
                                refetch={() => refetch()}
                            />
                        </td>
                        <td>
                            <ToolsCell
                                activity={activity}
                                activityIndex={index}
                                activities={array}
                                disabled={!checkEditableRow(activity)}
                                refetch={() => refetch()}
                            />
                        </td>
                        <td>
                            <RentalsCell
                                activity={activity}
                                activityIndex={index}
                                activities={array}
                                disabled={!checkEditableRow(activity)}
                                refetch={() => refetch()}
                            />
                        </td>

                        <td>
                            <TrucksPlannedCell
                                activity={activity}
                                activityIndex={index}
                                disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                refetch={() => refetch()}
                                type="8x4"
                            />
                        </td>
                        <td>
                            <TrucksPlannedCell
                                activity={activity}
                                activityIndex={index}
                                disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                refetch={() => refetch()}
                                type="Semie"
                            />
                        </td>
                        <td>
                            <TrucksPlannedCell
                                activity={activity}
                                activityIndex={index}
                                disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                refetch={() => refetch()}
                                type="Ampliroll"
                            />
                        </td>
                        <td>
                            <ObservationsCell activity={activity} activityIndex={index} disabled={!checkEditableRow(activity)} refetch={refetch} />
                        </td>
                        <td>
                            <TempWorkersCell
                                activity={activity}
                                activityIndex={index}
                                activities={array}
                                disabled={!checkEditableRow(activity)}
                                refetch={() => refetch()}
                            />
                        </td>
                        <td>
                            <SubContractorsCell
                                activity={activity}
                                activityIndex={index}
                                activities={array}
                                disabled={!checkEditableRow(activity)}
                                refetch={() => refetch()}
                            />
                        </td>
                        <td>
                            <div className="fx-r fx-justify-c">
                                {activity.activityTypeId._id !== truckRideActivityTypeId ? (
                                    <CustomToggle
                                        checked={activity.isTruckManager}
                                        onChange={(checked) => handleActivityTruckManager(activity._id, checked, activity.siteId._id)}
                                        disabled={!checkEditableRow(activity)}
                                        title={capitalizeFirstLetter(activity.isTruckManager ? t('yes') : t('no'))}
                                    />
                                ) : (
                                    <TruckIcon
                                        type={
                                            (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)?.truckTypeId.designation ||
                                                '') as 'Semie' | 'Ampliroll' | '8x4' | 'Porte-char' | ''
                                        }
                                    />
                                )}
                            </div>
                        </td>
                        <td className="row-cell-activity">
                            <ActivityCell
                                activitiesTypes={activitiesTypes}
                                activity={activity}
                                refetch={refetch}
                                disabled={!checkEditableRow(activity)}
                            />
                        </td>
                        <td>
                            <div className="fx-r fx-justify-c">
                                <StateBadge
                                    title={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                                    backgroundColor={getStateColor(activity.activityState)}
                                />
                            </div>
                        </td>
                        <td>
                            {activity.activityState === reportState.PLANNINGSENT && (
                                <Icon name="resetTv" onClick={() => rollBackData(activity._id)} />
                            )}
                            {activity.activityState === reportState.PLANNING && (
                                <Icon name="verticalAlignTop" onClick={() => submitData(activity._id)} />
                            )}

                            {activity.activityState === reportState.PLANNING && (
                                <Icon name="deleteForever" onClick={() => handleDeleteActivity(activity._id)} color="crimson" />
                            )}
                        </td>
                    </tr>
                </>
            ))}

            {showActivityModal && (
                <CreateActivityModal
                    activitiesTypes={activitiesTypes}
                    open={showActivityModal}
                    handleClose={() => setShowActivityModal(false)}
                    sites={sites.map((type) => {
                        return { label: type.name, value: type._id };
                    })}
                    users={usersByPermsLevel}
                    initDate={new Date(currentDate.setHours(7, 0)).toISOString()}
                    refetch={() => refetch()}
                    initSiteId={selectedSiteId}
                />
            )}
        </>
    );
};

export default AmplirollTableRows;
