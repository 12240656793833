import { crudStatus, materialState, reportState } from './enums';

export const customColors = {
    red: 'crimson',
    green: 'lightgreen',
    gray: 'lightgray',
    yellow: 'yellow',
    blue: 'blue',
    orange: 'orange',
    lightblue: 'lightblue',
    purple: 'purple',
    pink: 'pink',
    cyan: 'cyan',
    brown: 'brown'
};

export const getMaterialStateColor = (state: number) => {
    switch (state) {
        case materialState.NEW:
            return 'lightgreen';
        case materialState.REMOVE:
            return 'rgb(255,200,100) ';
        default:
            return '';
    }
};
export const getTextColor = (bgColor: string) => {
    const rgb = parseInt(bgColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance > 128 ? 'black' : 'white';
};

export const getStatusColor = (status: number) => {
    switch (status) {
        case crudStatus.OK:
            return customColors.green;
        case crudStatus.INACTIVE:
            return customColors.gray;
        case crudStatus.DELETED:
            return customColors.red;
        default:
            return customColors.yellow;
    }
};

export const getStateColor = (state: number) => {
    switch (state) {
        case reportState.REPORTFILLED:
            return 'green';
        case reportState.REPORT:
            return 'yellow';
        case reportState.PLANNINGSENT:
            return 'green';
        case reportState.PLANNING:
            return 'yellow';
        case reportState.NEEDSIMPORTED:
            return 'purple';
        case reportState.NEEDSSENT:
            return 'green';
        case reportState.NEEDSFILLED:
            return 'yellow';
        case reportState.NEEDS:
            return 'yellow';
        default:
            return 'yellow';
    }
};
