import React, { useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/Login';
import PlanningPage from '../pages/PlanningPage';
import AdminPage from '../pages/AdminPage';
import NotFoundPage from '../pages/NotFoundPage';
import ReportsHistory from '../pages/ReportsHistory';
import NeedsPage from '../pages/NeedsPage';
import HomePage from '../pages/HomePage';
import ProfilePage from '../pages/ProfilePage';
import OnLoadPage from '../pages/OnLoadPage';
import { useAuth } from '../hooks/useAuth';
import NavBar from './NavBar';

const Routers = () => {
    const { authIsLoading, isAuthenticated } = useAuth();

    return (
        <Router>
            {!authIsLoading && <NavBar />}
            <main className=" fx-fg-1 pad-l-10px pad-r-10px">
                <Routes>
                    {authIsLoading ? (
                        <Route path="*" element={<OnLoadPage />} />
                    ) : isAuthenticated ? (
                        <>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/needs" element={<NeedsPage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/planning" element={<PlanningPage />} />
                            <Route path="/supervision" element={<ReportsHistory />} />
                            <Route path="/admin" element={<AdminPage />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </>
                    ) : (
                        <>
                            <Route path="/login" element={<Login />} />
                            <Route path="*" element={<Navigate to="/login" replace />} />
                        </>
                    )}
                </Routes>
            </main>
        </Router>
    );
};

export default Routers;
