import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface TruckCompany {
    designation: string;
    _id: string;
    crudStatus: number;
}

interface TrucksCompaniesResponse {
    message: string;
    data: TruckCompany[];
}

export const getAllTrucksCompanies = async (): Promise<TrucksCompaniesResponse> => {
    try {
        const response: AxiosResponse<TrucksCompaniesResponse> = await apiClient.get('/truck-companies');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateTruckCompanyBody {
    designation?: string;
    crudStatus?: number;
}

export interface TruckCompanyResponse {
    message: string;
    data: TruckCompany;
}

export const updateTruckCompany = async (truckId: string, data: UpdateTruckCompanyBody): Promise<TruckCompanyResponse> => {
    try {
        const response: AxiosResponse<TruckCompanyResponse> = await apiClient.patch('/truck-companies/' + truckId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateTruckCompanyBody {
    designation: string;
}

export const createTruckCompany = async (data: CreateTruckCompanyBody): Promise<TruckCompanyResponse> => {
    try {
        const response: AxiosResponse<TruckCompanyResponse> = await apiClient.post('/truck-companies', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
