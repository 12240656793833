import React from 'react';
import { Switch } from '@mui/material';

interface CustomToggleProps {
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
    checked: boolean;
    title?: string;
    className?: string;
}

const CustomToggle: React.FC<CustomToggleProps> = ({ disabled = false, onChange = () => {}, checked, title, className = '' }) => {
    return <input type="checkbox" className={className} checked={checked} onChange={(event) => onChange(event.target.checked)} disabled={disabled} title={title} />;
};

export default CustomToggle;
