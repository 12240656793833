import React, { useState } from 'react';
import DropDown from '../DropDown';
import { DataItem } from '../../services/apiSLTP/activities';
import DroppableCell from '../DroppableCell';
import DraggableCell from '../DraggableCell';
import { createDailyTruckPlanned, updateDailyTruckPlanned } from '../../services/apiSLTP/dailyReports/dailyTrucksPlanned';
import ManageDailyTruckPlannedModal from '../modals/ManageDailyTruckPlannedModal';
import { deleteDailyTruckPlanned } from '../../services/apiSLTP/dailyReports/dailyTrucksPlanned';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';
import { createDailyTransfer } from '../../services/apiSLTP/dailyReports/dailyTransfers';
import { getTruckModeInfos, materialState, truckMode } from '../../utils/enums';
import { getMaterialStateColor } from '../../utils/colors';

interface TrucksPlannedCellProps {
    activity: DataItem;
    activityIndex: number;
    disabled?: boolean;
    refetch?: () => void;
    hiddenPlusIcon?: boolean;
    type?: 'Semie' | 'Ampliroll' | '8x4' | '';
}

const TrucksPlannedCell: React.FC<TrucksPlannedCellProps> = ({
    activity,
    activityIndex,
    disabled = false,
    refetch = () => {},
    hiddenPlusIcon = false,
    type = '',
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const itemType = 'TRUCKSPLANNED';
    const { t } = useTranslation();

    const handleTrucksPlannedChange = async (fromLineId: string, fromItemId: string, toLineId: string) => {
        if (fromLineId === toLineId) return;
        try {
            await updateDailyTruckPlanned(fromItemId, { activityId: toLineId });
            refetch();
        } catch (error) {
            console.error('Error updating truck planned:', error);
        }
    };

    const handleDeleteTruckPlanned = async (id: string, index: number) => {
        try {
            await deleteDailyTruckPlanned(id);
            refetch();
        } catch (error) {
            console.error('Error deleting truck planned:', error);
        }
    };

    const handleCreateDailyTruckPlanned = async (dailyTruckPlannedId: string) => {
        const selectedTruckPlanned = activity.dailyTrucksPlanned.find((value) => value._id === dailyTruckPlannedId);
        if (selectedTruckPlanned) {
            const response = await createDailyTruckPlanned({
                activityId: activity._id,
                trucksAmount: selectedTruckPlanned.trucksAmount,
                departureLoadNatureId: selectedTruckPlanned.departureLoadNatureId?._id,
                arrivalLoadNatureId: selectedTruckPlanned.arrivalLoadNatureId?._id,
                departureDumpId: selectedTruckPlanned.departureDumpId?._id,
                arrivalDumpId: selectedTruckPlanned.arrivalDumpId?._id,
                departureQuarryId: selectedTruckPlanned.departureQuarryId?._id,
                arrivalQuarryId: selectedTruckPlanned.arrivalQuarryId?._id,
                departureSiteId: selectedTruckPlanned.departureSiteId?._id,
                arrivalSiteId: selectedTruckPlanned.arrivalSiteId?._id,
                mode: selectedTruckPlanned.mode,
                ridesAmount: selectedTruckPlanned.ridesAmount,
                truckTypeId: selectedTruckPlanned.truckTypeId._id,
                truckCompanyId: selectedTruckPlanned.truckCompanyId?._id,
            });
            for (const transfer of selectedTruckPlanned.transfers) {
                await createDailyTransfer({
                    dailyTrucksPlannedId: response.data._id,
                    state: transfer.state,
                    machineId: transfer.machineId?._id,
                    quarryId: transfer.quarryId?._id,
                    rentalCompanyId: transfer.rentalCompanyId?._id,
                    rentalName: transfer.rentalName,
                    siteId: transfer.siteId?._id,
                    toolId: transfer.toolId?._id,
                });
            }
            refetch();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <DroppableCell
                dropId={{ lineId: activity._id }}
                moveCell={(fromLineId, fromItemId, toLineId) => handleTrucksPlannedChange(fromLineId, fromItemId, toLineId)}
                itemType={itemType}
                disabled={disabled}
            >
                <div className="fx-r pos-re">
                    <div>
                        {!hiddenPlusIcon && (
                            <Icon
                                name="addCircle"
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedId('');
                                }}
                                disabled={disabled}
                                title={capitalizeFirstLetter(t('add'))}
                            />
                        )}
                    </div>
                    <div className="w-250px pad-l-5px">
                        {activity.dailyTrucksPlanned
                            .filter(
                                (value) =>
                                    type === '' ||
                                    value.truckTypeId.designation === type ||
                                    (value.truckTypeId.designation.toLowerCase() === 'porte-char' && type === 'Ampliroll')
                            )
                            .map((truckPlanned, index) => (
                                <DraggableCell
                                    key={`${activityIndex}-${index}`}
                                    dragIds={{ itemId: truckPlanned._id, lineId: activity._id }}
                                    index={index}
                                    itemType={itemType}
                                    onDelete={() => handleDeleteTruckPlanned(truckPlanned._id, index)}
                                    disabled={disabled}
                                    onDuplicate={() => handleCreateDailyTruckPlanned(truckPlanned._id)}
                                    onDoubleClick={() => {
                                        setShowModal(true);
                                        setSelectedId(truckPlanned._id);
                                    }}
                                >
                                    <div
                                        title={`${truckPlanned.trucksAmount} - ${truckPlanned.truckTypeId.designation} - ${truckPlanned.truckCompanyId?.designation || ''} ${truckPlanned.truckCompanyId?.designation.toUpperCase() === 'SLTP' && truckPlanned.truckId?.userId && ' - ' + capitalizeFirstLetter(truckPlanned.truckId?.userId.firstName) + ' ' + truckPlanned.truckId?.userId.lastName.toUpperCase()}`}
                                        className="pad-l-5px w-100 fx-c"
                                    >
                                        <div className="one-line" style={{ fontWeight: 'bold' }}>
                                            {truckPlanned.trucksAmount}
                                            {' - '}
                                            {truckPlanned.truckTypeId.designation}
                                            {' - '}
                                            {truckPlanned.truckCompanyId?.designation || ''}
                                            {truckPlanned.truckCompanyId?.designation.toUpperCase() === 'SLTP' &&
                                                truckPlanned.truckId?.userId &&
                                                ' - ' +
                                                    capitalizeFirstLetter(truckPlanned.truckId?.userId.firstName) +
                                                    ' ' +
                                                    truckPlanned.truckId?.userId.lastName.toUpperCase()}
                                        </div>
                                        <div className="one-line" style={{ fontStyle: 'italic' }}>
                                            {truckPlanned.mode !== undefined && `${capitalizeFirstLetter(t(getTruckModeInfos(truckPlanned.mode)))} `}
                                            {truckPlanned.mode !== undefined && !!truckPlanned.ridesAmount && truckPlanned.ridesAmount > 0 && ' | '}
                                            {!!truckPlanned.ridesAmount &&
                                                truckPlanned.ridesAmount > 0 &&
                                                `${truckPlanned.ridesAmount} ${t('ride(s)')}`}
                                        </div>

                                        {[truckMode.APPRO, truckMode.POSE, truckMode.ROTATION, truckMode.DOUBLEFRET].includes(
                                            truckPlanned.mode === undefined ? -1 : truckPlanned.mode
                                        ) && (
                                            <div className="fx-r fx-items-c">
                                                <div className="h-100 fx-items-c fx-r">
                                                    <div className="w-2px m-l-5px m-r-5px" style={{ height: '80%', backgroundColor: '#5A5A5E' }} />
                                                </div>
                                                <div className="fx-c">
                                                    {(truckPlanned.departureDumpId || truckPlanned.departureLoadNatureId) && (
                                                        <div className="one-line" style={{ fontStyle: 'italic' }}>
                                                            {truckPlanned.departureDumpId &&
                                                                `${capitalizeFirstLetter(t('dump'))} ${truckPlanned.departureDumpId?.designation} `}
                                                            {truckPlanned.departureLoadNatureId &&
                                                                `${truckPlanned.departureLoadNatureId.designation}`}
                                                        </div>
                                                    )}
                                                    {(truckPlanned.departureSiteId || truckPlanned.departureQuarryId) && (
                                                        <div className="one-line">
                                                            <b>{t('from') + ' : '}</b>
                                                            {truckPlanned.departureSiteId && truckPlanned.departureSiteId?.name}
                                                            {truckPlanned.departureQuarryId && truckPlanned.departureQuarryId?.designation}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {[truckMode.EVAC, truckMode.ENLEVEMENT, truckMode.ROTATION, truckMode.DOUBLEFRET].includes(
                                            truckPlanned.mode === undefined ? -1 : truckPlanned.mode
                                        ) && (
                                            <div className="fx-r fx-items-c">
                                                <div className="h-100 fx-items-c fx-r">
                                                    <div className="w-2px m-l-5px m-r-5px" style={{ height: '80%', backgroundColor: '#5A5A5E' }} />
                                                </div>
                                                <div className="fx-c">
                                                    {(truckPlanned.arrivalDumpId?._id || truckPlanned.arrivalLoadNatureId?._id) && (
                                                        <div className="one-line" style={{ fontStyle: 'italic' }}>
                                                            {truckPlanned.arrivalDumpId &&
                                                                `${capitalizeFirstLetter(t('dump'))} ${truckPlanned.arrivalDumpId?.designation} `}
                                                            {truckPlanned.arrivalLoadNatureId && `${truckPlanned.arrivalLoadNatureId.designation}`}
                                                        </div>
                                                    )}
                                                    {(truckPlanned.arrivalSiteId || truckPlanned.arrivalQuarryId) && (
                                                        <div className="one-line">
                                                            <b>{t('to') + ' : '}</b>
                                                            {truckPlanned.arrivalSiteId && truckPlanned.arrivalSiteId?.name}
                                                            {truckPlanned.arrivalQuarryId && truckPlanned.arrivalQuarryId?.designation}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {truckPlanned.observations && (
                                            <div style={{ paddingTop: 2 }}>
                                                <div>{truckPlanned.observations && truckPlanned.observations}</div>
                                            </div>
                                        )}
                                        <div>
                                            {truckPlanned.mode === truckMode.TRANSFER &&
                                                truckPlanned.transfers.map((transfer) => (
                                                    <div
                                                        className="m-3px fx-c border-radius-5px smFont pad-l-3px"
                                                        style={{ backgroundColor: getMaterialStateColor(transfer.state) }}
                                                    >
                                                        <div>
                                                            {transfer.machineId?.designation ||
                                                                transfer.toolId?.designation ||
                                                                transfer.rentalName + ' ' + transfer.rentalCompanyId?.designation}
                                                        </div>
                                                        <div>
                                                            <b>{transfer.state === materialState.NEW ? t('from') + ' : ' : t('to') + ' : '}</b>
                                                            {transfer.siteId?.name || transfer.quarryId?.designation}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </DraggableCell>
                            ))}
                    </div>
                </div>
            </DroppableCell>
            {showModal && (
                <ManageDailyTruckPlannedModal
                    open={showModal}
                    activity={activity}
                    handleClose={() => setShowModal(false)}
                    refetch={refetch}
                    truckPlannedId={selectedId}
                    truckType={type}
                />
            )}
        </div>
    );
};

export default TrucksPlannedCell;
