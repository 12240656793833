import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cancelActivities, cancelActivity, DataItem, duplicateActivities, duplicateActivity } from '../../services/apiSLTP/activities';
import { Site, UserItem, deleteActivity, updateActivity } from '../../services/apiSLTP/planning';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../../utils/capitalize';
import { getStateInfos, localStorageKeys, permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../../utils/enums';
import StartTimeCell from '../cells/StartTimeCell';
import ObservationsCell from '../cells/ObservationsCell';
import CreateActivityModal from '../modals/CreateActivityModal';
import PersonCell from '../cells/PersonCell';
import { useAuth } from '../../hooks/useAuth';
import { getStateColor, getTextColor } from '../../utils/colors';
import CustomToggle from '../CustomToggle';
import TruckIcon from '../TruckIcon';
import { Truck } from '../../services/apiSLTP/materials/trucks';
import ColorBar from '../ColorBar';
import StateBadge from '../StateBadge';
import Icon from '../Icon';
import TrucksPlannedCell from '../cells/TrucksPlannedCell';
import RemainingPeopleModal from '../modals/RemainingPeopleModal';
import RemainingMaterialModal from '../modals/RemainingMaterialModal';
import InfosColorsModal from '../modals/InfosColorsModal';
import ModalBody from '../modals/ModalBody';

interface PlanningTrucksPageProps {
    sites: Site[];
    currentDate: Date;
    users: UserItem[];
    activitiesTypes: { label: string; value: string }[];
    trucks: Truck[];
    refetch: () => void;
    activities: DataItem[];
}

const PlanningTrucksPage: React.FC<PlanningTrucksPageProps> = ({ activitiesTypes, currentDate, refetch, sites, trucks, users, activities }) => {
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [selectedSiteId, setSelectedSiteId] = useState('');
    const [selectedAddress, setSelectedAddress] = useState<Site | null>(null);

    const { user } = useAuth();
    const { t } = useTranslation();

    const handleDeleteActivity = async (activityId: string) => {
        const response = await deleteActivity(activityId);
        refetch();
    };

    const submitData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.PLANNING, reportState.REPORT);
        if (!activityId.length) {
            await duplicateActivities(currentDate.toISOString(), reportState.PLANNING, stepModes.PLANNING, stepModes.REPORT, reportState.REPORT);
        } else {
            await duplicateActivity(activityId, stepModes.REPORT, reportState.REPORT);
        }
        refetch();
    };

    const rollBackData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.REPORT, reportState.PLANNING);
        if (!activityId.length) {
            await cancelActivities(currentDate.toISOString(), reportState.REPORT, stepModes.REPORT, stepModes.PLANNING, reportState.PLANNING);
        } else {
            await cancelActivity(activityId, reportState.REPORT, stepModes.REPORT);
        }
        refetch();
    };

    const handleActivityTruckManager = async (id: string, isTruckManager: boolean, siteId: string) => {
        await updateActivity(id, { isTruckManager });
        const currentTruckManager = activities?.find((value) => value.siteId._id === siteId && value.isTruckManager);
        if (currentTruckManager) await updateActivity(currentTruckManager._id, { isTruckManager: false });
        refetch();
    };

    const checkEditableRow = (activity: DataItem) => {
        if (activity.activityState <= reportState.PLANNINGSENT) return false;
        return true;
    };

    const setSpecificHour = () => {
        const date = new Date(currentDate);
        date.setHours(7, 0);
        return date.toISOString();
    };

    const filterAndSortTrucks = () => {
        return activities
            .filter((activity) => activity.activityTypeId._id === truckRideActivityTypeId)
            .sort((a, b) => {
                const truckA = trucks.find((truck) => truck.userId._id === a.reportId.userId._id)?.truckTypeId._id || '';
                const truckB = trucks.find((truck) => truck.userId._id === b.reportId.userId._id)?.truckTypeId._id || '';
                if (truckA < truckB) return -1;
                if (truckA > truckB) return 1;
                if (a.reportId.userId._id < b.reportId.userId._id) return -1;
                if (a.reportId.userId._id > b.reportId.userId._id) return 1;
                if (new Date(a.startTime).getTime() < new Date(b.startTime).getTime()) return -1;
                if (new Date(a.startTime).getTime() > new Date(b.startTime).getTime()) return 1;
                return 0;
            });
    };

    const compareTypePreviousLine = (index: number, array: DataItem[], activity: DataItem) => {
        return (
            index > 0 &&
            (trucks.find((truck) => truck.userId._id === array[index - 1].reportId.userId._id)?.truckTypeId._id || '') !==
                (trucks.find((truck) => truck.userId._id === activity.reportId.userId._id)?.truckTypeId._id || '')
        );
    };

    return (
        <div className="planning">
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4">
                                {capitalizeFirstLetter(t(''))}
                            </th>
                            <th scope="col" className="w-200px  pos-sy left-30px z-idx-4">
                                {capitalizeFirstLetter(t('site'))}
                            </th>
                            <th scope="col" className="w-75px ">
                                {capitalizeFirstLetter(t('start'))}
                            </th>
                            <th scope="col" className="w-200px left-230px z-idx-4">
                                {capitalizeFirstLetter(t('person'))}
                            </th>
                            <th scope="col" className="w-300px">
                                {capitalizeFirstLetter(t('8x4'))}
                            </th>
                            <th scope="col" className="w-300px">
                                {capitalizeFirstLetter(t('Semie'))}
                            </th>
                            <th scope="col" className="w-300px ">
                                {capitalizeFirstLetter(t('Ampliroll'))}
                            </th>
                         
                            <th scope="col" className="w-300px ">
                                {capitalizeFirstLetter(t('observations'))}
                            </th>
                            <th scope="col" className="w-75px ">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('activityStatus'))}</div>
                            </th>
                            <th scope="col" className="w-100px ">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {!filterAndSortTrucks()?.length && (
                            <tr className="row-empty">
                                <td className="cell-empty" colSpan={19}>
                                    {capitalizeFirstLetter(t('noActivity'))}
                                </td>
                            </tr>
                        )}
                        {filterAndSortTrucks().map((activity, index, array) => (
                            <>
                                {(index === 0 || compareTypePreviousLine(index, array, activity)) && (
                                    <tr className="" id="fu-exclude">
                                        <td className="pos-sy left-0 pad-0-imp" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }} colSpan={12}>
                                            <div className='fx-r'>
                                                <div className="fx-r fx-items-c pos-sy left-0 text-white">
                                                    <TruckIcon
                                                        type={
                                                            (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)?.truckTypeId
                                                                .designation || '') as 'Semie' | 'Ampliroll' | '8x4' |  ''
                                                        }
                                                        dark
                                                    />
                                                    <div>
                                                        {
                                                            (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)?.truckTypeId
                                                                .designation || '') as 'Semie' | 'Ampliroll' | '8x4' |  ''
                                                        }
                                                </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                <tr
                                    key={activity._id}
                                    style={{
                                        borderTopWidth: !(index > 0 && array[index - 1].reportId.userId._id === activity.reportId.userId._id)
                                            ? '1px'
                                            : '',
                                    }}
                                >
                                    <td className="pos-sy left-0 pad-0-imp z-idx-2">
                                        <ColorBar
                                            backgroundColor={activity.siteId?.conducId?.color || 'black'}
                                            isPaddingTop={
                                                !(
                                                    index > 0 &&
                                                    array[index - 1].siteId?._id === activity.siteId?._id &&
                                                    array[index - 1].reportId.userId._id === activity.reportId.userId._id
                                                )
                                            }
                                            isPaddingBottom={
                                                !(
                                                    index < array.length - 1 &&
                                                    array[index + 1].siteId?._id === activity.siteId?._id &&
                                                    array.length - 1 &&
                                                    array[index + 1].reportId.userId._id === activity.reportId.userId._id
                                                )
                                            }
                                            title={
                                                activity.siteId?.conducId?._id
                                                    ? capitalizeFirstLetter(activity.siteId?.conducId?.firstName) +
                                                      ' ' +
                                                      activity.siteId?.conducId?.lastName.toUpperCase()
                                                    : ''
                                            }
                                        />
                                    </td>
                                    <td className="pos-sy left-30px z-idx-2">
                                        <>
                                            <div className="font-w-600">
                                                {activity.siteId.name.includes(' - ')
                                                    ? activity.siteId.name.split(' - ').slice(1).join(' - ') || ''
                                                    : activity.siteId.name}
                                            </div>
                                            <div className="fx-items-c fx-r">
                                                <Icon
                                                    name="infoSharp"
                                                    onClick={() => {
                                                        setSelectedAddress(activity.siteId);
                                                        setShowLocationModal(true);
                                                    }}
                                                    title={capitalizeFirstLetter(t('siteInformations'))}
                                                />
                                                <Icon
                                                    name="addCircle"
                                                    onClick={() => {
                                                        setSelectedSiteId(activity.siteId._id);
                                                        setShowActivityModal(true);
                                                    }}
                                                    title={capitalizeFirstLetter(t('add'))}
                                                />
                                                <div className="pad-l-5px one-line">
                                                    {capitalizeFirstLetter(activity.siteId?.conducId?.firstName)}{' '}
                                                    {activity.siteId?.conducId?.lastName}
                                                </div>
                                            </div>
                                        </>
                                    </td>
                                    <td>
                                        <StartTimeCell
                                            activity={activity}
                                            activityIndex={index}
                                            refetch={refetch}
                                            disabled={!checkEditableRow(activity)}
                                        />
                                    </td>
                                    <td className="pos-sy left-230px z-idx-2">
                                        <PersonCell
                                            activity={activity}
                                            activityIndex={index}
                                            users={users}
                                            activities={array}
                                            disabled={!checkEditableRow(activity)}
                                            refetch={() => {
                                                refetch();
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <TrucksPlannedCell
                                            activity={activity}
                                            activityIndex={index}
                                            disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                            refetch={() => refetch()}
                                            type="8x4"
                                        />
                                    </td>
                                    <td>
                                        <TrucksPlannedCell
                                            activity={activity}
                                            activityIndex={index}
                                            disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                            refetch={() => refetch()}
                                            type="Semie"
                                        />
                                    </td>
                                    <td>
                                        <TrucksPlannedCell
                                            activity={activity}
                                            activityIndex={index}
                                            disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                            refetch={() => refetch()}
                                            type="Ampliroll"
                                        />
                                    </td>
    
                                    <td>
                                        <ObservationsCell
                                            activity={activity}
                                            activityIndex={index}
                                            disabled={!checkEditableRow(activity)}
                                            refetch={refetch}
                                        />
                                    </td>
                                    <td>
                                        <div className="fx-r fx-justify-c">
                                            <StateBadge
                                                title={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                                                backgroundColor={getStateColor(activity.activityState)}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        {activity.activityState === reportState.PLANNINGSENT &&
                                            activity.reportId.reportState !== reportState.REPORTFILLED && (
                                                <Icon
                                                    name="resetTv"
                                                    onClick={() => rollBackData(activity._id)}
                                                    title={capitalizeFirstLetter(t('takeBackActivity'))}
                                                />
                                            )}
                                        {activity.activityState === reportState.PLANNING && (
                                            <Icon
                                                name="verticalAlignTop"
                                                onClick={() => submitData(activity._id)}
                                                title={capitalizeFirstLetter(t('sendActivity'))}
                                            />
                                        )}
                                        {activity.activityState === reportState.PLANNING && (
                                            <Icon
                                                name="deleteForever"
                                                onClick={() => handleDeleteActivity(activity._id)}
                                                title={capitalizeFirstLetter(t('deleteActivity'))}
                                                color="crimson"
                                            />
                                        )}
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
            {showActivityModal && (
                <CreateActivityModal
                    activitiesTypes={activitiesTypes}
                    open={showActivityModal}
                    handleClose={() => setShowActivityModal(false)}
                    sites={sites.map((type) => {
                        return { label: type.name, value: type._id };
                    })}
                    users={users}
                    initDate={setSpecificHour()}
                    refetch={() => refetch()}
                    initSiteId={selectedSiteId}
                />
            )}
            {showLocationModal && (
                <ModalBody open={showLocationModal} handleClose={() => setShowLocationModal(false)} okText={t('ok')} title={selectedAddress?.name}>
                    <div>{selectedAddress?.address}</div>
                </ModalBody>
            )}
        </div>
    );
};

export default PlanningTrucksPage;
