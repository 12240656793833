export const datesBetween = (daysBefore: number, daysAfter: number) => {
    let dates: Date[] = [];
    let currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - daysBefore);
    startDate.setHours(12, 0, 0, 0);

    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + daysAfter);
    endDate.setHours(12, 0, 0, 0);

    while (startDate.getTime() <= endDate.getTime()) {
        dates.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
    }
    return dates;
};

export const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
};

export const getInOneDayDate = (date: Date) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    return newDate;
};

export const getOneDayAgoDate = (date: Date) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);
    return newDate;
};

export const getSpecificDateFromDate = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
};

export const isRollbackPossible = (date: string) => {
    const nowDate = new Date();
    const limitDate = new Date(date);
    limitDate.setHours(8, 0, 0, 0);
    return nowDate.getTime() < limitDate.getTime();
};

export const formatDate = (date: Date) => {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

export const formatDateToYYYYMMDD = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};
