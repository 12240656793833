import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';
import { User } from '../users';

export interface TruckType {
    designation: string;
    _id: string;
    crudStatus: number;
}

interface TrucksTypesResponse {
    message: string;
    data: TruckType[];
}

export const getAllTrucksTypes = async (): Promise<TrucksTypesResponse> => {
    try {
        const response: AxiosResponse<TrucksTypesResponse> = await apiClient.get('/trucks-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateTruckTypeBody {
    designation?: string;
    crudStatus?: number;
}

export interface TruckTypeResponse {
    message: string;
    data: TruckType;
}

export const updateTruckType = async (truckId: string, data: UpdateTruckTypeBody): Promise<TruckTypeResponse> => {
    try {
        const response: AxiosResponse<TruckTypeResponse> = await apiClient.patch('/trucks-types/' + truckId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateTruckTypeBody {
    designation: string;
}

export const createTruckType = async (data: CreateTruckTypeBody): Promise<TruckTypeResponse> => {
    try {
        const response: AxiosResponse<TruckTypeResponse> = await apiClient.post('/trucks', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
