import React, { useState } from 'react';
import { Modal, Button } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DropDown from '../DropDown';
import dayjs from 'dayjs';
import { DataItem } from '../../services/apiSLTP/activities';
import { createActivity, UserItem } from '../../services/apiSLTP/planning';
import { permissionLevels, reportState, stepModes } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { SelectChangeEvent } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';
import CreateActivityModal from './CreateActivityModal';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    sites: { label: string; value: string }[];
    users: UserItem[];
    activitiesTypes: { label: string; value: string }[];
    activities: DataItem[];
    initDate: string;
    refetch: () => void;
}

const RemainingPeopleModal: React.FC<ModalProps> = ({ open, handleClose, users, activitiesTypes, sites, activities, initDate, refetch }) => {
    const [selectedUserId, setSelectedUserId] = useState('');
    const [showActivityModal, setShowActivityModal] = useState(false);

    const { t } = useTranslation();

    const handleRemainingPeople = () => {
        handleClose();
    };

    const getRemainingUsers = () => {
        const activitiesUsers = activities.map((activity) => activity.reportId.userId._id);
        const sortedUsers = users.sort((a, b) => a.firstName.localeCompare(b.firstName));
        return {
            drivers: sortedUsers.filter((user) => !activitiesUsers.includes(user._id) && user.profileId.permsLevel === permissionLevels.CHAUFFEUR && !user.isSpecial),
            field: sortedUsers.filter((user) => !activitiesUsers.includes(user._id) && user.profileId.permsLevel === permissionLevels.TERRAIN && !user.isSpecial),
        };
    };

    return (
        <ModalBody
            closeText={t('cancel')}
            okText={t('ok')}
            title={t('remainingUsers')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                handleRemainingPeople();
            }}
            disabledOk={false}
        >
            <div className="fx-c h-300px w-100" style={{ overflowY: 'scroll' }}>
                <h3 className="m-b-5px">{capitalizeFirstLetter(t('site'))} :</h3>
                {getRemainingUsers().field.map((user) => (
                    <div key={user._id} className="fx-r w-300px fx-justify-sb bg-lightgray border-radius-5px pad-3px m-t-5px fx-items-c">
                        <div className="one-line">
                            {capitalizeFirstLetter(user.firstName)} {user.lastName.toUpperCase()}
                        </div>
                        <Icon
                            name="addCircle"
                            onClick={() => {
                                setSelectedUserId(user._id);
                                setShowActivityModal(true);
                            }}
                        />
                    </div>
                ))}
                <h3 className="m-b-5px">{capitalizeFirstLetter(t('trucks'))} :</h3>
                {getRemainingUsers().drivers.map((user) => (
                    <div key={user._id} className="fx-r w-300px fx-justify-sb bg-lightgray border-radius-5px pad-3px m-t-5px fx-items-c">
                        <div className="one-line">
                            {capitalizeFirstLetter(user.firstName)} {user.lastName.toUpperCase()}
                        </div>
                        <Icon
                            name="addCircle"
                            onClick={() => {
                                setSelectedUserId(user._id);
                                setShowActivityModal(true);
                            }}
                        />
                    </div>
                ))}
            </div>
            {showActivityModal && (
                <CreateActivityModal
                    activitiesTypes={activitiesTypes}
                    open={showActivityModal}
                    handleClose={() => setShowActivityModal(false)}
                    sites={sites}
                    users={users}
                    initDate={initDate}
                    refetch={() => refetch()}
                    initUserId={selectedUserId}
                />
            )}
        </ModalBody>
    );
};

export default RemainingPeopleModal;
