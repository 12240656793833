import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSitesQuery } from '../../hooks/queries/useSitesQuery';
import { Site } from '../../services/apiSLTP/planning';
import { User } from '../../services/apiSLTP/users';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useEntitiesQuery } from '../../hooks/queries/useEntitiesQuery';
import { Entity } from '../../services/apiSLTP/sites/entities';
import { useUpdateSiteMutation } from '../../hooks/mutations/useSiteMutation';
import UpdateSiteModal from '../modals/UpdateSiteModal';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import CustomToggle from '../CustomToggle';

interface SitesSettingsRowProps {
    users: User[];
    usersIsSuccess?: boolean;
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const SitesSettingsRow: React.FC<SitesSettingsRowProps> = ({ users, usersIsSuccess = false, universalFilter, setAddFtn }) => {
    const emptySite: Site = {
        _id: '',
        name: '',
        codeProC: '',
        address: '',
        conducId: { _id: '', firstName: '', jobId: { _id: '', designation: '', order: 0 }, lastName: '' },
        crudStatus: crudStatus.OK,
        entityId: '',
        stateProC: 0,
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedSite, setSelectedSite] = useState<Site>(emptySite);
    const [isEditMode, setIsEditMode] = useState(false);
    const [sites, setSites] = useState<Site[]>([]);
    const [entities, setEntities] = useState<Entity[]>([]);
    const { t } = useTranslation();
    const { user } = useAuth();

    const { data, isLoading, isError, isSuccess, refetch } = useSitesQuery({});
    const { data: entitiesData, isLoading: entitiesIsLoading, isError: entitiesIsError, isSuccess: entitiesIsSuccess } = useEntitiesQuery({});
    const {
        mutate: mutateSite,
        isSuccess: isSuccessSite,
        isError: isErrorSite,
        isLoading: isLoadingSite,
        error: errorSite,
        data: dataSite,
    } = useUpdateSiteMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating site:', error.message);
        },
    });

    const filterSites = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (site: Site) => {
            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(site?.name) ||
                matchesSearchTerm(site?.address) ||
                matchesSearchTerm(site?.codeProC) ||
                matchesSearchTerm(site?.conducId?.firstName) ||
                matchesSearchTerm(site?.conducId?.lastName) ||
                matchesSearchTerm(entities.find((entity) => entity._id === site.entityId)?.designation)
            );
        };
        return sites.filter(matchesFilter);
    };

    const sortedSites = () => {
        return sites.sort((a, b) => {
            const designationA = a?.name;
            const designationB = b?.name;
            if (designationA < designationB) return -1;
            if (designationA > designationB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        if (data && isSuccess && entitiesIsSuccess && entitiesData) {
            setSites(data);
            setEntities(entitiesData);
        }
    }, [data, entitiesData]);

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedSite(emptySite);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('codeProC'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                            <th scope="col" className="w-300px">
                                {capitalizeFirstLetter(t('address'))}
                            </th>
                            <th scope="col" className="w-200px">
                                {capitalizeFirstLetter(t('conduc'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('entity'))}
                            </th>
                            <th scope="col" className="w-50px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('favorite'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedSites().map((site, index, array) => (
                            <tr key={site._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {site.name}
                                </td>
                                <td>{site.codeProC}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedSite(site);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={site.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedSite(site);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={site.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <div className="fx-r fx-justify-c">
                                        <StateBadge
                                            backgroundColor={getStatusColor(site.crudStatus)}
                                            title={capitalizeFirstLetter(t(getCrudStatusInfos(site.crudStatus)))}
                                        />
                                    </div>
                                </td>
                                <td className="one-line" title={site.address}>
                                    {site.address}
                                </td>
                                <td>
                                    {site.conducId?.firstName} {site.conducId?.lastName}
                                </td>
                                <td>{entities.find((entity) => entity._id === site.entityId)?.designation}</td>
                                <td>
                                    <div className="fx-r fx-justify-c">
                                        <CustomToggle checked={site.isFavorite || false} disabled className="checkbox-star" />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateSiteModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    isEditMode={isEditMode}
                    site={selectedSite}
                    sites={sites}
                    setSites={setSites}
                    users={users}
                    entities={entities}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateSite({ siteId: selectedSite?._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedSite?.codeProC}
                />
            )}
        </div>
    );
};

export default SitesSettingsRow;
