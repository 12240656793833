import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import DropDown from '../DropDown';
import { getMachineTypes, getMachinesByMachineTypeId, createDailyMachine } from '../../services/apiSLTP/planning';
import { DataItem, updateDailyMachine } from '../../services/apiSLTP/activities';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { getMaterialStateInfos, materialState } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { useActiveMachinesQuery } from '../../hooks/queries/useMachinesQuery';
import RangeInput from '../RangeInput';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activity: DataItem;
    refetch: () => void;
    machineId: string;
}

const CreateDailyMachineModal: React.FC<ModalProps> = ({ open, handleClose, activity, refetch, machineId }) => {
    const { t } = useTranslation();
    const [currentMachineId, setCurrentMachineId] = useState(machineId);
    const [selectedMachineType, setSelectedMachineType] = useState({ label: '', value: '' });
    const [selectedMachine, setSelectedMachine] = useState({ label: '', value: '' });
    const [selectedState, setSelectedState] = useState<{
        label: string;
        value: string;
    }>({ label: capitalizeFirstLetter(t('present')), value: materialState.PRESENT.toString() });

    const states = [
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.NEW))), value: materialState.NEW.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.PRESENT))), value: materialState.PRESENT.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.REMOVE))), value: materialState.REMOVE.toString() },
    ];

    const { data: dataMachines } = useActiveMachinesQuery({});


    const postDailyMachine = async () => {
        const response = await createDailyMachine(activity._id, selectedMachine.value, parseInt(selectedState.value), true);
        setCurrentMachineId(response.data._id);
        refetch();
    };

    const patchDailyMachine = async () => {
        const response = await updateDailyMachine(currentMachineId, {
            machineId: selectedMachine.value,
            state: parseInt(selectedState.value),
            createTransfer: true
        });
        refetch();
    };

    useEffect(() => {

        const dailyMachine = activity.dailyMachines.find((value) => value._id === machineId);
        if (dailyMachine) {
            setSelectedMachine({ label: dailyMachine.machineId?.designation || '', value: dailyMachine.machineId?._id || '' });
            setSelectedState({ label: capitalizeFirstLetter(t(getMaterialStateInfos(dailyMachine.state))) || '', value: dailyMachine.state.toString() || '' });
        }
    }, []);


    return (
        <ModalBody
            closeText={t('cancel')}
            title={!!currentMachineId.length ? t('modifyMachine') : t('addMachine')}
            okText={!!currentMachineId.length ? t('update') : t('create')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !currentMachineId.length ? postDailyMachine() : patchDailyMachine();
            }}
            disabledOk={!selectedMachine.value.length}
        >
            <div className='fx-c'>
                <DropDown
                    selectedItem={selectedMachine}
                    items={
                        dataMachines?.map((machine) => ({
                            label: machine.designation,
                            value: machine._id,
                            group: machine.machineTypeId.designation,
                        })) || []
                    }
                    handleChange={(event, value) => setSelectedMachine({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('machine')}
                    grouped
                    selectClassName='h-25px'
                />
                {!!selectedMachine.value.length && (
                    <DropDown
                        selectedItem={selectedState}
                        items={states}
                        handleChange={(event, value) => setSelectedState({ value: value?.value || '', label: value?.label || '' })}
                        inputLabel={t('state')}
                    />
                )}
            </div>
        </ModalBody>
    );
};

export default CreateDailyMachineModal;
