import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';
import { Truck } from '../materials/trucks';
import { LoadNature } from '../materials/loadNatures';
import { formatDateToYYYYMMDD } from '../../../utils/dates';
import { Site } from '../planning';
import { Quarry } from '../sites/quarries';
import { DailyTransferRide } from './dailyTransfersRides';
import { Dump } from '../materials/dumps';

export interface DailyTruckRide {
    _id: string;
    truckId: Truck;
    activityId: string;
    time?: string;
    mode?: number;
    nickname?: string;
    departureLoadNatureId?: LoadNature;
    arrivalLoadNatureId?: LoadNature;
    departureSiteId?: Site;
    arrivalSiteId?: Site;
    departureQuarryId?: Quarry;
    arrivalQuarryId?: Quarry;
    departureDumpId?: Dump;
    arrivalDumpId?: Dump;
    transfers: DailyTransferRide[];
    observations?: string;
    departureTime?: string;
    arrivalTime?: string;
    departureTonnage?: number;
    arrivalTonnage?: number;
}

interface DailyTrucksRidesResponse {
    message: string;
    data: DailyTruckRide[];
}

export const getDailyTrucksRidesByDate = async (date: Date): Promise<DailyTrucksRidesResponse> => {
    try {
        const response: AxiosResponse<DailyTrucksRidesResponse> = await apiClient.get('/daily-trucksrides/date/' + formatDateToYYYYMMDD(date));
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
