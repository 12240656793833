import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTempWorker } from '../../services/apiSLTP/planning';
import { DataItem } from '../../services/apiSLTP/activities';

interface TempWorkersTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const TempWorkersTable: React.FC<TempWorkersTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const filterActivities = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (activity: DataItem) => {
            const { siteId, dailyTempWorkers } = activity;

            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(siteId?.name) ||
                dailyTempWorkers.some(
                    (tempWorker) => matchesSearchTerm(tempWorker.durationDay.toString()) || matchesSearchTerm(tempWorker.tempWorkerTypeId.designation)
                )
            );
        };

        return activities.filter(matchesFilter);
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('duration'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {activities.map((activity) =>
                        activity.dailyTempWorkers.map((tempWorker, index) => (
                            <tr key={tempWorker._id} className="row">
                                <td className="pos-sy left-0 z-idx-2">{activity.siteId.name}</td>
                                <td>{tempWorker.tempWorkerTypeId.designation}</td>
                                <td>{tempWorker.durationDay}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default TempWorkersTable;
