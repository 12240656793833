import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { SubContractor } from '../../services/apiSLTP/materials/subContractors';
import { crudStatus } from '../../utils/enums';
import { useCreateSubContractorMutation, useUpdateSubContractorMutation } from '../../hooks/mutations/useSubContractorMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    subContractor: SubContractor;
    subContractors: SubContractor[];
    isEditMode?: boolean;
    setSubContractors: React.Dispatch<React.SetStateAction<SubContractor[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateSubContractorModal: React.FC<ModalProps> = ({
    subContractor,
    isEditMode = true,
    setSubContractors,
    showEditModal,
    setShowEditModal,
    subContractors,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpSubContractor, setTmpSubContractor] = useState(subContractor);

    const { user } = useAuth();

    const {
        mutate: mutateSubContractor,
        isSuccess: isSuccessSubContractor,
        isError: isErrorSubContractor,
        isLoading: isLoadingSubContractor,
        error: errorSubContractor,
        data: dataSubContractor,
    } = useUpdateSubContractorMutation({
        onSuccess: (data, variables) => {
            {
                /*setSubContractors((prevSubContractors) => {
                const newSubContractors = [...prevSubContractors];
                const index = newSubContractors.findIndex((value) => value._id === subContractor._id);
                newSubContractors[index] = data.data; // change here
                return newSubContractors;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating subContractor:', error.message);
        },
    });

    const {
        mutate: addSubContractor,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateSubContractorMutation({
        onSuccess: (data, variables) => {
            {
                /*setSubContractors((prevSubContractors) => {
                const newSubContractors = [...prevSubContractors];
                newSubContractors.push(data.data);
                return newSubContractors;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating subContractor:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpSubContractor((prevSubContractor) => {
            const newSubContractor = { ...prevSubContractor };
            newSubContractor.designation = text;
            return newSubContractor;
        });
    };

    const handleCodeProCChange = (text: string) => {
        setTmpSubContractor((prevSubContractor) => {
            const newSubContractor = { ...prevSubContractor };
            newSubContractor.codeProC = text;
            return newSubContractor;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const verifyCodeProC = () => {
        if (
            subContractors
                .filter((value) => value._id !== tmpSubContractor._id)
                .map((value) => value.codeProC)
                .includes(tmpSubContractor.codeProC)
        )
            return false;
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpSubContractor((prevSubContractor) => {
                const newSubContractor = { ...prevSubContractor };
                newSubContractor.crudStatus = crudStatus.OK;
                return newSubContractor;
            });
        } else {
            setTmpSubContractor((prevSubContractor) => {
                const newSubContractor = { ...prevSubContractor };
                newSubContractor.crudStatus = crudStatus.INACTIVE;
                return newSubContractor;
            });
        }
    };

    return (
        <ModalBody
            title={isEditMode ? t('updateSubContractor') : t('createSubContractor')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation() || !verifyCodeProC()}
            handleOk={() => {
                isEditMode
                    ? mutateSubContractor({
                          subContractorId: subContractor._id,
                          data: {
                              designation: tmpSubContractor.designation,
                              crudStatus: tmpSubContractor.crudStatus,
                              codeProC: tmpSubContractor.codeProC,
                          },
                      })
                    : addSubContractor({ codeProC: tmpSubContractor.codeProC, designation: tmpSubContractor.designation });
            }}
        >
            <CustomTextInput
                value={tmpSubContractor.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
            />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpSubContractor.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
            <CustomTextInput
                value={tmpSubContractor.codeProC}
                label={t('codeProC')}
                onChange={handleCodeProCChange}
                //error={!verifyCodeProC()}
                errorText={t('error')}
            />
        </ModalBody>
    );
};

export default UpdateSubContractorModal;
