import React, { createContext, useState, useEffect, useContext } from 'react';
import { protectedInfos } from '../services/apiSLTP/auth';
import { User } from '../services/apiSLTP/users';

type AuthContextType = {
    isAuthenticated: boolean;
    login: (user: string) => void;
    logout: () => void;
    user: User | null;
    authIsLoading: boolean;
};

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authIsLoading, setAuthIsLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);

    const login = (user: string) => {
        setIsAuthenticated(true);
    };

    const logout = () => {
        setIsAuthenticated(false);
    };

    const initUsersInfos = async () => {
        try {
            //await new Promise((resolve) => setTimeout(resolve, 3000));
            const response = await protectedInfos();
            if (response.data._id) {
                login(response.data.firstName);
                setUser(response.data);
            }
        } catch {
        } finally {
            setAuthIsLoading(false);
        }
    };

    useEffect(() => {
        initUsersInfos();
    }, []);

    return <AuthContext.Provider value={{ isAuthenticated, login, logout, user, authIsLoading }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
