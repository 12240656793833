import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface Quarry {
    designation: string;
    crudStatus: number;
    _id: string;
}
export interface QuarriesResponse {
    message: string;
    data: Quarry[];
}

export const getAllQuarries = async (): Promise<QuarriesResponse> => {
    try {
        const response: AxiosResponse<QuarriesResponse> = await apiClient.get('/quarries');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getAllActiveQuarries = async (): Promise<QuarriesResponse> => {
    try {
        const response: AxiosResponse<QuarriesResponse> = await apiClient.get('/quarries/active');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateQuarryBody {
    designation?: string;
    crudStatus?: number;
}

export interface QuarryResponse {
    message: string;
    data: Quarry;
}

export const updateQuarry = async (loadNatureId: string, data: UpdateQuarryBody): Promise<QuarryResponse> => {
    try {
        const response: AxiosResponse<QuarryResponse> = await apiClient.patch('/quarries/' + loadNatureId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateQuarryBody {
    designation: string;
}

export const createQuarry = async (data: CreateQuarryBody): Promise<QuarryResponse> => {
    try {
        const response: AxiosResponse<QuarryResponse> = await apiClient.post('/quarries', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
