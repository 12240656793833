import { useQuery, QueryFunction } from 'react-query';
import { getAllTools, Tool } from '../../services/apiSLTP/materials/tools';

type UseToolsQueryOptions = {};

const getTools: QueryFunction<Tool[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseToolsQueryOptions];
    const {} = options;

    const response = await getAllTools();
    return response.data;
};

export const useToolsQuery = (options: UseToolsQueryOptions) => {
    return useQuery(['tools', options], getTools);
};
