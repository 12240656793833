import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Machine } from '../../services/apiSLTP/materials/machines';
import { crudStatus } from '../../utils/enums';
import { User } from '../../services/apiSLTP/users';
import { MachineType } from '../../services/apiSLTP/materials/machinesTypes';
import { useCreateMachineMutation, useUpdateMachineMutation } from '../../hooks/mutations/useMachineMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    machine: Machine;
    machines: Machine[];
    setMachines: React.Dispatch<React.SetStateAction<Machine[]>>;
    users: User[];
    machineTypes: MachineType[];
    showEditModal: boolean;
    isEditMode?: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateMachineModal: React.FC<ModalProps> = ({
    machine,
    setMachines,
    showEditModal,
    setShowEditModal,
    users,
    machineTypes,
    machines,
    isEditMode = true,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpMachine, setTmpMachine] = useState(machine);
    const [selectedUser, setSelectedUser] = useState({
        label: '',
        value: '',
    });
    const [selectedMachineType, setSelectedMachineType] = useState({
        label: '',
        value: '',
    });

    const { user } = useAuth();

    const {
        mutate: mutateMachine,
        isSuccess: isSuccessMachine,
        isError: isErrorMachine,
        isLoading: isLoadingMachine,
        error: errorMachine,
        data: dataMachine,
    } = useUpdateMachineMutation({
        onSuccess: (data, variables) => {
            {
                /*setMachines((prevMachines) => {
                const newMachines = [...prevMachines];
                const index = newMachines.findIndex((value) => value._id === user?._id);
                newMachines[index] = data.data; // change here
                return newMachines;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating machine:', error.message);
        },
    });

    const {
        mutate: addMachine,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateMachineMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating machine:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpMachine((prevMachine) => {
            const newMachine = { ...prevMachine };
            newMachine.designation = text;
            return newMachine;
        });
    };

    const handleCodeProCChange = (text: string) => {
        setTmpMachine((prevMachine) => {
            const newMachine = { ...prevMachine };
            newMachine.codeProC = text;
            return newMachine;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const verifyCodeProC = () => {
        if (
            machines
                .filter((value) => value._id !== tmpMachine._id)
                .map((value) => value.codeProC)
                .includes(tmpMachine.codeProC)
        )
            return false;
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpMachine((prevMachine) => {
                const newMachine = { ...prevMachine };
                newMachine.crudStatus = crudStatus.OK;
                return newMachine;
            });
        } else {
            setTmpMachine((prevMachine) => {
                const newMachine = { ...prevMachine };
                newMachine.crudStatus = crudStatus.INACTIVE;
                return newMachine;
            });
        }
    };

    useEffect(() => {
        const user = users.find((value) => value?._id === tmpMachine.userId?._id);
        setSelectedUser({ label: `${user?.firstName} ${user?.lastName}`, value: `${user?._id}` });
        const machineType = machineTypes.find((value) => value?._id === tmpMachine.machineTypeId?._id);
        setSelectedMachineType({ label: `${machineType?.designation}`, value: `${machineType?._id}` });
    }, []);

    return (
        <ModalBody
            title={isEditMode ? t('updateMachine') : t('createMachine')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation() || !verifyCodeProC()}
            handleOk={() => {
                isEditMode
                    ? mutateMachine({
                          machineId: machine._id,
                          data: {
                              codeProC: tmpMachine.codeProC,
                              designation: tmpMachine.designation,
                              crudStatus: tmpMachine.crudStatus,
                              machineTypeId: selectedMachineType.value,
                              userId: selectedUser.value,
                          },
                      })
                    : addMachine({
                          codeProC: tmpMachine.codeProC,
                          designation: tmpMachine.designation,
                          machineTypeId: selectedMachineType.value,
                          userId: selectedUser.value,
                      });
            }}
        >
            <CustomTextInput
                value={tmpMachine.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
            />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpMachine.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
            <CustomTextInput
                value={tmpMachine.codeProC}
                label={t('codeProC')}
                onChange={handleCodeProCChange}
                //error={!verifyCodeProC()}
                errorText={t('error')}
            />

            <DropDown
                items={users.map((user, index) => ({ label: `${user.firstName} ${user?.lastName}`, value: user._id }))}
                selectedItem={selectedUser}
                handleChange={(event, value) => setSelectedUser({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('user')}
            />
            <DropDown
                items={machineTypes.map((machineType, index) => ({ label: `${machineType.designation}`, value: machineType._id }))}
                selectedItem={selectedMachineType}
                handleChange={(event, value) => setSelectedMachineType({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('type')}
            />
        </ModalBody>
    );
};

export default UpdateMachineModal;
