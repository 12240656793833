import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { materialState } from '../../utils/enums';
import { DataItem } from '../../services/apiSLTP/activities';

interface MachinesTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const MachinesTable: React.FC<MachinesTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();
    const getMaterialStateText = (state: number) => {
        switch (state) {
            case materialState.NEW:
                return t('new');
            case materialState.PRESENT:
                return t('present');
            case materialState.REMOVE:
                return t('remove');
            default:
                return '';
        }
    };

    const filterActivities = () => {
        const searchTerm = universalFilter.toLowerCase().trim();
        
        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);
    
        const matchesFilter = (activity: DataItem) => {
            const { siteId, activityTypeId, dailyMachines,  } = activity;
    
            return (
                (searchTerm.length === 0 ||
                    matchesSearchTerm(siteId?.name) ||
                    dailyMachines.some((machine) =>
                        matchesSearchTerm(machine.machineId.designation) ||
                        matchesSearchTerm(machine.machineId.machineTypeId.designation) ||
                        matchesSearchTerm(getMaterialStateText(machine.state)) ||
                        matchesSearchTerm(machine.machineId.codeProC)
                    )
                )
            );
        };
    
        return activities.filter(matchesFilter);
    };

    return (
        <div className='table-container'>
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('name'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('state'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {activities.map((activity) =>
                        activity.dailyMachines.map((machine, index) => (
                            <tr key={machine._id} className="row">
                                <td className="pos-sy left-0 z-idx-2">{activity.siteId.name}</td>
                                <td className="row-cell-codeproc">{machine.machineId.codeProC}</td>
                                <td className="row-cell-designation">{machine.machineId.designation}</td>
                                <td className="row-cell-state">{getMaterialStateText(machine.state)}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default MachinesTable;
