import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

interface DroppableCellProps {
    dropId: { lineId: string }; // Mise à jour ici
    moveCell: (fromLineId: string, fromItemId: string, toLineId: string) => void;
    children?: React.ReactNode;
    itemType: string;
    disabled?: boolean;
}

const DroppableCell: React.FC<DroppableCellProps> = ({ dropId, moveCell, children, itemType, disabled = false }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: itemType,
        drop: (item: { dragIds: { lineId: string; itemId: string } }) => {
            if (!disabled) {
                const { lineId: fromLineId, itemId: fromItemId } = item.dragIds;
                const { lineId: toLineId } = dropId;
                moveCell(fromLineId, fromItemId, toLineId);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            /*const { lineId: fromLineId, itemId: fromItemId } = item.dragIds;
            const { lineId: toLineId } = dropId;
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveCell(fromLineId, fromItemId, toLineId);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;*/
        },
    });
    drop(ref);

    return (
        <div
            className="droppable"
            ref={ref}
            style={{ backgroundColor: isOver ? 'gray' : undefined, borderRadius: 5, boxShadow: isOver ? '0 0 0 3px gray' : undefined }}
        >
            {children}
        </div>
    );
};

export default DroppableCell;
