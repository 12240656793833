import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyMachine, DailyTool } from '../../services/apiSLTP/planning';
import { materialState } from '../../utils/enums';
import { DataItem } from '../../services/apiSLTP/activities';

interface ToolsTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const ToolsTable: React.FC<ToolsTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const filterActivities = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (activity: DataItem) => {
            const {
                siteId,
                dailyTools,
            } = activity;

            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(siteId?.name) ||
                dailyTools.some(
                    (tool) =>
                        matchesSearchTerm(tool.toolId.designation) ||
                        matchesSearchTerm(tool.toolId.toolTypeId.designation) ||
                        matchesSearchTerm(getMaterialStateText(tool.state)) ||
                        matchesSearchTerm(tool.toolId.codeProC)
                )
            );
        };

        return activities.filter(matchesFilter);
    };

    const getMaterialStateText = (state: number) => {
        switch (state) {
            case materialState.NEW:
                return t('new');
            case materialState.PRESENT:
                return t('present');
            case materialState.REMOVE:
                return t('remove');
            default:
                return '';
        }
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('name'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('state'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {activities.map((activity) =>
                        activity.dailyTools.map((tool, index) => (
                            <tr key={tool._id} className="row">
                                <td className="pos-sy left-0 z-idx-2">{activity.siteId.name}</td>
                                <td className="row-cell-codeproc">{tool.toolId.codeProC}</td>
                                <td className="row-cell-designation">{tool.toolId.designation}</td>

                                <td className="row-cell-state">{getMaterialStateText(tool.state)}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ToolsTable;
