import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Quarry } from '../../services/apiSLTP/sites/quarries';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateQuarryMutation } from '../../hooks/mutations/useQuarryMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateQuarryModal from '../modals/UpdateQuarryModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import { useQuarriesQuery } from '../../hooks/queries/useQuarriesQuery';

interface QuarriesSettingsRowProps {
    setAddFtn: (ftn: () => void) => void;
}

const QuarriesSettingsRow: React.FC<QuarriesSettingsRowProps> = ({ setAddFtn }) => {
    const {
        data: quarries,
        isLoading: isQuarriesLoading,
        isError: isQuarriesError,
        isSuccess: isQuarriesSuccess,
        refetch: refetchQuarries,
    } = useQuarriesQuery({});

    const emptyQuarry: Quarry = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedQuarry, setSelectedQuarry] = useState<Quarry>(emptyQuarry);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const {
        mutate: mutateQuarry,
        isSuccess: isSuccessQuarry,
        isError: isErrorQuarry,
        isLoading: isLoadingQuarry,
        error: errorQuarry,
        data: dataQuarry,
    } = useUpdateQuarryMutation({
        onSuccess: (data, variables) => {
            refetchQuarries();
        },
        onError: (error) => {
            console.error('Error updating quarry:', error.message);
        },
    });

    const sortedQuarries = () => {
        return (
            quarries?.sort((a, b) => {
                const typeA = a?.designation;
                const typeB = b?.designation;
                if (typeA < typeB) return -1;
                if (typeA > typeB) return 1;
                const designationA = a.designation;
                const designationB = b.designation;
                if (designationA < designationB) return -1;
                if (designationA > designationB) return 1;
                return 0;
            }) || []
        );
    };

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedQuarry(emptyQuarry);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-30px pos-sy left-0 4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedQuarries().map((quarry, index, array) => (
                            <tr key={quarry._id}>
                                <td scope="row">{quarry.designation}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedQuarry(quarry);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={quarry.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedQuarry(quarry);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={quarry.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <div className="fx-r fx-justify-c">
                                        <StateBadge backgroundColor={getStatusColor(quarry.crudStatus)} title={capitalizeFirstLetter(t(getCrudStatusInfos(quarry.crudStatus)))} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateQuarryModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    quarry={selectedQuarry}
                    isEditMode={isEditMode}
                    refetch={refetchQuarries}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateQuarry({ quarryId: selectedQuarry._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedQuarry?.designation}
                />
            )}
        </div>
    );
};

export default QuarriesSettingsRow;
