import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem } from '../../../services/apiSLTP/activities';
import { getMaterialStateInfos, getTruckModeInfos, permissionLevels, truckRideActivityTypeId } from '../../../utils/enums';
import { getMaterialStateColor } from '../../../utils/colors';
import { countPropertyValues, removeDuplicates } from '../../../utils/array';
import Icon from '../../Icon';

interface DriversAmplirollRowProps {
    dailyTrucksRides: DailyTruckRide[];
    dailyTruckRide: DailyTruckRide;
    activities: DataItem[];
}

const DriversAmplirollRow: React.FC<DriversAmplirollRowProps> = ({ dailyTrucksRides, activities, dailyTruckRide }) => {
    const { t } = useTranslation();

    const [isExpandable, setIsExpandable] = useState(false);

    const activitiesMap = new Map(activities.map((activity) => [activity._id, activity]));

    const activity = activities.find((value) => value._id === dailyTruckRide?.activityId);
    const site = activity?.siteId;

    const sortedDailyTrucksRides = () => {
        return dailyTrucksRides.sort((a, b) => {
            const timeA = new Date(a.time || '');
            const timeB = new Date(b.time || '');

            if (timeA < timeB) return -1;
            if (timeA > timeB) return 1;

            return 0;
        });
    };

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <>
            <tr onClick={() => setIsExpandable(!isExpandable)} className="font-w-700" style={{ borderTop: '1px solid black' }}>
                <td className="pos-sy left-0 z-idx-2">
                    <Icon name={isExpandable ? 'keyboardArrowDownSharp' : 'keyboardArrowRightSharp'} />
                </td>
                <td scope="row" className="pos-sy left-30px z-idx-2 w-30px">
                    {site?.name.includes(' - ') ? site.name.split(' - ').slice(1).join(' - ') || '' : site?.name}
                    {` | ${dailyTrucksRides.length} ${t('ride(s)')}`}
                </td>
                <td>{dailyTruckRide.truckId?.codeProC}</td>
                <td>{dailyTruckRide.truckId?.truckCompanyId?.designation}</td>
                <td>{dailyTruckRide.truckId?.plateNumber}</td>
                <td className="pos-sy left-230px z-idx-2">
                    {capitalizeFirstLetter(activities.find((value) => value._id === dailyTruckRide.activityId)?.reportId.userId.firstName) +
                        ' ' +
                        activities.find((value) => value._id === dailyTruckRide.activityId)?.reportId.userId.lastName.toUpperCase()}
                </td>
                <td>{dailyTruckRide.truckId?.truckTypeId?.designation}</td>
                <td>
                    {countPropertyValues(dailyTrucksRides, 'mode').map((mode) => (
                        <div>
                            {mode.number} {capitalizeFirstLetter(t(getTruckModeInfos(parseInt(mode.name))))}
                        </div>
                    ))}
                </td>
                <td colSpan={4} />
                <td>{dailyTrucksRides.reduce((a, b) => a + (b.departureTonnage || 0), 0)}</td>
                <td colSpan={6} />
                <td>{dailyTrucksRides.reduce((a, b) => a + (b.arrivalTonnage || 0), 0)}</td>
                <td colSpan={3} />
            </tr>
            {isExpandable &&
                sortedDailyTrucksRides().map((truckRide) => {
                    return (
                        <tr key={truckRide._id} className={`wrapper ${isExpandable ? 'open' : ''}`}>
                            <td className="pos-sy left-0 z-idx-2"></td>
                            <td scope="row" className="pos-sy left-30px z-idx-2">
                                {site?.name.includes(' - ') ? site.name.split(' - ').slice(1).join(' - ') || '' : site?.name}
                            </td>
                            <td>{truckRide.truckId?.codeProC}</td>
                            <td>{truckRide.truckId?.truckCompanyId?.designation}</td>
                            <td>{truckRide.truckId?.plateNumber}</td>
                            <td className="pos-sy left-230px z-idx-2">
                                {capitalizeFirstLetter(activities.find((value) => value._id === truckRide.activityId)?.reportId.userId.firstName) +
                                    ' ' +
                                    activities.find((value) => value._id === truckRide.activityId)?.reportId.userId.lastName.toUpperCase()}
                            </td>
                            <td>{truckRide.truckId?.truckTypeId?.designation}</td>
                            <td>{capitalizeFirstLetter(t(getTruckModeInfos(truckRide?.mode)))}</td>
                            <td>{truckRide.departureLoadNatureId?.designation}</td>
                            <td>{truckRide.departureSiteId?.name || ''}</td>
                            <td>{truckRide.departureQuarryId?.designation || ''}</td>
                            <td>{truckRide.departureDumpId?.designation || ''}</td>
                            <td>{truckRide.departureTonnage || ''}</td>
                            <td>{formatTime(truckRide.departureTime)}</td>
                            <td>{formatTime(truckRide.time)}</td>
                            <td>{truckRide.arrivalLoadNatureId?.designation}</td>
                            <td>{truckRide.arrivalSiteId?.name || ''}</td>
                            <td>{truckRide.arrivalQuarryId?.designation || ''}</td>
                            <td>{truckRide.arrivalDumpId?.designation || ''}</td>
                            <td>{truckRide.arrivalTonnage || ''}</td>
                            <td>{formatTime(truckRide.arrivalTime)}</td>
                            <td>
                                {truckRide.transfers.map((transfer, transferIdx) => (
                                    <div
                                        className="border-radius-5px pad-l-5px fx-c"
                                        style={{
                                            backgroundColor: getMaterialStateColor(transfer.state),
                                            marginTop: transferIdx !== 0 ? 5 : 0,
                                        }}
                                        title={
                                            (transfer.machineId?.designation ||
                                                transfer.toolId?.designation ||
                                                `${transfer.rentalName} ${transfer.rentalCompanyId?.designation}`) +
                                            ` ${t(getMaterialStateInfos(transfer.state))} ` +
                                            (transfer.siteId?.name || transfer.quarryId?.designation)
                                        }
                                    >
                                        <div className="one-line">
                                            {transfer.machineId?.designation ||
                                                transfer.toolId?.designation ||
                                                `${transfer.rentalName} ${transfer.rentalCompanyId?.designation}`}
                                        </div>
                                        <div className="one-line">{transfer.siteId?.name || transfer.quarryId?.designation}</div>
                                    </div>
                                ))}
                            </td>
                            <td>{truckRide.observations || ''}</td>
                        </tr>
                    );
                })}
        </>
    );
};

export default DriversAmplirollRow;
