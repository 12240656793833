import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { LoadNature } from '../../services/apiSLTP/materials/loadNatures';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateLoadNatureMutation, useUpdateLoadNatureMutation } from '../../hooks/mutations/useLoadNatureMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    loadNature: LoadNature;
    loadNatures: LoadNature[];
    isEditMode?: boolean;
    setLoadNatures: React.Dispatch<React.SetStateAction<LoadNature[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateLoadNatureModal: React.FC<ModalProps> = ({
    loadNature,
    isEditMode = true,
    setLoadNatures,
    showEditModal,
    setShowEditModal,
    loadNatures,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpLoadNature, setTmpLoadNature] = useState(loadNature);

    const { user } = useAuth();

    const {
        mutate: mutateLoadNature,
        isSuccess: isSuccessLoadNature,
        isError: isErrorLoadNature,
        isLoading: isLoadingLoadNature,
        error: errorLoadNature,
        data: dataLoadNature,
    } = useUpdateLoadNatureMutation({
        onSuccess: (data, variables) => {
            {
                /*setLoadNatures((prevLoadNatures) => {
                const newLoadNatures = [...prevLoadNatures];
                const index = newLoadNatures.findIndex((value) => value._id === loadNature._id);
                newLoadNatures[index] = data.data; // change here
                return newLoadNatures;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating loadNature:', error.message);
        },
    });

    const {
        mutate: addLoadNature,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateLoadNatureMutation({
        onSuccess: (data, variables) => {
            {
                /*setLoadNatures((prevLoadNatures) => {
                const newLoadNatures = [...prevLoadNatures];
                const index = newLoadNatures.findIndex((value) => value._id === loadNature._id);
                newLoadNatures.push(data.data); // change here
                return newLoadNatures;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating loadNature:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpLoadNature((prevLoadNature) => {
            const newLoadNature = { ...prevLoadNature };
            newLoadNature.designation = text;
            return newLoadNature;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpLoadNature((prevLoadNature) => {
                const newLoadNature = { ...prevLoadNature };
                newLoadNature.crudStatus = crudStatus.OK;
                return newLoadNature;
            });
        } else {
            setTmpLoadNature((prevLoadNature) => {
                const newLoadNature = { ...prevLoadNature };
                newLoadNature.crudStatus = crudStatus.INACTIVE;
                return newLoadNature;
            });
        }
    };

    return (
        <ModalBody
            title={isEditMode ? t('updateLoadNature') : t('createLoadNature')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation()}
            handleOk={() => {
                isEditMode
                    ? mutateLoadNature({
                          loadNatureId: loadNature._id,
                          data: {
                              designation: tmpLoadNature.designation,
                              crudStatus: tmpLoadNature.crudStatus,
                          },
                      })
                    : addLoadNature({ designation: tmpLoadNature.designation });
            }}
        >
            <CustomTextInput
                value={tmpLoadNature.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
            />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpLoadNature.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
        </ModalBody>
    );
};

export default UpdateLoadNatureModal;
