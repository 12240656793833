import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Entity } from '../../services/apiSLTP/sites/entities';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateEntityMutation, useUpdateEntityMutation } from '../../hooks/mutations/useEntityMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    entity: Entity;
    isEditMode?: boolean;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateEntityModal: React.FC<ModalProps> = ({ entity, isEditMode = true, showEditModal, setShowEditModal, refetch }) => {
    const { t } = useTranslation();
    const [tmpEntity, setTmpEntity] = useState(entity);

    const { user } = useAuth();

    const {
        mutate: mutateEntity,
        isSuccess: isSuccessEntity,
        isError: isErrorEntity,
        isLoading: isLoadingEntity,
        error: errorEntity,
        data: dataEntity,
    } = useUpdateEntityMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating entity:', error.message);
        },
    });

    const {
        mutate: addEntity,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateEntityMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating entity:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpEntity((prevEntity) => {
            const newEntity = { ...prevEntity };
            newEntity.designation = text;
            return newEntity;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpEntity((prevEntity) => {
                const newEntity = { ...prevEntity };
                newEntity.crudStatus = crudStatus.OK;
                return newEntity;
            });
        } else {
            setTmpEntity((prevEntity) => {
                const newEntity = { ...prevEntity };
                newEntity.crudStatus = crudStatus.INACTIVE;
                return newEntity;
            });
        }
    };

    return (
        <ModalBody
            title={isEditMode ? t('updateEntity') : t('createEntity')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation()}
            handleOk={() => {
                isEditMode
                    ? mutateEntity({
                          entityId: entity._id,
                          data: {
                              designation: tmpEntity.designation,
                              crudStatus: tmpEntity.crudStatus,
                          },
                      })
                    : addEntity({ designation: tmpEntity.designation });
            }}
        >
            <CustomTextInput value={tmpEntity.designation} label={t('name')} onChange={handleDesignationChange} errorText={t('error')} />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpEntity.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
        </ModalBody>
    );
};

export default UpdateEntityModal;
