import React from 'react';
import semieImage from '../assets/images/semie.png';
import amplirollImage from '../assets/images/ampliroll.png';
import x4Image from '../assets/images/8x4.png';
import porteCharImage from '../assets/images/porte-char.png';
import unknownTruckImage from '../assets/images/unknowm-truck.png';
import semieWhiteImage from '../assets/images/semie-white.png';
import amplirollWhiteImage from '../assets/images/ampliroll-white.png';
import x4WhiteImage from '../assets/images/8x4-white.png';
import porteCharWhiteImage from '../assets/images/porte-char-white.png';
import unknownTruckWhiteImage from '../assets/images/unknowm-truck-white.png';

interface TruckIconProps {
    type: 'Semie' | 'Ampliroll' | '8x4' | 'Porte-char' | '';
    style?: React.CSSProperties
    dark?: boolean;
}

const TruckIcon: React.FC<TruckIconProps> = ({ type, style, dark = false }) => {
    let imageSrc: string;

    switch (type) {
        case 'Semie':
            imageSrc = dark ? semieWhiteImage : semieImage;
            break;
        case 'Ampliroll':
            imageSrc = dark ? amplirollWhiteImage : amplirollImage;
            break;
        case '8x4':
            imageSrc = dark ? x4WhiteImage : x4Image;
            break;
        case 'Porte-char':
            imageSrc = dark ? porteCharWhiteImage : porteCharImage;
            break;
        default:
            imageSrc = dark ? unknownTruckWhiteImage : unknownTruckImage;
            break;
    }
    return <img height={20} title={type} src={imageSrc} alt={`Truck icon ${type}`} style={{ padding: 5, ...style }} />;
};

export default TruckIcon;
