import React, { useEffect, useState } from 'react';
import { DataItem } from '../services/apiSLTP/activities';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Site, updateActivity } from '../services/apiSLTP/planning';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useActivitiesByDateQuery } from '../hooks/queries/useActivitiesQuery';
import { getStateInfos, localStorageKeys, permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../utils/enums';
import { getInOneDayDate, getOneDayAgoDate, getSpecificDateFromDate, getTomorrowDate } from '../utils/dates';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../utils/capitalize';
import LinearProgress from '@mui/material/LinearProgress';
import { getStateColor, getTextColor } from '../utils/colors';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import WorkersTable from '../components/tables/WorkersTable';
import MachinesTable from '../components/tables/MachinesTable';
import RentalsTable from '../components/tables/RentalsTable';
import ToolsTable from '../components/tables/ToolsTable';
import TempWorkersTable from '../components/tables/TempWorkersTable';
import CalendarModal from '../components/modals/CalendarModal';
import { updateReportState } from '../services/apiSLTP/dailyReports/reports';
import { useDailyTrucksRidesQuery } from '../hooks/queries/useDailyTrucksRidesQuery';
import SitesRidesTable from '../components/tables/supervision/SitesRidesTable';
import { DailyTruckRide } from '../services/apiSLTP/dailyReports/dailyTrucksRides';
import CustomToggle from '../components/CustomToggle';
import TruckIcon from '../components/TruckIcon';
import { useTrucksQuery } from '../hooks/queries/useTrucksQuery';
import { Truck } from '../services/apiSLTP/materials/trucks';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../utils/localStorage';
import ColorBar from '../components/ColorBar';
import Header from '../components/Header';
import StateBadge from '../components/StateBadge';
import Icon from '../components/Icon';
import SubContractorsTable from '../components/tables/SubContractorsTable';
import InfosColorsModal from '../components/modals/InfosColorsModal';
import MachinesCell from '../components/cells/MachinesCell';
import ToolsCell from '../components/cells/ToolsCell';
import RentalsCell from '../components/cells/RentalsCell';
import ReportsTable from '../components/tables/ReportsTable';
import SitesAmplirollTable from '../components/tables/supervision/SitesAmplirollTable';
import DriversRidesTable from '../components/tables/supervision/DriversRidesTable';
import DriversAmplirollTable from '../components/tables/supervision/DriversAmplirollTable';

const ReportsHistory: React.FC = () => {
    const [activities, setActivities] = useState<DataItem[]>([]);
    const [dailyTrucksRides, setDailyTrucksRides] = useState<DailyTruckRide[]>([]);
    const [currentDate, setCurrentDate] = useState(new Date(getItemFromLocalStorage(localStorageKeys.DATE) || getTomorrowDate()));
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [showInfosColorsModal, setShowInfosColorsModal] = useState(false);
    const [universalFilter, setUniversalFilter] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [trucks, setTrucks] = useState<Truck[]>([]);

    const { user } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();

    const { data, isLoading, isError, isSuccess, isRefetching, refetch } = useActivitiesByDateQuery({
        date: currentDate.toISOString(),
        minState: reportState.REPORT,
        maxState: reportState.REPORTFILLED,
        stepMode: stepModes.REPORT,
    });
    const { data: trucksData, isLoading: trucksIsLoading, isError: trucksIsError, isSuccess: trucksIsSuccess } = useTrucksQuery({});

    const {
        data: dailyTrucksData,
        isLoading: dailyTrucksIsLoading,
        isError: dailyTrucksIsError,
        isSuccess: dailyTrucksIsSuccess,
        isRefetching: dailyTrucksIsRefetching,
        refetch: dailyTrucksRefetch,
    } = useDailyTrucksRidesQuery({
        date: currentDate,
    });

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        storeItemToLocalStorage(localStorageKeys.SUPERVISIONTAB, newValue.toString());
    };

    const handleDateChange = (newDate: string) => {
        setCurrentDate(new Date(newDate));
        storeItemToLocalStorage(localStorageKeys.DATE, newDate);
    };

    const handleActivityUnlock = async (activityId: string, reportId: string) => {
        await updateActivity(activityId, { activityState: reportState.REPORT });
        await updateReportState(reportId, reportState.REPORT);
        refetch();
    };

    const filteredActivitiesForConducs = () => {
        return activities.filter((activity) => {
            if (user?.profileId.permsLevel === permissionLevels.CONDUC) return activity.siteId.conducId._id === user?._id;
            else return true;
        });
    };

    const filterActivities = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (activity: DataItem) => {
            const {
                siteId,
                reportId,
                observationsPlanning,
                activityTypeId,
                dailyMachines,
                dailyTools,
                dailyRentals,
                dailyTempWorkers,
                dailySubContractors,
            } = activity;

            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(siteId?.name) ||
                matchesSearchTerm(siteId?.address) ||
                matchesSearchTerm(siteId?.conducId?.firstName) ||
                matchesSearchTerm(siteId?.conducId?.lastName) ||
                matchesSearchTerm(reportId?.userId?.firstName) ||
                matchesSearchTerm(reportId?.userId?.lastName) ||
                matchesSearchTerm(observationsPlanning) ||
                matchesSearchTerm(activityTypeId?.designation) ||
                dailyMachines.some(
                    (machine) => matchesSearchTerm(machine.machineId.designation) || matchesSearchTerm(machine.machineId.machineTypeId.designation)
                ) ||
                dailyTools.some((tool) => matchesSearchTerm(tool.toolId.designation) || matchesSearchTerm(tool.toolId.toolTypeId.designation)) ||
                dailyRentals.some((rental) => matchesSearchTerm(rental.designation) || matchesSearchTerm(rental.rentalTypeId.designation)) ||
                dailyTempWorkers.some(
                    (tempWorker) => matchesSearchTerm(tempWorker.durationDay.toString()) || matchesSearchTerm(tempWorker.tempWorkerTypeId.designation)
                ) ||
                dailySubContractors.some((subContractor) => matchesSearchTerm(subContractor.subContractorTypeId.designation))
            );
        };

        return filteredActivitiesForConducs()
            .filter(matchesFilter)
            .sort((a, b) => {
                if (a.siteId?.conducId?._id < b.siteId?.conducId?._id) return -1;
                if (a.siteId?.conducId?._id > b.siteId?.conducId?._id) return 1;
                const siteA = a.siteId.codeProC.toLowerCase();
                const siteB = b.siteId.codeProC.toLowerCase();
                if (siteA < siteB) return -1;
                if (siteA > siteB) return 1;
                return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
    };

    useEffect(() => {
        if (data && isSuccess) {
            setActivities(data);
        }
    }, [data]);

    useEffect(() => {
        if (dailyTrucksData && dailyTrucksIsSuccess) {
            setDailyTrucksRides(dailyTrucksData);
        }
    }, [dailyTrucksData]);

    useEffect(() => {
        refetch();
        const tab = getItemFromLocalStorage(localStorageKeys.SUPERVISIONTAB);
        setSelectedTab(parseInt(tab || '0'));
    }, [location, refetch]);

    useEffect(() => {
        if (trucksData && trucksIsSuccess) {
            setTrucks(trucksData);
        }
    }, [trucksData]);

    const handleExport = () => {
        const table = document.getElementById('csv-planning-obj') as HTMLTableElement;
        if (!table) return;
        const rows = Array.from(table.querySelectorAll('tr'));
        if (!rows) return;
        const csvData: string[] = [];

        rows.forEach((row) => {
            if (row.style.display === 'none') return;
            const cols = Array.from(row.querySelectorAll('th, td'))
                .map((col) => {
                    let textContent = col.textContent?.trim() || '';
                    let title = '';

                    const children = Array.from(col.querySelectorAll('*'));
                    children.forEach((child) => {
                        if (!textContent && child.textContent?.trim()) {
                            textContent = child.textContent.trim();
                        }
                        if (child.getAttribute('title')) {
                            title = child.getAttribute('title')?.trim() || '';
                        }
                    });

                    return (textContent !== '' ? textContent : title || '').replaceAll('\n', ' | ');
                })
                .join(',');
            csvData.push(cols);
        });

        const csvString = csvData.join('\n');

        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `reports-${currentDate.toISOString().split('T')[0]}.csv`;
        a.click();

        URL.revokeObjectURL(url);
    };

    return (
        <div>
            <Header
                isSearchable
                title={t('supervision')}
                onSearch={(text) => setUniversalFilter(text)}
                searchValue={universalFilter}
                date={currentDate}
                onLeftArrowClick={() => handleDateChange(getOneDayAgoDate(currentDate).toISOString())}
                onRightArrowClick={() => handleDateChange(getInOneDayDate(currentDate).toISOString())}
            >
                <Icon
                    name="eventRepeat"
                    onClick={() => handleDateChange(new Date().toISOString())}
                    title={capitalizeFirstLetter(t('today'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="calendarMonth"
                    onClick={() => setShowCalendarModal(true)}
                    title={capitalizeFirstLetter(t('calendar'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <div className="divider" />
                <Icon
                    name="palette"
                    onClick={() => setShowInfosColorsModal(true)}
                    title={capitalizeFirstLetter(t('colors'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon name="output" onClick={handleExport} title={capitalizeFirstLetter(t('export'))} classes={{ iconButton: 'pad-10px-imp' }} dark />
            </Header>
            <div className="pad-l-15px pad-r-15px">
                <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                    <Tab label={t('reports')} />
                    <Tab label={t('sites')} />
                    <Tab label={t('workers')} />
                    <Tab label={t('machines')} />
                    <Tab label={t('tools')} />
                    <Tab label={t('rentals')} />
                    <Tab label={t('tempWorkers')} />
                    <Tab label={t('subContractors')} />
                    <Tab label={t('sites') + ' - ' + t('rides')} />
                    <Tab label={t('sites') + ' - ' + t('ampliroll')} />
                    <Tab label={t('drivers') + ' - ' + t('rides')} />
                    <Tab label={t('drivers') + ' - ' + t('ampliroll')} />
                </Tabs>
                <div className="loading-bar">{isLoading && <LinearProgress color="inherit" />}</div>
                {selectedTab === 0 && <ReportsTable activities={filteredActivitiesForConducs()} />}

                {selectedTab === 1 && (
                    <div className="parent-container">
                        <div className="table-container">
                            <table id="csv-planning-obj">
                                <thead>
                                    <tr>
                                        <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4">
                                            {capitalizeFirstLetter(t(''))}
                                        </th>
                                        <th scope="col" className="w-200px pos-sy left-30px z-idx-4">
                                            {capitalizeFirstLetter(t('site'))}
                                        </th>
                                        <th scope="col" className="w-75px">
                                            <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('status'))}</div>
                                        </th>
                                        <th scope="col" className="w-200px left-230px z-idx-4">
                                            {capitalizeFirstLetter(t('person'))}
                                        </th>
                                        <th scope="col" className="w-75px">
                                            {capitalizeFirstLetter(t('start'))}
                                        </th>
                                        <th scope="col" className="w-75px">
                                            {capitalizeFirstLetter(t('end'))}
                                        </th>
                                        <th scope="col" className="w-100px">
                                            <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('truckManager'))}</div>
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('observationsPlanning'))}
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('observationsWorker'))}
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('machines'))}
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('tools'))}
                                        </th>
                                        <th scope="col" className="w-300px">
                                            {capitalizeFirstLetter(t('rentals'))}
                                        </th>
                                        <th scope="col" className="w-200px">
                                            {capitalizeFirstLetter(t('tempWorkers'))}
                                        </th>
                                        <th scope="col" className="w-200px">
                                            {capitalizeFirstLetter(t('subContractors'))}
                                        </th>
                                        <th scope="col" className="w-200px">
                                            {capitalizeFirstLetter(t('activity'))}
                                        </th>
                                        {user && user?.profileId?.permsLevel <= permissionLevels.SUPERVISION && (
                                            <th scope="col" className="w-100px">
                                                {capitalizeFirstLetter(t('actions'))}
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody id="fu-planning-obj">
                                    {!activities.length && (
                                        <tr className="row-empty">
                                            <td className="cell-empty" colSpan={16}>
                                                {capitalizeFirstLetter(t('noActivity'))}
                                            </td>
                                        </tr>
                                    )}
                                    {activities
                                        .sort((a, b) => {
                                            if (!(!a.siteId?.conducId?._id && !b.siteId?.conducId?._id)) {
                                                if (!a.siteId?.conducId?._id) return -1;
                                                if (!b.siteId?.conducId?._id) return 1;
                                            }
                                            if (a.siteId?.conducId?._id < b.siteId?.conducId?._id) return -1;
                                            if (a.siteId?.conducId?._id > b.siteId?.conducId?._id) return 1;
                                            const siteA = a.siteId.name.toLowerCase();
                                            const siteB = b.siteId.name.toLowerCase();
                                            if (siteA < siteB) return -1;
                                            if (siteA > siteB) return 1;
                                            return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
                                        })
                                        .map((activity, index, array) => (
                                            <tr
                                                key={activity._id}
                                                className="row"
                                                style={{
                                                    borderTopWidth: !(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id)
                                                        ? '1px'
                                                        : '',
                                                }}
                                            >
                                                <td className="pos-sy left-0 pad-0-imp z-idx-2">
                                                    <ColorBar
                                                        backgroundColor={activity.siteId?.conducId?.color || 'black'}
                                                        isPaddingTop={!(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id)}
                                                        isPaddingBottom={
                                                            !(index < array.length - 1 && array[index + 1].siteId?._id === activity.siteId?._id)
                                                        }
                                                        title={
                                                            activity.siteId?.conducId?._id
                                                                ? capitalizeFirstLetter(activity.siteId?.conducId?.firstName) +
                                                                  ' ' +
                                                                  activity.siteId?.conducId?.lastName.toUpperCase()
                                                                : ''
                                                        }
                                                    />
                                                </td>
                                                <td className="pos-sy left-30px z-idx-2">
                                                    <div className="font-w-600">{activity.siteId.name}</div>
                                                    {capitalizeFirstLetter(activity.siteId?.conducId?.firstName)}{' '}
                                                    {activity.siteId?.conducId?.lastName}
                                                </td>
                                                <td>
                                                    <div className="fx-r fx-justify-c">
                                                        <StateBadge
                                                            backgroundColor={getStateColor(activity.activityState)}
                                                            title={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="pos-sy left-230px z-idx-2">
                                                    <div>
                                                        {capitalizeFirstLetter(activity.reportId.userId.firstName)}{' '}
                                                        {activity.reportId.userId.lastName.toUpperCase()}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {new Date(activity.startTime).getHours().toString().padStart(2, '0')}:
                                                        {new Date(activity.startTime).getMinutes().toString().padStart(2, '0')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {new Date(activity.validationTime).getHours().toString().padStart(2, '0')}:
                                                        {new Date(activity.validationTime).getMinutes().toString().padStart(2, '0')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="fx-r fx-justify-c">
                                                        {activity.activityTypeId._id !== truckRideActivityTypeId ? (
                                                            <CustomToggle
                                                                checked={activity.isTruckManager}
                                                                disabled
                                                                title={capitalizeFirstLetter(activity.isTruckManager ? t('yes') : t('no'))}
                                                            />
                                                        ) : (
                                                            <TruckIcon
                                                                type={
                                                                    (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)
                                                                        ?.truckTypeId.designation || '') as
                                                                        | 'Semie'
                                                                        | 'Ampliroll'
                                                                        | '8x4'
                                                                        | 'Porte-char'
                                                                        | ''
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>{activity.observationsPlanning}</div>
                                                </td>
                                                <td>
                                                    <div>{activity.observationsWorker}</div>
                                                </td>
                                                <td>
                                                    <MachinesCell
                                                        activity={activity}
                                                        activityIndex={index}
                                                        activities={array}
                                                        disabled
                                                        refetch={refetch}
                                                        hiddenPlusIcon
                                                    />
                                                </td>
                                                <td>
                                                    <ToolsCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                                </td>
                                                <td>
                                                    <RentalsCell
                                                        activity={activity}
                                                        activityIndex={index}
                                                        activities={array}
                                                        disabled
                                                        hiddenPlusIcon
                                                    />
                                                </td>
                                                <td>
                                                    {activity.dailyTempWorkers.map((tempWorker, index) => (
                                                        <div key={tempWorker._id}>
                                                            {tempWorker.tempWorkerTypeId.designation} - {tempWorker.durationDay}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>
                                                    {activity.dailySubContractors.map((subContractor, index) => (
                                                        <div key={subContractor._id}>{subContractor.subContractorTypeId.designation}</div>
                                                    ))}
                                                </td>
                                                <td>
                                                    <div>{activity.activityTypeId.designation}</div>
                                                </td>
                                                {user && user?.profileId?.permsLevel <= permissionLevels.SUPERVISION && (
                                                    <td>
                                                        <Icon
                                                            name="lock"
                                                            onClick={() => {
                                                                handleActivityUnlock(activity._id, activity.reportId._id);
                                                            }}
                                                        />
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {selectedTab === 2 && <WorkersTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 3 && <MachinesTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 4 && <ToolsTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 5 && <RentalsTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 6 && <TempWorkersTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 7 && <SubContractorsTable activities={filteredActivitiesForConducs()} universalFilter={universalFilter} />}
                {selectedTab === 8 && (
                    <SitesRidesTable
                        activities={filteredActivitiesForConducs()}
                        dailyTrucksRides={dailyTrucksRides}
                        universalFilter={universalFilter}
                    />
                )}
                {selectedTab === 9 && (
                    <SitesAmplirollTable
                        activities={filteredActivitiesForConducs()}
                        dailyTrucksRides={dailyTrucksRides}
                        universalFilter={universalFilter}
                    />
                )}
                {selectedTab === 10 && (
                    <DriversRidesTable
                        activities={filteredActivitiesForConducs()}
                        dailyTrucksRides={dailyTrucksRides}
                        universalFilter={universalFilter}
                    />
                )}
                {selectedTab === 11 && (
                    <DriversAmplirollTable
                        activities={filteredActivitiesForConducs()}
                        dailyTrucksRides={dailyTrucksRides}
                        universalFilter={universalFilter}
                    />
                )}

                {showInfosColorsModal && <InfosColorsModal open={showInfosColorsModal} handleClose={() => setShowInfosColorsModal(false)} />}
                {!!showCalendarModal && (
                    <CalendarModal
                        currentDate={currentDate}
                        open={showCalendarModal}
                        handleClose={() => setShowCalendarModal(false)}
                        setCurrentDate={handleDateChange}
                    />
                )}
            </div>
        </div>
    );
};

export default ReportsHistory;
