import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DataItem, Report } from '../../services/apiSLTP/activities';
import datetimeDifference from 'datetime-difference';
import StateBadge from '../StateBadge';
import { getStateColor } from '../../utils/colors';
import { getStateInfos } from '../../utils/enums';

interface ReportsTableProps {
    activities: DataItem[];
}

const ReportsTable: React.FC<ReportsTableProps> = ({ activities }) => {
    const { t } = useTranslation();

    const getReports = () => {
        let reports: Report[] = [];
        for (const activity of activities) {
            if (reports.find((report) => report._id === activity.reportId._id) === undefined) reports.push(activity.reportId);
        }
        return reports.sort((a, b) => {
            const userA = a.userId.lastName + a.userId.firstName;
            const userB = b.userId.lastName + b.userId.firstName;
            return userA.localeCompare(userB);
        });
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {t('lastName')}
                        </th>
                        <th scope="col" className="w-100px">
                            {t('firstName')}
                        </th>
                        <th scope="col" className="w-100px">
                            {t('fuel') + ` (${t('liters').charAt(0)})`}
                        </th>
                        <th scope="col" className="w-100px">
                            {t('km')}
                        </th>
                        <th scope="col" className="w-75px">
                            <div className="fx-r fx-justify-c txt-align-c">{t('status')}</div>
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {getReports().map((report, index) => (
                        <tr key={report._id} className="row">
                            <td className="pos-sy left-0 z-idx-2">{report.userId.lastName}</td>
                            <td>{report.userId.firstName}</td>
                            <td>{report.fuel}</td>
                            <td>{report.km}</td>
                            <td>
                                <div className="fx-r fx-justify-c">
                                    <StateBadge
                                        title={capitalizeFirstLetter(t(getStateInfos(report.reportState)))}
                                        backgroundColor={getStateColor(report.reportState)}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReportsTable;
