import React, { useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { updateActivity } from '../../services/apiSLTP/planning';
import { DataItem } from '../../services/apiSLTP/activities';
import Icon from '../Icon';

interface StartTimeCellProps {
    activity: DataItem;
    activityIndex: number;
    disabled?: boolean;
    refetch: () => void;
}

const StartTimeCell: React.FC<StartTimeCellProps> = ({ activity, activityIndex, disabled = false, refetch }) => {
    const [open, setOpen] = useState(false);
    const handleTimeChange = async (time: string) => {
        const response = await updateActivity(activity._id, { startTime: time });
        refetch();
    };

    return (
        <TimePicker
            ampm={false}
            onChange={(value) => (value ? handleTimeChange(value?.toISOString()) : null)}
            value={dayjs(activity.startTime)}
            open={open}
            onClose={() => setOpen(false)}
            slotProps={{
                field: {
                    readOnly: true,
                },
                textField: {
                    variant: 'standard',
                    onClick: disabled ? () => {} : () => setOpen(true),
                    InputProps: {
                        startAdornment: (
                            <Icon name='accessTime' disabled={disabled} />
                        ),
                        sx: {
                            height: '25px',
                        },
                    },
                },
                inputAdornment: {
                    sx: {
                        display: 'none',
                    },
                },
            }}
            disabled={disabled}
        />
    );
};

export default StartTimeCell;
