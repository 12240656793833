import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import DropDown from '../DropDown';
import { getSubContractorTypes, createDailySubContractor } from '../../services/apiSLTP/planning';
import { DataItem, updateDailySubContractor } from '../../services/apiSLTP/activities';
import ModalBody from './ModalBody';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activity: DataItem;
    activityIndex: number;
    refetch: () => void;
    subContractorId: string;
}

const CreateDailySubContractorModal: React.FC<ModalProps> = ({ open, handleClose, activity, activityIndex, refetch, subContractorId }) => {
    const [subContractorsTypes, setSubContractorsTypes] = useState<{ label: string; value: string }[]>([]);
    const [selectedSubContractorType, setSelectedSubContractorType] = useState({ label: '', value: '' });
    const [currentSubContractorId, setCurrentSubContractorId] = useState(subContractorId);
    const { t } = useTranslation();

    const fetchSubContractorsTypes = async () => {
        const response = await getSubContractorTypes();
        setSubContractorsTypes(
            response.data.map((value) => {
                return { label: value.designation, value: value._id };
            })
        );
    };

    const postDailySubContractor = async () => {
        const response = await createDailySubContractor(activity._id, selectedSubContractorType.value);
        setCurrentSubContractorId(response.data._id);
        refetch();
    };

    const patchDailySubContractor = async () => {
        const response = await updateDailySubContractor(currentSubContractorId, {
            subContractorTypeId: selectedSubContractorType.value,
        });
        refetch();
    };

    useEffect(() => {
        fetchSubContractorsTypes();

        const dailySubContractor = activity.dailySubContractors.find((value) => value._id === subContractorId);
        if (dailySubContractor) {
            setSelectedSubContractorType({
                label: dailySubContractor.subContractorTypeId?.designation || '',
                value: dailySubContractor.subContractorTypeId?._id || '',
            });
        }
    }, []);

    return (
        <ModalBody
            closeText={t('cancel')}
            title={!!currentSubContractorId.length ? t('modifySubContractor') : t('addSubContractor')}
            okText={!!currentSubContractorId.length ? t('update') : t('create')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !currentSubContractorId.length ? postDailySubContractor() : patchDailySubContractor();
            }}
        >
            <div className='fx-c'>
                <DropDown
                    selectedItem={selectedSubContractorType}
                    items={subContractorsTypes}
                    handleChange={(event, value) => setSelectedSubContractorType({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('subContractor')}
                />
                {!!selectedSubContractorType.value.length && <></>}
            </div>
        </ModalBody>
    );
};

export default CreateDailySubContractorModal;
