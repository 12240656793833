import React, { useEffect, useState } from 'react';
import UsersSettingsRow from '../components/settingsRows/UsersSettingsRow';
import SitesSettingsRow from '../components/settingsRows/SitesSettingsRow';
import MachinesSettingsRow from '../components/settingsRows/MachinesSettingsRow';
import ToolsSettingsRow from '../components/settingsRows/ToolsSettingsRow';
import JobsSettingsRow from '../components/settingsRows/JobsSettingsRow';
import CustomAlert from '../components/CustomAlert';
import { useUsersQuery } from '../hooks/queries/useUsersQuery';
import { User } from '../services/apiSLTP/users';
import RentalsSettingsRow from '../components/settingsRows/RentalsSettingsRow';
import TempWorkersSettingsRow from '../components/settingsRows/TempWorkersSettingsRow';
import SubContractorsSettingsRow from '../components/settingsRows/SubContractorsSettingsRow';
import { useJobsQuery } from '../hooks/queries/useJobsQuery';
import { Job } from '../services/apiSLTP/jobs';
import { Profile } from '../services/apiSLTP/profiles';
import { useProfilesQuery } from '../hooks/queries/useProfilesQuery';
import { useAuth } from '../hooks/useAuth';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import TrucksSettingsRow from '../components/settingsRows/TrucksSettingsRow';
import { useTrucksTypesQuery } from '../hooks/queries/useTrucksTypesQuery';
import { useTrucksCompaniesQuery } from '../hooks/queries/useTrucksCompaniesQuery';
import { TruckType } from '../services/apiSLTP/materials/trucksTypes';
import { TruckCompany } from '../services/apiSLTP/materials/trucksCompanies';
import TrucksTypesSettingsRow from '../components/settingsRows/TrucksTypesSettingsRow';
import TrucksCompaniesSettingsRow from '../components/settingsRows/TrucksCompaniesSettingsRow';
import { useLoadNaturesQuery } from '../hooks/queries/useLoadNaturesQuery';
import LoadNaturesSettingsRow from '../components/settingsRows/LoadNaturesSettingsRow';
import { LoadNature } from '../services/apiSLTP/materials/loadNatures';
import Header from '../components/Header';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../utils/localStorage';
import { localStorageKeys } from '../utils/enums';
import Icon from '../components/Icon';
import { capitalizeFirstLetter } from '../utils/capitalize';
import QuarriesSettingsRow from '../components/settingsRows/QuarriesSettingsRow';
import DumpsSettingsRow from '../components/settingsRows/DumpsSettingsRow';
import EntitiesSettingsRow from '../components/settingsRows/EntitiesSettingsRow';

const AdminPage: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [trucksTypes, setTrucksTypes] = useState<TruckType[]>([]);
    const [trucksCompanies, setTrucksCompanies] = useState<TruckCompany[]>([]);
    const [loadNatures, setLoadNatures] = useState<LoadNature[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [universalFilter, setUniversalFilter] = useState('');
    const [addFtn, setAddFtn] = useState<() => void>(() => {});

    const { t } = useTranslation();

    const { data: usersData, isLoading: usersIsLoading, isError: usersIsError, isSuccess: usersIsSuccess, refetch: usersRefetch } = useUsersQuery({});
    const { data: jobsData, isLoading: jobsIsLoading, isError: jobsIsError, isSuccess: jobsIsSuccess, refetch: jobsRefetch } = useJobsQuery({});
    const {
        data: profilesData,
        isLoading: profilesIsLoading,
        isError: profilesIsError,
        isSuccess: profilesIsSuccess,
        refetch: profilesRefetch,
    } = useProfilesQuery({});
    const {
        data: trucksTypesData,
        isLoading: trucksTypesIsLoading,
        isError: trucksTypesIsError,
        isSuccess: trucksTypesIsSuccess,
        refetch: trucksTypesRefetch,
    } = useTrucksTypesQuery({});
    const {
        data: trucksCompaniesData,
        isLoading: trucksCompaniesIsLoading,
        isError: trucksCompaniesIsError,
        isSuccess: trucksCompaniesIsSuccess,
        refetch: trucksCompaniesRefetch,
    } = useTrucksCompaniesQuery({});
    const {
        data: loadNaturesData,
        isLoading: loadNaturesIsLoading,
        isError: loadNaturessIsError,
        isSuccess: loadNaturesIsSuccess,
        refetch: loadNaturesRefetch,
    } = useLoadNaturesQuery({});

    const { user } = useAuth();

    useEffect(() => {
        if (usersData && usersIsSuccess) {
            setUsers(usersData);
        }
    }, [usersData, usersIsSuccess]);

    useEffect(() => {
        if (jobsData && jobsIsSuccess) {
            setJobs(jobsData);
        }
    }, [jobsData, jobsIsSuccess]);

    useEffect(() => {
        if (profilesData && profilesIsSuccess) {
            setProfiles(profilesData);
        }
    }, [profilesData, profilesIsSuccess]);

    useEffect(() => {
        if (trucksTypesData && trucksTypesIsSuccess) {
            setTrucksTypes(trucksTypesData);
        }
    }, [trucksTypesData, trucksTypesIsSuccess]);

    useEffect(() => {
        if (trucksCompaniesData && trucksCompaniesIsSuccess) {
            setTrucksCompanies(trucksCompaniesData);
        }
    }, [trucksCompaniesData, trucksCompaniesIsSuccess]);

    useEffect(() => {
        if (loadNaturesData && loadNaturesIsSuccess) {
            setLoadNatures(loadNaturesData);
        }
    }, [loadNaturesData, loadNaturesIsSuccess]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        storeItemToLocalStorage(localStorageKeys.ADMINTAB, newValue.toString());
    };

    useEffect(() => {
        const tab = getItemFromLocalStorage(localStorageKeys.ADMINTAB);
        setSelectedTab(parseInt(tab || '0'));
    }, []);

    return (
        <div>
            <Header title={t('admin')} isDateVisible={false}>
                <Icon name="addCircle" onClick={addFtn} title={capitalizeFirstLetter(t('add'))} classes={{ iconButton: 'pad-10px-imp' }} dark />
            </Header>
            <div className="pad-l-15px pad-r-15px">
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="admin page tabs"
                    classes={{ indicator: 'bg-black-imp' }}
                >
                    <Tab label={t('users')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('sites')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('machines')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('tools')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('rentals')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('tempWorkers')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('subContractors')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('jobs')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('trucks')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('trucksTypes')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('trucksCompanies')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('loadNatures')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('quarries')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('dumps')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('entities')} classes={{ selected: 'text-black-imp' }} />
                </Tabs>

                {selectedTab === 0 && (
                    <UsersSettingsRow
                        users={users}
                        setUsers={setUsers}
                        isLoading={usersIsLoading}
                        isError={usersIsError}
                        isSuccess={usersIsSuccess}
                        jobs={jobs}
                        profiles={profiles}
                        refetch={() => {
                            usersRefetch();
                        }}
                        universalFilter={universalFilter}
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 1 && (
                    <SitesSettingsRow
                        users={users}
                        usersIsSuccess={usersIsSuccess}
                        universalFilter={universalFilter}
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 2 && (
                    <MachinesSettingsRow
                        users={users}
                        usersIsSuccess={usersIsSuccess}
                        universalFilter={universalFilter}
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 3 && <ToolsSettingsRow universalFilter={universalFilter} setAddFtn={(ftn: () => void) => setAddFtn(ftn)} />}
                {selectedTab === 4 && <RentalsSettingsRow universalFilter={universalFilter} setAddFtn={(ftn: () => void) => setAddFtn(ftn)} />}
                {selectedTab === 5 && <TempWorkersSettingsRow universalFilter={universalFilter} setAddFtn={(ftn: () => void) => setAddFtn(ftn)} />}
                {selectedTab === 6 && <SubContractorsSettingsRow universalFilter={universalFilter} setAddFtn={(ftn: () => void) => setAddFtn(ftn)} />}
                {selectedTab === 7 && (
                    <JobsSettingsRow
                        jobs={jobs}
                        setJobs={setJobs}
                        isError={jobsIsError}
                        isLoading={jobsIsLoading}
                        isSuccess={jobsIsSuccess}
                        refetch={() => {
                            jobsRefetch();
                        }}
                        universalFilter={universalFilter}
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 8 && (
                    <TrucksSettingsRow
                        users={users}
                        usersIsSuccess={usersIsSuccess}
                        trucksTypes={trucksTypes}
                        trucksTypesIsSuccess={trucksTypesIsSuccess}
                        trucksCompanies={trucksCompanies}
                        trucksCompaniesIsSuccess={trucksCompaniesIsSuccess}
                        universalFilter={universalFilter}
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 9 && (
                    <TrucksTypesSettingsRow
                        trucksTypes={trucksTypes}
                        setTrucksTypes={setTrucksTypes}
                        isSuccess={trucksTypesIsSuccess}
                        refetch={() => {
                            trucksTypesRefetch();
                        }}
                        universalFilter={universalFilter}
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 10 && (
                    <TrucksCompaniesSettingsRow
                        setTrucksCompanies={setTrucksCompanies}
                        trucksCompanies={trucksCompanies}
                        isSuccess={trucksCompaniesIsSuccess}
                        isLoading={trucksCompaniesIsLoading}
                        refetch={() => {
                            trucksCompaniesRefetch();
                        }}
                        universalFilter={universalFilter}
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 11 && (
                    <LoadNaturesSettingsRow
                        setLoadNatures={setLoadNatures}
                        loadNatures={loadNatures}
                        isSuccess={loadNaturesIsSuccess}
                        isLoading={loadNaturesIsLoading}
                        refetch={() => {
                            loadNaturesRefetch();
                        }}
                        universalFilter={universalFilter}
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 12 && (
                    <QuarriesSettingsRow
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 13 && (
                    <DumpsSettingsRow
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
                {selectedTab === 14 && (
                    <EntitiesSettingsRow
                        setAddFtn={(ftn: () => void) => setAddFtn(ftn)}
                    />
                )}
            </div>
        </div>
    );
};

export default AdminPage;
