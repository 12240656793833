import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyRental } from '../../services/apiSLTP/planning';
import { materialState } from '../../utils/enums';
import { DataItem } from '../../services/apiSLTP/activities';

interface RentalsTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const RentalsTable: React.FC<RentalsTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const getMaterialStateText = (state: number) => {
        switch (state) {
            case materialState.NEW:
                return t('new');
            case materialState.PRESENT:
                return t('present');
            case materialState.REMOVE:
                return t('remove');
            default:
                return '';
        }
    };

    const filterActivities = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (activity: DataItem) => {
            const { siteId, dailyRentals } = activity;

            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(siteId?.name) ||
                dailyRentals.some(
                    (rental) =>
                        matchesSearchTerm(rental.designation) ||
                        matchesSearchTerm(rental.rentalTypeId.designation) ||
                        matchesSearchTerm(getMaterialStateText(rental.state))
                )
            );
        };

        return activities.filter(matchesFilter);
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('name'))}
                        </th>

                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('state'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {activities.map((activity) =>
                        activity.dailyRentals.map((rental, index) => (
                            <tr key={rental._id} className="row">
                                <td className="pos-sy left-0 z-idx-2">{activity.siteId.name}</td>
                                <td className="row-cell-codeproc">{rental.rentalTypeId.designation}</td>
                                <td className="row-cell-designation">{rental.designation}</td>
                                <td className="row-cell-state">{getMaterialStateText(rental.state)}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default RentalsTable;
