// src/components/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="pos-fi text-white bottom-0 w-100">
      <div className="fx-r fx-justify-c">
        <p className="sFont text-white">Copyright © 2024 SLTP. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
