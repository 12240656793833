import { useQuery, QueryFunction } from 'react-query';
import { getAllActiveMachines, getAllMachines, Machine } from '../../services/apiSLTP/materials/machines';

type UseMachinesQueryOptions = {};

const getMachines: QueryFunction<Machine[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseMachinesQueryOptions];
    const {} = options;

    const response = await getAllMachines();
    return response.data;
};

export const useMachinesQuery = (options: UseMachinesQueryOptions) => {
    return useQuery(['machines', options], getMachines);
};

type UseActiveMachinesQueryOptions = {};

const getActiveMachines: QueryFunction<Machine[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseActiveMachinesQueryOptions];
    const {} = options;

    const response = await getAllActiveMachines();
    return response.data;
};

export const useActiveMachinesQuery = (options: UseActiveMachinesQueryOptions) => {
    return useQuery(['active machines', options], getActiveMachines);
};
