import React, { useState } from 'react';
import { Modal, Button, LinearProgress } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DropDown from '../DropDown';
import dayjs from 'dayjs';
import { DataItem } from '../../services/apiSLTP/activities';
import { createActivity, UserItem } from '../../services/apiSLTP/planning';
import { permissionLevels, reportState, stepModes } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { SelectChangeEvent } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';
import CreateActivityModal from './CreateActivityModal';
import { useMachinesQuery } from '../../hooks/queries/useMachinesQuery';
import { useToolsQuery } from '../../hooks/queries/useToolsQuery';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activities: DataItem[];
    initDate: string;
    refetch: () => void;
}

const RemainingMaterialModal: React.FC<ModalProps> = ({ open, handleClose, activities, initDate, refetch }) => {
    const { t } = useTranslation();
    const { data: dataMachines, isLoading: isMachinesLoading } = useMachinesQuery({});
    const { data: dataTools, isLoading: isToolsLoading } = useToolsQuery({});

    const handleRemainingMaterial = () => {
        handleClose();
    };

    const getRemainingMaterial = () => {
        const activitiesMachines = activities.map((activity) => activity.dailyMachines.map((machine) => machine.machineId._id)).flat();
        const activitiesTools = activities.map((activity) => activity.dailyTools.map((tool) => tool.toolId._id)).flat();
        const sortedMachines = dataMachines?.sort((a, b) => a.designation.localeCompare(b.designation));
        const sortedTools = dataTools?.sort((a, b) => a.designation.localeCompare(b.designation));
        return {
            machines: sortedMachines?.filter((machine) => !activitiesMachines.includes(machine._id)),
            tools: sortedTools?.filter((user) => !activitiesTools.includes(user._id)),
        };
    };

    return (
        <ModalBody
            closeText={t('cancel')}
            okText={t('ok')}
            title={t('remainingMaterial')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                handleRemainingMaterial();
            }}
            disabledOk={false}
        >
            <div className="fx-c h-300px w-100" style={{ overflowY: 'scroll' }}>
                <h3 className="m-b-5px">{capitalizeFirstLetter(t('machines'))} :</h3>
                <div className="loading-bar">{isMachinesLoading && <LinearProgress color="inherit" />}</div>
                {getRemainingMaterial().machines?.map((machine) => (
                    <div key={machine._id} className="fx-r w-300px fx-justify-sb bg-lightgray border-radius-5px pad-3px m-t-5px fx-items-c">
                        <div className="one-line">{capitalizeFirstLetter(machine.designation)}</div>
                    </div>
                ))}
                <h3 className="m-b-5px">{capitalizeFirstLetter(t('tools'))} :</h3>
                <div className="loading-bar">{isToolsLoading && <LinearProgress color="inherit" />}</div>
                {getRemainingMaterial().tools?.map((tool) => (
                    <div key={tool._id} className="fx-r w-300px fx-justify-sb bg-lightgray border-radius-5px pad-3px m-t-5px fx-items-c">
                        <div className="one-line">{capitalizeFirstLetter(tool.designation)}</div>
                    </div>
                ))}
            </div>
        </ModalBody>
    );
};

export default RemainingMaterialModal;
