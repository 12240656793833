import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Quarry } from '../../services/apiSLTP/sites/quarries';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateQuarryMutation, useUpdateQuarryMutation } from '../../hooks/mutations/useQuarryMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    quarry: Quarry;
    isEditMode?: boolean;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateQuarryModal: React.FC<ModalProps> = ({ quarry, isEditMode = true, showEditModal, setShowEditModal, refetch }) => {
    const { t } = useTranslation();
    const [tmpQuarry, setTmpQuarry] = useState(quarry);

    const { user } = useAuth();

    const {
        mutate: mutateQuarry,
        isSuccess: isSuccessQuarry,
        isError: isErrorQuarry,
        isLoading: isLoadingQuarry,
        error: errorQuarry,
        data: dataQuarry,
    } = useUpdateQuarryMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating quarry:', error.message);
        },
    });

    const {
        mutate: addQuarry,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateQuarryMutation({
        onSuccess: (data, variables) => {
            {
                /*setQuarries((prevQuarries) => {
                const newQuarries = [...prevQuarries];
                const index = newQuarries.findIndex((value) => value._id === quarry._id);
                newQuarries.push(data.data); // change here
                return newQuarries;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating quarry:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpQuarry((prevQuarry) => {
            const newQuarry = { ...prevQuarry };
            newQuarry.designation = text;
            return newQuarry;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpQuarry((prevQuarry) => {
                const newQuarry = { ...prevQuarry };
                newQuarry.crudStatus = crudStatus.OK;
                return newQuarry;
            });
        } else {
            setTmpQuarry((prevQuarry) => {
                const newQuarry = { ...prevQuarry };
                newQuarry.crudStatus = crudStatus.INACTIVE;
                return newQuarry;
            });
        }
    };

    return (
        <ModalBody
            title={isEditMode ? t('updateQuarry') : t('createQuarry')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation()}
            handleOk={() => {
                isEditMode
                    ? mutateQuarry({
                          quarryId: quarry._id,
                          data: {
                              designation: tmpQuarry.designation,
                              crudStatus: tmpQuarry.crudStatus,
                          },
                      })
                    : addQuarry({ designation: tmpQuarry.designation });
            }}
        >
            <CustomTextInput
                value={tmpQuarry.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
            />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpQuarry.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
        </ModalBody>
    );
};

export default UpdateQuarryModal;
