import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { crudStatus } from '../../utils/enums';
import { Job } from '../../services/apiSLTP/jobs';
import { useCreateJobMutation, useUpdateJobMutation } from '../../hooks/mutations/useJobMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    job: Job;
    jobs: Job[];
    isEditMode?: boolean;
    setJobs: React.Dispatch<React.SetStateAction<Job[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateJobModal: React.FC<ModalProps> = ({ job, isEditMode = true, setJobs, showEditModal, setShowEditModal, jobs, refetch }) => {
    const { t } = useTranslation();
    const [tmpJob, setTmpJob] = useState(job);

    const { user } = useAuth();

    const {
        mutate: mutateJob,
        isSuccess: isSuccessJob,
        isError: isErrorJob,
        isLoading: isLoadingJob,
        error: errorJob,
        data: dataJob,
    } = useUpdateJobMutation({
        onSuccess: (data, variables) => {
            {/*setJobs((prevJobs) => {
                const newJobs = [...prevJobs];
                const index = newJobs.findIndex((value) => value._id === job._id);
                newJobs[index] = data.data; // change here
                return newJobs;
            });*/}
            refetch();
        },
        onError: (error) => {
            console.error('Error updating job:', error.message);
        },
    });

    const {
        mutate: addJob,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateJobMutation({
        onSuccess: (data, variables) => {
            {/*setJobs((prevJobs) => {
                const newJobs = [...prevJobs];
                newJobs.push(data.data); // change here
                return newJobs;
            });*/}
            refetch();
        },
        onError: (error) => {
            console.error('Error updating job:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpJob((prevJob) => {
            const newJob = { ...prevJob };
            newJob.designation = text;
            return newJob;
        });
    };

    const handleOrderChange = (text: string) => {
        setTmpJob((prevJob) => {
            const newJob = { ...prevJob };
            newJob.order = parseInt(text);
            return newJob;
        });
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpJob((prevJob) => {
                const newJob = { ...prevJob };
                newJob.crudStatus = crudStatus.OK;
                return newJob;
            });
        } else {
            setTmpJob((prevJob) => {
                const newJob = { ...prevJob };
                newJob.crudStatus = crudStatus.INACTIVE;
                return newJob;
            });
        }
    };

    const verifyDesignation = () => {
        return true;
    };

    const verifyOrder = () => {
        return true;
    };

    return (
        <ModalBody
        title={isEditMode ? t('updateJob') : t('createJob')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation() || !verifyOrder()}
            handleOk={() => {
                isEditMode
                    ? mutateJob({
                          jobId: job._id,
                          data: {
                              designation: tmpJob.designation,
                              order: tmpJob.order,
                          },
                      })
                    : addJob({
                          designation: tmpJob.designation,
                          order: tmpJob.order,
                      });
            }}
        >
            <CustomTextInput
                value={tmpJob.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
            />
            {isEditMode && <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                <div>{capitalizeFirstLetter(t('active'))}</div><CustomToggle checked={tmpJob.crudStatus === crudStatus.OK} onChange={handleActiveChange} /></div>}
            <CustomTextInput
                value={tmpJob.order}
                type="number"
                label={t('order')}
                onChange={handleOrderChange}
                //error={!verifyOrder()}
                errorText={t('error')}
            />
        </ModalBody>
    );
};

export default UpdateJobModal;
