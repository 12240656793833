import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface TempWorker {
    crudStatus: number;
    _id: string;
    designation: string;
}

interface TempWorkersResponse {
    message: string;
    data: TempWorker[];
}

export const getAllTempWorkers = async (): Promise<TempWorkersResponse> => {
    try {
        const response: AxiosResponse<TempWorkersResponse> = await apiClient.get('/tempworker-types');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateTempWorkerBody {
    designation?: string;
    crudStatus?: number;
}

export interface TempWorkerResponse {
    message: string;
    data: TempWorker;
}

export const updateTempWorker = async (tempWorkerId: string, data: UpdateTempWorkerBody): Promise<TempWorkerResponse> => {
    try {
        const response: AxiosResponse<TempWorkerResponse> = await apiClient.patch('/tempworker-types/' + tempWorkerId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateTempWorkerBody {
    designation: string;
}

export const createTempWorker = async (data: CreateTempWorkerBody): Promise<TempWorkerResponse> => {
    try {
        const response: AxiosResponse<TempWorkerResponse> = await apiClient.post('/tempworker-types', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
