import React, { useEffect, useState } from 'react';
import { useActivitiesByDateQuery } from '../hooks/queries/useActivitiesQuery';
import { useTranslation } from 'react-i18next';
import {
    DataItem,
    duplicateActivities,
    importActivitiesByConduc,
    importActivitiesByDate,
    importActivitiesBySite,
} from '../services/apiSLTP/activities';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../utils/capitalize';
import { getStateInfos, localStorageKeys, permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../utils/enums';
import MachinesCell from '../components/cells/MachinesCell';
import RentalsCell from '../components/cells/RentalsCell';
import TempWorkersCell from '../components/cells/TempWorkersCell';
import SubContractorsCell from '../components/cells/SubContractorsCell';
import ToolsCell from '../components/cells/ToolsCell';
import CalendarModal from '../components/modals/CalendarModal';
import { getInOneDayDate, getOneDayAgoDate, getTomorrowDate, isRollbackPossible } from '../utils/dates';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from '../hooks/useAuth';
import { getStateColor } from '../utils/colors';
import CustomToggle from '../components/CustomToggle';
import TruckIcon from '../components/TruckIcon';
import { useTrucksQuery } from '../hooks/queries/useTrucksQuery';
import { Truck } from '../services/apiSLTP/materials/trucks';
import ColorBar from '../components/ColorBar';
import { getCurrentConducs } from '../services/apiSLTP/sites/sites';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../utils/localStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useConducsQuery } from '../hooks/queries/useSitesQuery';
import Icon from '../components/Icon';
import StateBadge from '../components/StateBadge';
import TrucksPlannedCell from '../components/cells/TrucksPlannedCell';
import InfosColorsModal from '../components/modals/InfosColorsModal';

const NeedsPage: React.FC = () => {
    const [currentDate, setCurrentDate] = useState(new Date(getItemFromLocalStorage(localStorageKeys.DATE) || getTomorrowDate()));
    const [dragIndex, setDragIndex] = useState({ activityIndex: 0, dailyIndex: 0 });
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [showInfosColorsModal, setShowInfosColorsModal] = useState(false);
    const [universalFilter, setUniversalFilter] = useState('');
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [conducs, setConducs] = useState<{ _id: string; firstName: string; lastName: string }[]>([]);
    const [selectedTab, setSelectedTab] = useState('');

    const { user } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();

    const {
        data: activities,
        isLoading,
        isError,
        isSuccess,
        isRefetching,
        refetch,
        isFetching,
        isIdle,
    } = useActivitiesByDateQuery({
        date: currentDate.toISOString(),
        minState: reportState.NEEDS,
        maxState: reportState.PLANNINGSENT,
        stepMode: stepModes.NEEDS,
    });
    const { data: trucksData, isLoading: trucksIsLoading, isError: trucksIsError, isSuccess: trucksIsSuccess } = useTrucksQuery({});
    const { data: conducsData, isLoading: conducsIsLoading, isError: conducsIsError, isSuccess: conducsIsSuccess } = useConducsQuery({});

    const handleDateChange = (newDate: string) => {
        setCurrentDate(new Date(newDate));
        storeItemToLocalStorage(localStorageKeys.DATE, newDate);
    };

    const importData = async (conducId: string) => {
        /*!conducId.length
            ? await importActivitiesByDate(currentDate.toISOString(), reportState.NEEDSSENT, reportState.PLANNING)
            : await importActivitiesByConduc(conducId, currentDate.toISOString(), reportState.NEEDSSENT, reportState.PLANNING);*/
        await duplicateActivities(currentDate.toISOString(), reportState.NEEDSSENT, stepModes.NEEDS, stepModes.PLANNING, reportState.PLANNING);
        refetch();
    };

    const unfilledData = async (conducId: string) => {
        /*!conducId.length
            ? await importActivitiesByDate(currentDate.toISOString(), reportState.NEEDSSENT, reportState.NEEDS)
            : await importActivitiesByConduc(conducId, currentDate.toISOString(), reportState.NEEDSSENT, reportState.NEEDS);*/
        await importActivitiesByDate(currentDate.toISOString(), reportState.NEEDSSENT, reportState.NEEDS);
        //await importActivitiesByConduc(conducId, currentDate.toISOString(), reportState.NEEDSSENT, reportState.NEEDS);
        refetch();
    };

    const handleActivityState = async (siteId: string, state: number) => {
        //await updateActivity(activityId, { activityState: state });
        await importActivitiesBySite(siteId, currentDate.toISOString(), reportState.NEEDSSENT, state, stepModes.NEEDS);
        refetch();
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
        setSelectedTab(newValue);
        storeItemToLocalStorage(localStorageKeys.NEEDSTAB, newValue);
    };

    const filterActivities = () => {
        const filteredActivities = activities?.filter((activity) => !selectedTab.length || activity.siteId?.conducId?._id === selectedTab);

        if (!universalFilter.length) return filteredActivities;

        const searchTerm = universalFilter.toLowerCase().trim();

        return filteredActivities?.filter((activity) => {
            const {
                siteId,
                reportId,
                observationsPlanning,
                activityTypeId,
                dailyMachines,
                dailyTools,
                dailyRentals,
                dailyTempWorkers,
                dailySubContractors,
            } = activity;

            const searchMatch = (value: any) => value?.toLowerCase().includes(searchTerm);

            return (
                searchMatch(siteId?.name) ||
                searchMatch(siteId?.address) ||
                searchMatch(siteId?.conducId?.firstName) ||
                searchMatch(siteId?.conducId?.lastName) ||
                searchMatch(reportId?.userId?.firstName) ||
                searchMatch(reportId?.userId?.lastName) ||
                searchMatch(observationsPlanning) ||
                searchMatch(activityTypeId?.designation) ||
                dailyMachines.some(
                    (machine) => searchMatch(machine.machineId.designation) || searchMatch(machine.machineId.machineTypeId.designation)
                ) ||
                dailyTools.some((tool) => searchMatch(tool.toolId.designation) || searchMatch(tool.toolId.toolTypeId.designation)) ||
                dailyRentals.some((rental) => searchMatch(rental.designation) || searchMatch(rental.rentalTypeId.designation)) ||
                dailyTempWorkers.some(
                    (tempWorker) => searchMatch(tempWorker.durationDay.toString()) || searchMatch(tempWorker.tempWorkerTypeId.designation)
                ) ||
                dailySubContractors.some((subContractor) => searchMatch(subContractor.subContractorTypeId.designation))
            );
        });
    };

    useEffect(() => {
        if (user) {
            if (user?.profileId.permsLevel > permissionLevels.PLANNING) window.location.replace('/');
        }
    }, [user]);

    useEffect(() => {
        if (conducsData && conducsIsSuccess) setConducs(conducsData);
    }, [conducsData]);

    useEffect(() => {
        refetch();
    }, [location, refetch]);

    useEffect(() => {
        if (trucksData && trucksIsSuccess) {
            setTrucks(trucksData);
        }
    }, [trucksData]);

    useEffect(() => {
        if (conducsIsSuccess) {
            const tab = getItemFromLocalStorage(localStorageKeys.NEEDSTAB);
            setSelectedTab(tab || '');
        }
    }, [conducs]);

    return (
        <div>
            <Header
                isSearchable
                title={t('needs')}
                onSearch={(text) => setUniversalFilter(text)}
                searchValue={universalFilter}
                date={currentDate}
                onLeftArrowClick={() => handleDateChange(getOneDayAgoDate(currentDate).toISOString())}
                onRightArrowClick={() => handleDateChange(getInOneDayDate(currentDate).toISOString())}
            >
                <Icon
                    name="eventRepeat"
                    onClick={() => handleDateChange(new Date().toISOString())}
                    title={capitalizeFirstLetter(t('today'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="calendarMonth"
                    onClick={() => setShowCalendarModal(true)}
                    title={capitalizeFirstLetter(t('calendar'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <div className="divider" />
                <Icon
                    name="palette"
                    onClick={() => setShowInfosColorsModal(true)}
                    title={capitalizeFirstLetter(t('colors'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="addToHomeScreen"
                    onClick={() => unfilledData(selectedTab)}
                    title={capitalizeFirstLetter(t('sendBackNeeds'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="verticalAlignBottom"
                    onClick={() => importData(selectedTab)}
                    title={capitalizeFirstLetter(t('importNeeds'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
            </Header>
            <div className="pad-l-15px pad-r-15px">
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="admin page tabs"
                    classes={{ indicator: 'bg-black-imp', root: '' }}
                >
                    <Tab value={''} label={t('all')} classes={{ selected: 'text-black-imp', root: '' }} />
                    {/*conducs.map((conduc, index) => (
                        <Tab
                            classes={{ selected: 'text-black-imp' }}
                            key={index}
                            value={conduc._id}
                            label={capitalizeFirstLetter(conduc.firstName) + ' ' + conduc.lastName.toUpperCase()}
                        />
                    ))*/}
                </Tabs>

                <div className="loading-bar">{isLoading && <LinearProgress color="inherit" />}</div>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4">
                                    {capitalizeFirstLetter(t(''))}
                                </th>
                                <th scope="col" className="w-200px pos-sy left-30px z-idx-4">
                                    {capitalizeFirstLetter(t('site'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('address'))}
                                </th>
                                <th scope="col" className="w-75px">
                                    {capitalizeFirstLetter(t('start'))}
                                </th>
                                <th scope="col" className="w-200px left-230px z-idx-4">
                                    {capitalizeFirstLetter(t('person'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('tempWorkers'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('machines'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('tools'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('rentals'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('subContractors'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('trucks'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('observations'))}
                                </th>
                                <th scope="col" className="w-100px">
                                    <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('truckManager'))}</div>
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('activity'))}
                                </th>
                                <th scope="col" className="w-75px">
                                    <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('activityStatus'))}</div>
                                </th>
                                <th scope="col" className="w-100px">
                                    {capitalizeFirstLetter(t('actions'))}
                                </th>
                            </tr>
                        </thead>
                        <tbody id="fu-planning-obj">
                            {!activities?.length && (
                                <tr className="row-empty">
                                    <td className="cell-empty" colSpan={14}>
                                        {capitalizeFirstLetter(t('noActivity'))}
                                    </td>
                                </tr>
                            )}
                            {(activities || [])
                                .sort((a, b) => {
                                    if (!(!a.siteId?.conducId?._id && !b.siteId?.conducId?._id)) {
                                        if (!a.siteId?.conducId?._id) return -1;
                                        if (!b.siteId?.conducId?._id) return 1;
                                    }
                                    if (a.siteId?.conducId?._id < b.siteId?.conducId?._id) return -1;
                                    if (a.siteId?.conducId?._id > b.siteId?.conducId?._id) return 1;
                                    const siteA = a.siteId.name.toLowerCase();
                                    const siteB = b.siteId.name.toLowerCase();
                                    if (siteA < siteB) return -1;
                                    if (siteA > siteB) return 1;
                                    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
                                })
                                .map((activity, index, array) => (
                                    <tr
                                        key={activity._id}
                                        className="row"
                                        style={{
                                            borderTopWidth: !(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id)
                                                ? '1px'
                                                : '',
                                        }}
                                    >
                                        <td className="pos-sy left-0 pad-0-imp z-idx-2">
                                            <ColorBar
                                                backgroundColor={activity.siteId?.conducId?.color || 'black'}
                                                isPaddingTop={!(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id)}
                                                isPaddingBottom={!(index < array.length - 1 && array[index + 1].siteId?._id === activity.siteId?._id)}
                                                title={
                                                    activity.siteId?.conducId?._id
                                                        ? capitalizeFirstLetter(activity.siteId?.conducId?.firstName) +
                                                          ' ' +
                                                          activity.siteId?.conducId?.lastName.toUpperCase()
                                                        : ''
                                                }
                                            />
                                        </td>
                                        <td className="pos-sy left-30px z-idx-2">
                                            <div className="font-w-600">{activity.siteId.name}</div>
                                            {capitalizeFirstLetter(activity.siteId?.conducId?.firstName)} {activity.siteId?.conducId?.lastName}
                                        </td>
                                        <td>
                                            <div>{activity.siteId.address}</div>
                                        </td>
                                        <td>
                                            {new Date(activity.startTime).getHours().toString().padStart(2, '0')}:
                                            {new Date(activity.startTime).getMinutes().toString().padStart(2, '0')}
                                        </td>
                                        <td className="pos-sy left-230px z-idx-2">
                                            {capitalizeFirstLetter(activity.reportId.userId.firstName)}{' '}
                                            {activity.reportId.userId.lastName.toUpperCase()}
                                        </td>
                                        <td>
                                            <TempWorkersCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                        </td>
                                        <td>
                                            <MachinesCell
                                                activity={activity}
                                                activityIndex={index}
                                                activities={array}
                                                disabled
                                                refetch={refetch}
                                                hiddenPlusIcon
                                            />
                                        </td>
                                        <td>
                                            <ToolsCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                        </td>
                                        <td>
                                            <RentalsCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                        </td>
                                        <td>
                                            <SubContractorsCell
                                                activity={activity}
                                                activityIndex={index}
                                                activities={array}
                                                disabled
                                                hiddenPlusIcon
                                            />
                                        </td>
                                        <td>
                                            <TrucksPlannedCell activity={activity} activityIndex={index} disabled hiddenPlusIcon />
                                        </td>
                                        <td>{activity.observationsPlanning}</td>
                                        <td>
                                            <div className="fx-r fx-justify-c">
                                                {activity.activityTypeId._id !== truckRideActivityTypeId ? (
                                                    <CustomToggle
                                                        checked={activity.isTruckManager}
                                                        disabled
                                                        title={capitalizeFirstLetter(activity.isTruckManager ? t('yes') : t('no'))}
                                                    />
                                                ) : (
                                                    <TruckIcon
                                                        type={
                                                            (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)?.truckTypeId
                                                                .designation || '') as 'Semie' | 'Ampliroll' | '8x4' | 'Porte-char' | ''
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </td>
                                        <td>{activity.activityTypeId.designation}</td>
                                        <td>
                                            <div className="fx-r fx-justify-c">
                                                <StateBadge
                                                    backgroundColor={getStateColor(activity.activityState)}
                                                    title={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            {activity.activityState === reportState.NEEDSSENT && (
                                                <Icon
                                                    name="addToHomeScreen"
                                                    onClick={() => handleActivityState(activity.siteId._id, reportState.NEEDS)}
                                                    title={t('sendBackTheNeed')}
                                                />
                                            )}
                                            {activity.activityState === reportState.NEEDSSENT && (
                                                <Icon
                                                    name="verticalAlignBottom"
                                                    onClick={() => handleActivityState(activity.siteId._id, reportState.PLANNING)}
                                                    disabled
                                                    title={t('importTheNeed')}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                {showInfosColorsModal && <InfosColorsModal open={showInfosColorsModal} handleClose={() => setShowInfosColorsModal(false)} />}
                {showCalendarModal && <CalendarModal
                    currentDate={currentDate}
                    open={showCalendarModal}
                    handleClose={() => setShowCalendarModal(false)}
                    setCurrentDate={handleDateChange}
                />}
            </div>
        </div>
    );
};

export default NeedsPage;
