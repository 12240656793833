import axios, { AxiosResponse } from 'axios';
import { apiClient } from './endpoints';

export interface Job {
    _id: string;
    designation: string;
    order: number;
    crudStatus: number;
}

interface JobsResponse {
    message: string;
    data: Job[];
}

export const getAllJobs = async (): Promise<JobsResponse> => {
    try {
        const response: AxiosResponse<JobsResponse> = await apiClient.get('/jobs');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateJobBody {
    designation?: string;
    order?: number;
    crudStatus?: number;
}

export interface JobResponse {
    message: string;
    data: Job;
}

export const updateJob = async (jobId: string, data: UpdateJobBody): Promise<JobResponse> => {
    try {
        const response: AxiosResponse<JobResponse> = await apiClient.patch('/jobs/' + jobId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateJobBody {
    designation: string;
    order: number;
}

export const createJob = async (data: CreateJobBody): Promise<JobResponse> => {
    try {
        const response: AxiosResponse<JobResponse> = await apiClient.post('/jobs', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
