import React, { useEffect, useState } from 'react';
import { useActivitiesByDateQuery } from '../hooks/queries/useActivitiesQuery';
import { useTranslation } from 'react-i18next';
import {
    cancelActivities,
    cancelActivity,
    DataItem,
    duplicateActivities,
    duplicateActivity,
    importActivitiesByDate,
} from '../services/apiSLTP/activities';
import {
    Site,
    UserItem,
    deleteActivity,
    getActivitiesTypes,
    getSites,
    getUsersBetweenPermsLevel,
    updateActivity,
} from '../services/apiSLTP/planning';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../utils/capitalize';
import { getStateInfos, localStorageKeys, permissionLevels, reportState, stepModes, truckRideActivityTypeId } from '../utils/enums';
import ActivityCell from '../components/cells/ActivityCell';
import StartTimeCell from '../components/cells/StartTimeCell';
import ObservationsCell from '../components/cells/ObservationsCell';
import MachinesCell from '../components/cells/MachinesCell';
import RentalsCell from '../components/cells/RentalsCell';
import TempWorkersCell from '../components/cells/TempWorkersCell';
import SubContractorsCell from '../components/cells/SubContractorsCell';
import ToolsCell from '../components/cells/ToolsCell';
import CreateActivityModal from '../components/modals/CreateActivityModal';
import PersonCell from '../components/cells/PersonCell';
import CalendarModal from '../components/modals/CalendarModal';
import { getInOneDayDate, getOneDayAgoDate, getTomorrowDate, isRollbackPossible } from '../utils/dates';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from '../hooks/useAuth';
import { getStateColor, getTextColor } from '../utils/colors';
import CustomToggle from '../components/CustomToggle';
import { updateReportState } from '../services/apiSLTP/dailyReports/reports';
import TruckIcon from '../components/TruckIcon';
import { useTrucksQuery } from '../hooks/queries/useTrucksQuery';
import { Truck } from '../services/apiSLTP/materials/trucks';
import ColorBar from '../components/ColorBar';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../utils/localStorage';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import StateBadge from '../components/StateBadge';
import Icon from '../components/Icon';
import TrucksPlannedCell from '../components/cells/TrucksPlannedCell';
import RemainingPeopleModal from '../components/modals/RemainingPeopleModal';
import { useKeyPress, KeyCode } from '@xyflow/react';
import RemainingMaterialModal from '../components/modals/RemainingMaterialModal';
import AmplirollTableRows from '../components/tables/AmplirollsTableRows';
import InfosColorsModal from '../components/modals/InfosColorsModal';
import ModalBody from '../components/modals/ModalBody';
import PlanningTrucksPage from '../components/tables/PlanningTrucksPage';

const PlanningPage: React.FC = () => {
    const [currentDate, setCurrentDate] = useState(new Date(getItemFromLocalStorage(localStorageKeys.DATE) || getTomorrowDate()));
    const [sites, setSites] = useState<Site[]>([]);
    const [usersByPermsLevel, setUsersByPermsLevel] = useState<UserItem[]>([]);
    const [activitiesTypes, setActivitiesTypes] = useState<{ label: string; value: string }[]>([]);
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showPeopleModal, setShowPeopleModal] = useState(false);
    const [showMaterialModal, setShowMaterialModal] = useState(false);
    const [showInfosColorsModal, setShowInfosColorsModal] = useState(false);
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [universalFilter, setUniversalFilter] = useState('');
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedSiteId, setSelectedSiteId] = useState('');
    const [selectedAddress, setSelectedAddress] = useState<Site | null>(null);

    const { user } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();
    const ctrlKeyPressed = useKeyPress(['Control']);
    const shiftKeyPressed = useKeyPress(['Shift']);
    const leftKeyPressed = useKeyPress(['ArrowLeft']);
    const rightKeyPressed = useKeyPress(['ArrowRight']);

    const {
        data: activities,
        isLoading,
        isError,
        isSuccess,
        isRefetching,
        refetch,
    } = useActivitiesByDateQuery({
        date: currentDate.toISOString(),
        minState: reportState.NEEDS,
        maxState: reportState.REPORTFILLED,
        stepMode: stepModes.PLANNING,
    });
    const { data: trucksData, isLoading: trucksIsLoading, isError: trucksIsError, isSuccess: trucksIsSuccess } = useTrucksQuery({});
    const { data: dataTrucks, isLoading: isLoadingTrucks, isError: isErrorTrucks, isSuccess: isSuccessTrucks } = useTrucksQuery({});

    const handleDateChange = (newDate: string) => {
        setCurrentDate(new Date(newDate));
        storeItemToLocalStorage(localStorageKeys.DATE, newDate);
    };

    const fetchUsers = async () => {
        const response = await getUsersBetweenPermsLevel(permissionLevels.CHAUFFEUR, permissionLevels.ADMIN);
        setUsersByPermsLevel(response.data);
    };

    const fetchActivitiesTypes = async () => {
        const response = await getActivitiesTypes();
        setActivitiesTypes(
            response.data.map((type) => {
                return { label: type.designation, value: type?._id };
            })
        );
    };

    const fetchSites = async () => {
        const response = await getSites();
        setSites(response.data);
    };

    const handleDeleteActivity = async (activityId: string) => {
        const response = await deleteActivity(activityId);
        refetch();
    };

    const submitData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.PLANNING, reportState.REPORT);
        if (!activityId.length) {
            await duplicateActivities(currentDate.toISOString(), reportState.PLANNING, stepModes.PLANNING, stepModes.REPORT, reportState.REPORT);
        } else {
            await duplicateActivity(activityId, stepModes.REPORT, reportState.REPORT);
        }
        refetch();
    };

    const rollBackData = async (activityId: string) => {
        //const response = await importActivitiesByDate(currentDate.toISOString(), reportState.REPORT, reportState.PLANNING);
        if (!activityId.length) {
            await cancelActivities(currentDate.toISOString(), reportState.REPORT, stepModes.REPORT, stepModes.PLANNING, reportState.PLANNING);
        } else {
            await cancelActivity(activityId, reportState.REPORT, stepModes.REPORT);
        }
        refetch();
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
        setSelectedTab(newValue);
    };

    const handleActivityState = async (activityId: string, reportId: string, state: number) => {
        await updateActivity(activityId, { activityState: state });
        if (state === reportState.REPORT) await updateReportState(reportId, state);
        refetch();
    };

    const handleActivityTruckManager = async (id: string, isTruckManager: boolean, siteId: string) => {
        await updateActivity(id, { isTruckManager });
        const currentTruckManager = activities?.find((value) => value.siteId._id === siteId && value.isTruckManager);
        if (currentTruckManager) await updateActivity(currentTruckManager._id, { isTruckManager: false });
        refetch();
    };

    const checkEditableRow = (activity: DataItem) => {
        if (activity.activityState <= reportState.PLANNINGSENT) return false;
        return true;
    };

    const filterUsers = (activity: DataItem) => {
        if (ctrlKeyPressed && shiftKeyPressed) {
            //return usersByPermsLevel.filter((user) => user.profileId.permsLevel <= permissionLevels.CONDUC);
            return usersByPermsLevel;
        } else {
            return usersByPermsLevel.filter((user) => user.profileId.permsLevel === activity.reportId.userId.profileId.permsLevel);
        }
    };

    const setSpecificHour = () => {
        const date = new Date(currentDate);
        date.setHours(7, 0);
        return date.toISOString();
    };

    useEffect(() => {
        if (user) {
            if (user?.profileId.permsLevel > permissionLevels.PLANNING) window.location.replace('/');
        }
    }, [user]);

    useEffect(() => {
        fetchUsers();
        fetchActivitiesTypes();
        fetchSites();
    }, []);

    useEffect(() => {
        refetch();
    }, [location, refetch]);

    useEffect(() => {
        if (trucksData && trucksIsSuccess) {
            setTrucks(trucksData);
        }
    }, [trucksData]);

    return (
        <div className="planning">
            <Header
                isSearchable
                title={t('planning')}
                onSearch={(text) => setUniversalFilter(text)}
                searchValue={universalFilter}
                date={currentDate}
                onLeftArrowClick={() => handleDateChange(getOneDayAgoDate(currentDate).toISOString())}
                onRightArrowClick={() => handleDateChange(getInOneDayDate(currentDate).toISOString())}
            >
                <Icon
                    name="eventRepeat"
                    onClick={() => handleDateChange(new Date().toISOString())}
                    title={capitalizeFirstLetter(t('today'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="calendarMonth"
                    onClick={() => setShowCalendarModal(true)}
                    title={capitalizeFirstLetter(t('calendar'))}
                    color="white"
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <div className="divider" />
                <Icon
                    name="palette"
                    onClick={() => setShowInfosColorsModal(true)}
                    title={capitalizeFirstLetter(t('colors'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="peopleAlt"
                    onClick={() => setShowPeopleModal(true)}
                    title={capitalizeFirstLetter(t('remainingUsers'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="precisionManufacturing"
                    onClick={() => setShowMaterialModal(true)}
                    title={capitalizeFirstLetter(t('remainingMaterial'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="addCircle"
                    onClick={() => {
                        setSelectedSiteId('');
                        setShowActivityModal(true);
                    }}
                    title={capitalizeFirstLetter(t('add'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="resetTv"
                    onClick={() => rollBackData('')}
                    title={capitalizeFirstLetter(t('takeBackReports'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <Icon
                    name="verticalAlignTop"
                    onClick={() => submitData('')}
                    title={capitalizeFirstLetter(t('sendReports'))}
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
            </Header>
            <div className="pad-l-15px pad-r-15px">
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="admin page tabs"
                    classes={{ indicator: 'bg-black-imp' }}
                >
                    <Tab label={t('all')} classes={{ selected: 'text-black-imp' }} />
                    <Tab label={t('trucks')} classes={{ selected: 'text-black-imp' }} />
                </Tabs>

                <div className="loading-bar">{isLoading && <LinearProgress color="inherit" />}</div>

                {selectedTab === 0 && (
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col" className="w-30px pos-sy pad-0-imp left-0 z-idx-4">
                                        {capitalizeFirstLetter(t(''))}
                                    </th>
                                    <th scope="col" className="w-200px  pos-sy left-30px z-idx-4">
                                        {capitalizeFirstLetter(t('site'))}
                                    </th>
                                    <th scope="col" className="w-80px ">
                                        {capitalizeFirstLetter(t('start'))}
                                    </th>
                                    <th scope="col" className="w-200px left-230px z-idx-4">
                                        {capitalizeFirstLetter(t('person'))}
                                    </th>
                                    <th scope="col" className="w-300px ">
                                        {capitalizeFirstLetter(t('machines'))}
                                    </th>
                                    <th scope="col" className="w-300px ">
                                        {capitalizeFirstLetter(t('tools'))}
                                    </th>
                                    <th scope="col" className="w-300px ">
                                        {capitalizeFirstLetter(t('rentals'))}
                                    </th>
                                    <th scope="col" className="w-300px">
                                        {capitalizeFirstLetter(t('8x4'))}
                                    </th>
                                    <th scope="col" className="w-300px">
                                        {capitalizeFirstLetter(t('Semie'))}
                                    </th>
                                    <th scope="col" className="w-300px ">
                                        {capitalizeFirstLetter(t('Ampliroll'))}
                                    </th>
                                  
                                    <th scope="col" className="w-300px ">
                                        {capitalizeFirstLetter(t('observations'))}
                                    </th>
                                    <th scope="col" className="w-200px ">
                                        {capitalizeFirstLetter(t('tempWorkers'))}
                                    </th>
                                    <th scope="col" className="w-300px ">
                                        {capitalizeFirstLetter(t('subContractors'))}
                                    </th>
                                    <th scope="col" className="w-100px">
                                        <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('truckManager'))}</div>
                                    </th>
                                    <th scope="col" className="w-200px ">
                                        {capitalizeFirstLetter(t('activity'))}
                                    </th>
                                    <th scope="col" className="w-75px ">
                                        <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('activityStatus'))}</div>
                                    </th>
                                    <th scope="col" className="w-100px ">
                                        {capitalizeFirstLetter(t('actions'))}
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="fu-planning-obj">
                                {!activities?.length && (
                                    <tr className="row-empty">
                                        <td className="cell-empty" colSpan={19}>
                                            {capitalizeFirstLetter(t('noActivity'))}
                                        </td>
                                    </tr>
                                )}
                                {(activities || [])
                                    .sort((a, b) => {
                                        if (!(!a.siteId?.conducId?._id && !b.siteId?.conducId?._id)) {
                                            if (!a.siteId?.conducId?._id) return -1;
                                            if (!b.siteId?.conducId?._id) return 1;
                                        }
                                        if (a.siteId?.conducId?._id < b.siteId?.conducId?._id) return -1;
                                        if (a.siteId?.conducId?._id > b.siteId?.conducId?._id) return 1;
                                        const siteA = a.siteId.name.toLowerCase();
                                        const siteB = b.siteId.name.toLowerCase();
                                        if (siteA < siteB) return -1;
                                        if (siteA > siteB) return 1;
                                        return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
                                    })
                                    .map((activity, index, array) => (
                                        <tr
                                            key={activity._id}
                                            className="row"
                                            style={{
                                                borderTopWidth: !(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id) ? '1px' : '',
                                            }}
                                        >
                                            <td className="pos-sy left-0 pad-0-imp z-idx-2">
                                                <ColorBar
                                                    backgroundColor={activity.siteId?.conducId?.color || 'black'}
                                                    isPaddingTop={!(index > 0 && array[index - 1].siteId?._id === activity.siteId?._id)}
                                                    isPaddingBottom={
                                                        !(index < array.length - 1 && array[index + 1].siteId?._id === activity.siteId?._id)
                                                    }
                                                    title={
                                                        activity.siteId?.conducId?._id
                                                            ? capitalizeFirstLetter(activity.siteId?.conducId?.firstName) +
                                                              ' ' +
                                                              activity.siteId?.conducId?.lastName.toUpperCase()
                                                            : ''
                                                    }
                                                />
                                            </td>
                                            <td className="pos-sy left-30px z-idx-2">
                                                <div
                                                    style={{ display: 'none' }}
                                                    title={
                                                        activity.siteId.name + activity.siteId.conducId?.firstName + activity.siteId.conducId?.lastName
                                                    }
                                                />
                                                {!(index > 0 && array[index - 1].siteId._id === activity.siteId._id) && (
                                                    <>
                                                        <div className="font-w-600" title={activity.siteId.name}>
                                                            {activity.siteId.name.includes(' - ')
                                                                ? activity.siteId.name.split(' - ').slice(1).join(' - ') || ''
                                                                : activity.siteId.name}
                                                        </div>

                                                        <div className="fx-items-c fx-r">
                                                            <Icon
                                                                name="infoSharp"
                                                                onClick={() => {
                                                                    setSelectedAddress(activity.siteId);
                                                                    setShowLocationModal(true);
                                                                }}
                                                                title={capitalizeFirstLetter(t('siteInformations'))}
                                                            />
                                                            <Icon
                                                                name="addCircle"
                                                                onClick={() => {
                                                                    setSelectedSiteId(activity.siteId._id);
                                                                    setShowActivityModal(true);
                                                                }}
                                                                title={capitalizeFirstLetter(t('add'))}
                                                            />
                                                            <div className="pad-l-5px one-line">
                                                                {capitalizeFirstLetter(activity.siteId?.conducId?.firstName)}{' '}
                                                                {activity.siteId?.conducId?.lastName}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </td>
                                            {/*<td>
                                            <div>{activity.siteId.address}</div>
                                        </td>*/}
                                            <td>
                                                <StartTimeCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    refetch={refetch}
                                                    disabled={!checkEditableRow(activity)}
                                                />
                                            </td>
                                            <td className="pos-sy left-230px z-idx-2">
                                                <PersonCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    users={usersByPermsLevel}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => {
                                                        refetch();
                                                    }}
                                                />
                                            </td>

                                            <td>
                                                <MachinesCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <ToolsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <RentalsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <TrucksPlannedCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                                    refetch={() => refetch()}
                                                    type="8x4"
                                                />
                                            </td>
                                            <td>
                                                <TrucksPlannedCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                                    refetch={() => refetch()}
                                                    type="Semie"
                                                />
                                            </td>
                                            <td>
                                                <TrucksPlannedCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    disabled={!checkEditableRow(activity) || activity.activityTypeId._id !== truckRideActivityTypeId}
                                                    refetch={() => refetch()}
                                                    type="Ampliroll"
                                                />
                                            </td>
                                           
                                            <td>
                                                <ObservationsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={refetch}
                                                />
                                            </td>
                                            <td>
                                                <TempWorkersCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <SubContractorsCell
                                                    activity={activity}
                                                    activityIndex={index}
                                                    activities={array}
                                                    disabled={!checkEditableRow(activity)}
                                                    refetch={() => refetch()}
                                                />
                                            </td>
                                            <td>
                                                <div className="fx-r fx-justify-c">
                                                    {activity.activityTypeId._id !== truckRideActivityTypeId ? (
                                                        <CustomToggle
                                                            checked={activity.isTruckManager}
                                                            onChange={(checked) =>
                                                                handleActivityTruckManager(activity._id, checked, activity.siteId._id)
                                                            }
                                                            disabled={!checkEditableRow(activity)}
                                                            title={capitalizeFirstLetter(activity.isTruckManager ? t('yes') : t('no'))}
                                                        />
                                                    ) : (
                                                        <TruckIcon
                                                            type={
                                                                (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)
                                                                    ?.truckTypeId.designation || '') as
                                                                    | 'Semie'
                                                                    | 'Ampliroll'
                                                                    | '8x4'
                                                                    | 'Porte-char'
                                                                    | ''
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                            <td className="row-cell-activity">
                                                <ActivityCell
                                                    activitiesTypes={activitiesTypes}
                                                    activity={activity}
                                                    refetch={refetch}
                                                    disabled={!checkEditableRow(activity)}
                                                />
                                            </td>
                                            <td>
                                                <div className="fx-r fx-justify-c">
                                                    <StateBadge
                                                        title={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                                                        backgroundColor={getStateColor(activity.activityState)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                {activity.activityState === reportState.PLANNINGSENT &&
                                                    activity.reportId.reportState !== reportState.REPORTFILLED && (
                                                        <Icon
                                                            name="resetTv"
                                                            onClick={() => rollBackData(activity._id)}
                                                            title={capitalizeFirstLetter(t('takeBackActivity'))}
                                                        />
                                                    )}
                                                {activity.activityState === reportState.PLANNING && (
                                                    <Icon
                                                        name="verticalAlignTop"
                                                        onClick={() => submitData(activity._id)}
                                                        title={capitalizeFirstLetter(t('sendActivity'))}
                                                    />
                                                )}
                                                {activity.activityState === reportState.PLANNING && (
                                                    <Icon
                                                        name="deleteForever"
                                                        onClick={() => handleDeleteActivity(activity._id)}
                                                        title={capitalizeFirstLetter(t('deleteActivity'))}
                                                        color="crimson"
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                <AmplirollTableRows
                                    activities={activities || []}
                                    activitiesTypes={activitiesTypes}
                                    currentDate={currentDate}
                                    refetch={refetch}
                                    selectedTab={selectedTab}
                                    sites={sites}
                                    trucks={trucks}
                                    universalFilter={universalFilter}
                                    usersByPermsLevel={usersByPermsLevel}
                                />
                            </tbody>
                        </table>
                    </div>
                )}
                {selectedTab === 1 && (
                    <PlanningTrucksPage
                        activities={activities || []}
                        activitiesTypes={activitiesTypes}
                        currentDate={currentDate}
                        refetch={refetch}
                        sites={sites}
                        trucks={trucks}
                        users={usersByPermsLevel}
                    />
                )}
                {showActivityModal && (
                    <CreateActivityModal
                        activitiesTypes={activitiesTypes}
                        open={showActivityModal}
                        handleClose={() => setShowActivityModal(false)}
                        sites={sites.map((type) => {
                            return { label: type.name, value: type._id };
                        })}
                        users={usersByPermsLevel}
                        initDate={setSpecificHour()}
                        refetch={() => refetch()}
                        initSiteId={selectedSiteId}
                    />
                )}
                {showLocationModal && (
                    <ModalBody
                        open={showLocationModal}
                        handleClose={() => setShowLocationModal(false)}
                        okText={t('ok')}
                        title={selectedAddress?.name}
                    >
                        <div>{selectedAddress?.address}</div>
                    </ModalBody>
                )}
                {showPeopleModal && (
                    <RemainingPeopleModal
                        activitiesTypes={activitiesTypes}
                        open={showPeopleModal}
                        handleClose={() => setShowPeopleModal(false)}
                        activities={activities || []}
                        sites={sites.map((type) => {
                            return { label: type.name, value: type._id };
                        })}
                        users={usersByPermsLevel}
                        initDate={setSpecificHour()}
                        refetch={() => refetch()}
                    />
                )}
                {showMaterialModal && (
                    <RemainingMaterialModal
                        open={showMaterialModal}
                        handleClose={() => setShowMaterialModal(false)}
                        activities={activities || []}
                        initDate={setSpecificHour()}
                        refetch={() => refetch()}
                    />
                )}
                {showInfosColorsModal && <InfosColorsModal open={showInfosColorsModal} handleClose={() => setShowInfosColorsModal(false)} />}
                {showCalendarModal && (
                    <CalendarModal
                        currentDate={currentDate}
                        open={showCalendarModal}
                        handleClose={() => setShowCalendarModal(false)}
                        setCurrentDate={handleDateChange}
                    />
                )}
            </div>
        </div>
    );
};

export default PlanningPage;
