import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Truck } from '../../services/apiSLTP/materials/trucks';
import { crudStatus } from '../../utils/enums';
import { useCreateTruckMutation, useUpdateTruckMutation } from '../../hooks/mutations/useTruckMutation';
import { useAuth } from '../../hooks/useAuth';
import { TruckType } from '../../services/apiSLTP/materials/trucksTypes';
import { TruckCompany } from '../../services/apiSLTP/materials/trucksCompanies';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    truck: Truck;
    trucks: Truck[];
    setTrucks: React.Dispatch<React.SetStateAction<Truck[]>>;
    trucksTypes: TruckType[];
    trucksCompanies: TruckCompany[];
    showEditModal: boolean;
    isEditMode?: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateTruckModal: React.FC<ModalProps> = ({
    truck,
    setTrucks,
    showEditModal,
    setShowEditModal,
    trucksTypes,
    trucks,
    isEditMode = true,
    trucksCompanies,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpTruck, setTmpTruck] = useState(truck ?? ({} as Truck));
    const [selectedTruckType, setSelectedTruckType] = useState({
        label: '',
        value: '',
    });
    const [selectedTruckCompany, setSelectedTruckCompany] = useState({
        label: '',
        value: '',
    });

    const { user } = useAuth();

    const {
        mutate: mutateTruck,
        isSuccess: isSuccessTruck,
        isError: isErrorTruck,
        isLoading: isLoadingTruck,
        error: errorTruck,
        data: dataTruck,
    } = useUpdateTruckMutation({
        onSuccess: (data, variables) => {
            {
                /*setTrucks((prevTrucks) => {
                const newTrucks = [...prevTrucks];
                const index = newTrucks.findIndex((value) => value._id === truck._id);
                newTrucks[index] = data.data; // change here
                return newTrucks;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truck:', error.message);
        },
    });

    const {
        mutate: addTruck,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateTruckMutation({
        onSuccess: (data, variables) => {
            {
                /*setTrucks((prevTrucks) => {
                const newTrucks = [...prevTrucks];
                newTrucks.push(data.data); // change here
                return newTrucks;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truck:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpTruck((prevTruck) => {
            const newTruck = { ...prevTruck };
            newTruck.designation = text;
            return newTruck;
        });
    };

    const handleCodeProCChange = (text: string) => {
        setTmpTruck((prevTruck) => {
            const newTruck = { ...prevTruck };
            newTruck.codeProC = text;
            return newTruck;
        });
    };

    const handlePlateNumberChange = (text: string) => {
        setTmpTruck((prevTruck) => {
            const newTruck = { ...prevTruck };
            newTruck.plateNumber = text;
            return newTruck;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const verifyCodeProC = () => {
        if (
            trucks
                .filter((value) => value._id !== tmpTruck._id)
                .map((value) => value.codeProC)
                .includes(tmpTruck.codeProC)
        )
            return false;
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpTruck((prevTruck) => {
                const newTruck = { ...prevTruck };
                newTruck.crudStatus = crudStatus.OK;
                return newTruck;
            });
        } else {
            setTmpTruck((prevTruck) => {
                const newTruck = { ...prevTruck };
                newTruck.crudStatus = crudStatus.INACTIVE;
                return newTruck;
            });
        }
    };

    useEffect(() => {
        const truckType = trucksTypes.find((value) => value?._id === tmpTruck.truckTypeId?._id);
        setSelectedTruckType({ label: `${truckType?.designation}`, value: `${truckType?._id}` });
        const truckCompany = trucksCompanies.find((value) => value?._id === tmpTruck.truckCompanyId?._id);
        setSelectedTruckCompany({ label: `${truckCompany?.designation}`, value: `${truckCompany?._id}` });
    }, [tmpTruck.truckTypeId, trucksTypes, trucksCompanies]);

    return (
        <ModalBody
            title={isEditMode ? t('updateTruck') : t('createTruck')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation() || !verifyCodeProC()}
            handleOk={() => {
                isEditMode
                    ? mutateTruck({
                          truckId: truck._id,
                          data: {
                              designation: tmpTruck.designation,
                              codeProC: tmpTruck.codeProC,
                              crudStatus: tmpTruck.crudStatus,
                              truckTypeId: selectedTruckType.value,
                          },
                      })
                    : addTruck({
                          codeProC: tmpTruck.codeProC,
                          designation: tmpTruck.designation,
                          truckTypeId: selectedTruckType.value,
                      });
            }}
        >
            <CustomTextInput value={tmpTruck.designation} onChange={handleDesignationChange} label={t('name')} errorText={t('error')} />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpTruck.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
            <CustomTextInput value={tmpTruck.codeProC} onChange={handleCodeProCChange} label={t('codeProC')} errorText={t('error')} />
            <CustomTextInput value={tmpTruck.plateNumber} onChange={handlePlateNumberChange} label={t('plateNumber')} errorText={t('error')} />
            <DropDown
                items={trucksTypes.map((truckType) => ({ label: truckType.designation, value: truckType._id }))}
                selectedItem={selectedTruckType}
                handleChange={(event, value) => setSelectedTruckType({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('type')}
            />
            <DropDown
                items={trucksCompanies.map((truckCompany) => ({ label: truckCompany.designation, value: truckCompany._id }))}
                selectedItem={selectedTruckCompany}
                handleChange={(event, value) => setSelectedTruckCompany({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('company')}
            />
        </ModalBody>
    );
};

export default UpdateTruckModal;
