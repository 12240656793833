import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dump } from '../../services/apiSLTP/materials/dumps';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateDumpMutation } from '../../hooks/mutations/useDumpMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateDumpModal from '../modals/UpdateDumpModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';
import { useDumpsQuery } from '../../hooks/queries/useDumpsQuery';

interface DumpsSettingsRowProps {
    setAddFtn: (ftn: () => void) => void;
}

const DumpsSettingsRow: React.FC<DumpsSettingsRowProps> = ({ setAddFtn }) => {
    const { data: dumps, isLoading: isDumpsLoading, isError: isDumpsError, isSuccess: isDumpsSuccess, refetch: refetchDumps } = useDumpsQuery({});
    const emptyDump: Dump = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
        codeProC: '',
        order: 1,
        volume: 0,
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedDump, setSelectedDump] = useState<Dump>(emptyDump);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const {
        mutate: mutateDump,
        isSuccess: isSuccessDump,
        isError: isErrorDump,
        isLoading: isLoadingDump,
        error: errorDump,
        data: dataDump,
    } = useUpdateDumpMutation({
        onSuccess: (data, variables) => {
            refetchDumps();
        },
        onError: (error) => {
            console.error('Error updating dump:', error.message);
        },
    });

    const sortedDumps = () => {
        return (
            dumps?.sort((a, b) => {
                const typeA = a?.designation;
                const typeB = b?.designation;
                if (typeA < typeB) return -1;
                if (typeA > typeB) return 1;
                const designationA = a.designation;
                const designationB = b.designation;
                if (designationA < designationB) return -1;
                if (designationA > designationB) return 1;
                return 0;
            }) || []
        );
    };

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedDump(emptyDump);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-100px pos-sy left-0 4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('codeProC'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('volume') + ` (${t('m³')})`)}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('order'))}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedDumps().map((dump, index, array) => (
                            <tr key={dump._id}>
                                <td scope="row">{dump.designation}</td>
                                <td scope="row">{dump.codeProC}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedDump(dump);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={dump.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedDump(dump);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={dump.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <div className="fx-r fx-justify-c">
                                        <StateBadge backgroundColor={getStatusColor(dump.crudStatus)} title={capitalizeFirstLetter(t(getCrudStatusInfos(dump.crudStatus)))} />
                                    </div>
                                </td>
                                <td scope="row">{dump.volume}</td>
                                <td scope="row">{dump.order}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateDumpModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    dump={selectedDump}
                    isEditMode={isEditMode}
                    refetch={refetchDumps}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateDump({ dumpId: selectedDump._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedDump?.designation}
                />
            )}
        </div>
    );
};

export default DumpsSettingsRow;
