import React from 'react';

interface ColorBarProps {
    backgroundColor: string;
    isPaddingTop: boolean;
    isPaddingBottom: boolean;
    title: string;
}

const ColorBar: React.FC<ColorBarProps> = ({ backgroundColor, isPaddingTop, isPaddingBottom, title }) => {
    const marginTop = isPaddingTop ? '5px' : '0';
    const marginBottom = isPaddingBottom ? '5px' : '0';

    const height = `calc(100% - ${parseInt(marginTop) + parseInt(marginBottom)}px)`;
    return (
        <div
            style={{
                width: 20,
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop,
                marginBottom,
                boxSizing: 'border-box',
                marginLeft: 3,
                marginRight: 3,
            }}
        >
            <div
                style={{
                    backgroundColor,
                    width: 20,
                    height,
                    borderBottomLeftRadius: isPaddingBottom ? 5 : 0,
                    borderBottomRightRadius: isPaddingBottom ? 5 : 0,
                    borderTopLeftRadius: isPaddingTop ? 5 : 0,
                    borderTopRightRadius: isPaddingTop ? 5 : 0,
                }}
                title={title}
            />
        </div>
    );
};

export default ColorBar;
