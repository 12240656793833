import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Dump } from '../../services/apiSLTP/materials/dumps';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateDumpMutation, useUpdateDumpMutation } from '../../hooks/mutations/useDumpMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    dump: Dump;
    isEditMode?: boolean;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateDumpModal: React.FC<ModalProps> = ({ dump, isEditMode = true, showEditModal, setShowEditModal, refetch }) => {
    const { t } = useTranslation();
    const [tmpDump, setTmpDump] = useState(dump);

    const { user } = useAuth();

    const {
        mutate: mutateDump,
        isSuccess: isSuccessDump,
        isError: isErrorDump,
        isLoading: isLoadingDump,
        error: errorDump,
        data: dataDump,
    } = useUpdateDumpMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating dump:', error.message);
        },
    });

    const {
        mutate: addDump,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateDumpMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating dump:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpDump((prevDump) => {
            const newDump = { ...prevDump };
            newDump.designation = text;
            return newDump;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpDump((prevDump) => {
                const newDump = { ...prevDump };
                newDump.crudStatus = crudStatus.OK;
                return newDump;
            });
        } else {
            setTmpDump((prevDump) => {
                const newDump = { ...prevDump };
                newDump.crudStatus = crudStatus.INACTIVE;
                return newDump;
            });
        }
    };

    return (
        <ModalBody
            title={isEditMode ? t('updateDump') : t('createDump')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation()}
            handleOk={() => {
                isEditMode
                    ? mutateDump({
                          dumpId: dump._id,
                          data: {
                              designation: tmpDump.designation,
                              crudStatus: tmpDump.crudStatus,
                              codeProC: tmpDump.codeProC,
                              volume: tmpDump.volume,
                              order: tmpDump.order,
                          },
                      })
                    : addDump({ designation: tmpDump.designation, codeProC: tmpDump.codeProC, volume: tmpDump.volume, order: tmpDump.order });
            }}
        >
            <CustomTextInput
                value={tmpDump.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
            />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpDump.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
        </ModalBody>
    );
};

export default UpdateDumpModal;
