import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import DropDown from '../DropDown';
import { getToolTypes, getToolsByToolTypeId, createDailyTool } from '../../services/apiSLTP/planning';
import { DataItem, updateDailyTool } from '../../services/apiSLTP/activities';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { getMaterialStateInfos, materialState } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import { getActiveTools } from '../../services/apiSLTP/materials/tools';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activity: DataItem;
    activityIndex: number;
    refetch: () => void;
    toolId: string;
}

const CreateDailyToolModal: React.FC<ModalProps> = ({ open, handleClose, activity, activityIndex, refetch, toolId }) => {
    const { t } = useTranslation();
    const [currentToolId, setCurrentToolId] = useState(toolId);
    const [tools, setTools] = useState<{ label: string; value: string; group: string }[]>([]);
    const [selectedTool, setSelectedTool] = useState({ label: '', value: '' });
    const [selectedState, setSelectedState] = useState<{
        label: string;
        value: string;
    }>({ label: capitalizeFirstLetter(t('present')), value: materialState.PRESENT.toString() });

    const states = [
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.NEW))), value: materialState.NEW.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.PRESENT))), value: materialState.PRESENT.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.REMOVE))), value: materialState.REMOVE.toString() },
    ];

    const fetchTools = async () => {
        const response = await getActiveTools();
        setTools(
            response.data.map((value) => {
                return { label: value.designation, value: value._id, group: value.toolTypeId.designation };
            })
        );
    };

    const postDailyTool = async () => {
        const response = await createDailyTool(activity._id, selectedTool.value, parseInt(selectedState.value), true);
        setCurrentToolId(response.data._id);
        refetch();
    };

    const patchDailyTool = async () => {
        const response = await updateDailyTool(currentToolId, {
            toolId: selectedTool.value,
            state: parseInt(selectedState.value),
            createTransfer: true
        });
        refetch();
    };

    useEffect(() => {
        fetchTools();

        const dailyTool = activity.dailyTools.find((value) => value._id === toolId);
        if (dailyTool) {
            setSelectedTool({ label: dailyTool.toolId?.designation || '', value: dailyTool.toolId?._id || '' });
            setSelectedState({ label: capitalizeFirstLetter(t(getMaterialStateInfos(dailyTool.state))) || '', value: dailyTool.state.toString() || '' });
        }
    }, []);

    return (
        <ModalBody
            closeText={t('cancel')}
            title={!!currentToolId.length ? t('modifyTool') : t('addTool')}
            okText={!!currentToolId.length ? t('update') : t('create')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !currentToolId.length ? postDailyTool() : patchDailyTool();
            }}
        >
            <div className='fx-c'>
                <DropDown
                    selectedItem={selectedTool}
                    items={tools}
                    handleChange={(event, value) => setSelectedTool({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('tool')}
                    grouped
                />
                {!!selectedTool.value.length && (
                    <DropDown
                        selectedItem={selectedState}
                        items={states}
                        handleChange={(event, value) => setSelectedState({ value: value?.value || '', label: value?.label || '' })}
                        inputLabel={t('state')}
                    />
                )}
            </div>
        </ModalBody>
    );
};

export default CreateDailyToolModal;
