import React from 'react';

interface StateBadgeProps {
    title: string;
    backgroundColor: string;
}

const StateBadge: React.FC<StateBadgeProps> = ({ title, backgroundColor }) => {
    return (
        <div
            title={title}
            style={{
                background: backgroundColor,
                borderRadius: '5px',
                width: '20px',
                height: '25px',
            }}
        />
    );
};

export default StateBadge;
