import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubContractorsQuery } from '../../hooks/queries/useSubContractorsQuery';
import { SubContractor } from '../../services/apiSLTP/materials/subContractors';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateSubContractorMutation } from '../../hooks/mutations/useSubContractorMutation';
import DeleteModal from '../modals/DeleteModal';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import UpdateSubContractorModal from '../modals/UpdateSubContractorModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';

interface SubContractorsSettingsRowProps {
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const SubContractorsSettingsRow: React.FC<SubContractorsSettingsRowProps> = ({ universalFilter, setAddFtn }) => {
    const emptySubContractor: SubContractor = {
        _id: '',
        codeProC: '',
        crudStatus: crudStatus.OK,
        designation: '',
    };
    const [subContractors, setSubContractors] = useState<SubContractor[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedSubContractor, setSelectedSubContractor] = useState<SubContractor>(emptySubContractor);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const { user } = useAuth();

    const { data, isLoading, isError, isSuccess, refetch } = useSubContractorsQuery({});
    const {
        mutate: mutateSubContractor,
        isSuccess: isSuccessSubContractor,
        isError: isErrorSubContractor,
        isLoading: isLoadingSubContractor,
        error: errorSubContractor,
        data: dataSubContractor,
    } = useUpdateSubContractorMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating subContractor:', error.message);
        },
    });

    const filterSubContractors = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (subContractor: SubContractor) => {
            return searchTerm.length === 0 || matchesSearchTerm(subContractor?.designation);
        };
        return subContractors.filter(matchesFilter);
    };

    const sortedSubContractors = () => {
        return subContractors.sort((a, b) => {
            const typeA = a?.designation;
            const typeB = b?.designation;
            if (typeA < typeB) return -1;
            if (typeA > typeB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        if (data && isSuccess) {
            setSubContractors(data);
        }
    }, [data]);

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedSubContractor(emptySubContractor);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('codeProC'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedSubContractors().map((subContractor, index, array) => (
                            <tr key={subContractor._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {subContractor.designation}
                                </td>
                                <td>{subContractor.codeProC}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedSubContractor(subContractor);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={subContractor.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && (
                                        <Icon
                                            name="delete"
                                            onClick={() => {
                                                setSelectedSubContractor(subContractor);
                                                setShowDeleteModal(true);
                                            }}
                                            disabled={subContractor.crudStatus === crudStatus.DELETED}
                                        />
                                    )}
                                </td>
                                <td>
                                    <div className="fx-r fx-justify-c">
                                        <StateBadge backgroundColor={getStatusColor(subContractor.crudStatus)} title={capitalizeFirstLetter(t(getCrudStatusInfos(subContractor.crudStatus)))} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateSubContractorModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    isEditMode={isEditMode}
                    subContractor={selectedSubContractor}
                    subContractors={subContractors}
                    setSubContractors={setSubContractors}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateSubContractor({ subContractorId: selectedSubContractor._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedSubContractor.designation}
                />
            )}
        </div>
    );
};

export default SubContractorsSettingsRow;
