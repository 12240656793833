import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import DropDown from '../DropDown';
import { getRentalTypes, createDailyRental } from '../../services/apiSLTP/planning';
import { DataItem, updateDailyRental } from '../../services/apiSLTP/activities';
import TextInput from '../TextInput';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { getMaterialStateInfos, materialState } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import CustomTextInput from '../CustomTextInput';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activity: DataItem;
    activityIndex: number;
    refetch: () => void;
    rentalId: string;
}

const CreateDailyRentalModal: React.FC<ModalProps> = ({ open, handleClose, activity, activityIndex, refetch, rentalId }) => {
    const { t } = useTranslation();
    const [currentRentalId, setCurrentRentalId] = useState(rentalId);
    const [rentalsTypes, setRentalsTypes] = useState<{ label: string; value: string }[]>([]);
    const [selectedRentalType, setSelectedRentalType] = useState({ label: '', value: '' });
    const [rentalDesignation, setRentalDesignation] = useState('');
    const [selectedState, setSelectedState] = useState<{
        label: string;
        value: string;
    }>({ label: capitalizeFirstLetter(t('present')), value: materialState.PRESENT.toString() });

    const states = [
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.NEW))), value: materialState.NEW.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.PRESENT))), value: materialState.PRESENT.toString() },
        { label: capitalizeFirstLetter(t(getMaterialStateInfos(materialState.REMOVE))), value: materialState.REMOVE.toString() },
    ];

    const fetchRentalsTypes = async () => {
        const response = await getRentalTypes();
        setRentalsTypes(
            response.data.map((value) => {
                return { label: value.designation, value: value._id };
            })
        );
    };

    const postDailyRental = async () => {
        const response = await createDailyRental(
            activity._id,
            selectedRentalType.value,
            capitalizeFirstLetter(rentalDesignation.trim()),
            parseInt(selectedState.value),
            true
        );
        setCurrentRentalId(response.data._id);
        refetch();
    };

    const patchDailyRental = async () => {
        const response = await updateDailyRental(currentRentalId, {
            rentalTypeId: selectedRentalType.value,
            state: parseInt(selectedState.value),
            designation: rentalDesignation,
            createTransfer: true,
        });
        refetch();
    };

    useEffect(() => {
        fetchRentalsTypes();

        const dailyRental = activity.dailyRentals.find((value) => value._id === rentalId);
        if (dailyRental) {
            setSelectedRentalType({ label: dailyRental.rentalTypeId?.designation || '', value: dailyRental.rentalTypeId?._id || '' });
            setRentalDesignation(dailyRental.designation);
            setSelectedState({
                label: capitalizeFirstLetter(t(getMaterialStateInfos(dailyRental.state))) || '',
                value: dailyRental.state.toString() || '',
            });
        }
    }, []);

    return (
        <ModalBody
            closeText={t('cancel')}
            title={!!currentRentalId.length ? t('modifyRental') : t('addRental')}
            okText={!!currentRentalId.length ? t('update') : t('create')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !currentRentalId.length ? postDailyRental() : patchDailyRental();
            }}
        >
            <div className="fx-c">
                <DropDown
                    selectedItem={selectedRentalType}
                    items={rentalsTypes}
                    handleChange={(event, value) => setSelectedRentalType({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('company')}
                />
                {!!selectedRentalType.value.length && (
                    <CustomTextInput value={rentalDesignation} onChange={(text) => setRentalDesignation(text)} label={t('name')} />
                )}
                {!!rentalDesignation.length && (
                    <DropDown
                        selectedItem={selectedState}
                        items={states}
                        handleChange={(event, value) => setSelectedState({ value: value?.value || '', label: value?.label || '' })}
                        inputLabel={t('state')}
                    />
                )}
            </div>
        </ModalBody>
    );
};

export default CreateDailyRentalModal;
