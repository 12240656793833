import { useQuery, QueryFunction } from 'react-query';
import { getActivitiesByDateAndState, DataItem } from '../../services/apiSLTP/activities';

type UseActivitiesByDateAndStateQueryOptions = {
    date: string;
    minState: number;
    maxState: number;
    stepMode: number;
};

const getActivities: QueryFunction<DataItem[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseActivitiesByDateAndStateQueryOptions];
    const { date, minState, maxState, stepMode } = options;

    const response = await getActivitiesByDateAndState(date, minState, maxState, stepMode);
    return response.data;
};

export const useActivitiesByDateQuery = (options: UseActivitiesByDateAndStateQueryOptions) => {
    return useQuery(['activitiesByDate', options], getActivities);
};
