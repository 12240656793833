import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomButton from '../CustomButton';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { TruckType } from '../../services/apiSLTP/materials/trucksTypes';
import { crudStatus } from '../../utils/enums';
import { getTextColor } from '../../utils/colors';
import ColorModal from './ColorModal';
import { useCreateTruckTypeMutation, useUpdateTruckTypeMutation } from '../../hooks/mutations/useTruckTypeMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    truckType: TruckType;
    trucksTypes: TruckType[];
    isEditMode?: boolean;
    setTrucksTypes: React.Dispatch<React.SetStateAction<TruckType[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateTruckTypeModal: React.FC<ModalProps> = ({
    truckType,
    isEditMode = true,
    setTrucksTypes,
    showEditModal,
    setShowEditModal,
    trucksTypes,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpTruckType, setTmpTruckType] = useState(truckType);

    const { user } = useAuth();

    const {
        mutate: mutateTruckType,
        isSuccess: isSuccessTruckType,
        isError: isErrorTruckType,
        isLoading: isLoadingTruckType,
        error: errorTruckType,
        data: dataTruckType,
    } = useUpdateTruckTypeMutation({
        onSuccess: (data, variables) => {
            {
                /*setTrucksTypes((prevTrucksTypes) => {
                const newTrucksTypes = [...prevTrucksTypes];
                const index = newTrucksTypes.findIndex((value) => value._id === truckType._id);
                newTrucksTypes[index] = data.data; // change here
                return newTrucksTypes;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truckType:', error.message);
        },
    });

    const {
        mutate: addTruckType,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateTruckTypeMutation({
        onSuccess: (data, variables) => {
            {
                /*setTrucksTypes((prevTrucksTypes) => {
                const newTrucksTypes = [...prevTrucksTypes];
                const index = newTrucksTypes.findIndex((value) => value._id === truckType._id);
                newTrucksTypes.push(data.data); // change here
                return newTrucksTypes;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truckType:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpTruckType((prevTruckType) => {
            const newTruckType = { ...prevTruckType };
            newTruckType.designation = text;
            return newTruckType;
        });
    };

    const verifyDesignation = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpTruckType((prevTruckType) => {
                const newTruckType = { ...prevTruckType };
                newTruckType.crudStatus = crudStatus.OK;
                return newTruckType;
            });
        } else {
            setTmpTruckType((prevTruckType) => {
                const newTruckType = { ...prevTruckType };
                newTruckType.crudStatus = crudStatus.INACTIVE;
                return newTruckType;
            });
        }
    };

    return (
        <ModalBody
            title={isEditMode ? t('updateTruckType') : t('createTruckType')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyDesignation()}
            handleOk={() => {
                isEditMode
                    ? mutateTruckType({
                          truckTypeId: truckType._id,
                          data: {
                              designation: tmpTruckType.designation,
                              crudStatus: tmpTruckType.crudStatus,
                          },
                      })
                    : addTruckType({ designation: tmpTruckType.designation });
            }}
        >
            <CustomTextInput
                value={tmpTruckType.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
            />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpTruckType.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
        </ModalBody>
    );
};

export default UpdateTruckTypeModal;
