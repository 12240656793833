import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface LoadNature {
    crudStatus: number;
    _id: string;
    designation: string;
}

interface LoadNaturesResponse {
    message: string;
    data: LoadNature[];
}

export const getAllLoadNatures = async (): Promise<LoadNaturesResponse> => {
    try {
        const response: AxiosResponse<LoadNaturesResponse> = await apiClient.get('/load-natures');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateLoadNatureBody {
    designation?: string;
    crudStatus?: number;
}

export interface LoadNatureResponse {
    message: string;
    data: LoadNature;
}

export const updateLoadNature = async (loadNatureId: string, data: UpdateLoadNatureBody): Promise<LoadNatureResponse> => {
    try {
        const response: AxiosResponse<LoadNatureResponse> = await apiClient.patch('/load-natures/' + loadNatureId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateLoadNatureBody {
    designation: string;
}

export const createLoadNature = async (data: CreateLoadNatureBody): Promise<LoadNatureResponse> => {
    try {
        const response: AxiosResponse<LoadNatureResponse> = await apiClient.post('/load-natures', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
