import React, { useEffect, useState } from 'react';
import DropDown from '../DropDown';
import { createDailyTruckPlanned, deleteDailyTruckPlanned, updateDailyTruckPlanned } from '../../services/apiSLTP/dailyReports/dailyTrucksPlanned';
import { DataItem } from '../../services/apiSLTP/activities';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { getTruckModeInfos, materialState, truckMode } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';
import RangeInput from '../RangeInput';
import { useTrucksCompaniesQuery } from '../../hooks/queries/useTrucksCompaniesQuery';
import { useTrucksTypesQuery } from '../../hooks/queries/useTrucksTypesQuery';
import { useLoadNaturesQuery } from '../../hooks/queries/useLoadNaturesQuery';
import { useMachinesQuery } from '../../hooks/queries/useMachinesQuery';
import { useActiveQuarriesQuery, useQuarriesQuery } from '../../hooks/queries/useQuarriesQuery';
import { useToolsQuery } from '../../hooks/queries/useToolsQuery';
import { useActiveSitesQuery } from '../../hooks/queries/useSitesQuery';
import { useTrucksQuery } from '../../hooks/queries/useTrucksQuery';
import { useCreateDailyTruckPlannedMutation, useUpdateDailyTruckPlannedMutation } from '../../hooks/mutations/useDailyTrucksPlannedMutation';
import Icon from '../Icon';
import ManageDailyTransferModal from './ManageDailyTransferModal';
import { getMaterialStateColor } from '../../utils/colors';
import { DailyTransfer, deleteDailyTransfer } from '../../services/apiSLTP/dailyReports/dailyTransfers';
import { useDumpsQuery } from '../../hooks/queries/useDumpsQuery';
import CustomTextInput from '../CustomTextInput';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activity: DataItem;
    refetch: () => void;
    truckPlannedId: string;
    truckType: '8x4' | 'Semie' | 'Ampliroll' | '';
}

const ManageDailyTruckPlannedModal: React.FC<ModalProps> = ({ open, handleClose, activity, refetch, truckPlannedId, truckType }) => {
    const amplirollType = '6645c0f464b9490789ccb8d7';
    const portecharType = '6645c0f464b9490789ccb8d8';
    const x84Type = '6645c05564b9490789ccb8d3';
    const semieType = '6645c0d064b9490789ccb8d5';
    const { t } = useTranslation();
    const [currentTruckPlannedId, setCurrentTruckPlannedId] = useState(truckPlannedId);
    const [showModal, setShowModal] = useState(false);

    const [selectedTrucksAmount, setSelectedTrucksAmount] = useState(1);
    const [selectedRidesAmount, setSelectedRidesAmount] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState({ label: 'SLTP', value: '6688062ffd2728b140ab7f1b' });
    const [selectedType, setSelectedType] = useState({ label: '', value: '' });
    const [selectedTruck, setSelectedTruck] = useState({ label: '', value: '' });
    const [selectedMode, setSelectedMode] = useState({ label: '', value: '' });

    const [selectedDepartureLoadNature, setSelectedDepartureLoadNature] = useState({ label: '', value: '' });
    const [selectedArrivalLoadNature, setSelectedArrivalLoadNature] = useState({ label: '', value: '' });
    const [selectedDepartureDump, setSelectedDepartureDump] = useState({ label: '', value: '' });
    const [selectedArrivalDump, setSelectedArrivalDump] = useState({ label: '', value: '' });
    const [selectedOrigin, setSelectedOrigin] = useState({ label: '', value: '', group: '' });
    const [selectedDestination, setSelectedDestination] = useState({ label: '', value: '', group: '' });
    const [observations, setObservations] = useState('');
    const [selectedTransfer, setSelectedTransfer] = useState<DailyTransfer | null>(null);

    const [selectedTruckPlannedType, setSelectedTruckPlannedType] = useState({ label: '', value: '' });
    const [selectedState, setSelectedState] = useState<{
        label: string;
        value: string;
    }>({ label: capitalizeFirstLetter(t('present')), value: materialState.PRESENT.toString() });

    const states = [
        { label: capitalizeFirstLetter(t('new')), value: materialState.NEW.toString() },
        { label: capitalizeFirstLetter(t('present')), value: materialState.PRESENT.toString() },
        { label: capitalizeFirstLetter(t('remove')), value: materialState.REMOVE.toString() },
    ];

    const {
        data: dataCompanies,
        isLoading: isLoadingCompanies,
        isError: isErrorCompanies,
        isSuccess: isSuccessCompanies,
        refetch: refetchCompanies,
    } = useTrucksCompaniesQuery({});

    const {
        data: dataTrucksTypes,
        isLoading: isLoadingTrucksTypes,
        isError: isErrorTrucksTypes,
        isSuccess: isSuccessTrucksTypes,
        refetch: refetchTrucksTypes,
    } = useTrucksTypesQuery({});

    const {
        data: dataTrucks,
        isLoading: isLoadingTrucks,
        isError: isErrorTrucks,
        isSuccess: isSuccessTrucks,
        refetch: refetchTrucks,
    } = useTrucksQuery({});

    const {
        data: dataSites,
        isLoading: isLoadingSites,
        isError: isErrorSites,
        isSuccess: isSuccessSites,
        refetch: refetchSites,
    } = useActiveSitesQuery({});

    const {
        data: dataLoadNatures,
        isLoading: isLoadingLoadNatures,
        isError: isErrorLoadNatures,
        isSuccess: isSuccessLoadNatures,
        refetch: refetchLoadNatures,
    } = useLoadNaturesQuery({});

    const {
        data: dataQuarries,
        isLoading: isLoadingQuarries,
        isError: isErrorQuarries,
        isSuccess: isSuccessQuarries,
        refetch: refetchQuarries,
    } = useActiveQuarriesQuery({});

    const { data: dataDumps, isLoading: isLoadingDumps, isError: isErrorDumps, isSuccess: isSuccessDumps, refetch: refetchDumps } = useDumpsQuery({});

    const {
        mutate: createTruckPlanned,
        isSuccess: isCreateTruckPlannedSuccess,
        isError: isCreateTruckPlannedError,
        isLoading: isCreateTruckPlannedLoading,
        error: createTruckPlannedError,
    } = useCreateDailyTruckPlannedMutation({
        onSuccess: (data, variables) => {
            setCurrentTruckPlannedId(data.data._id);
            refetch();
        },
        onError: (error) => {
            console.error('Error creating truck planned:', error.message);
        },
    });

    const {
        mutate: updateTruckPlanned,
        isSuccess: isUpdateTruckPlannedSuccess,
        isError: isUpdateTruckPlannedError,
        isLoading: isUpdateTruckPlannedLoading,
        error: updateTruckPlannedError,
    } = useUpdateDailyTruckPlannedMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating truck planned:', error.message);
        },
    });

    const postDailyTruckPlanned = async () => {
        createTruckPlanned({
            activityId: activity._id,
            trucksAmount: selectedTrucksAmount,
            ridesAmount: selectedRidesAmount,
            truckTypeId: selectedType.value,
            departureLoadNatureId: parseInt(selectedMode.value) === truckMode.TRANSFER ? '' : selectedDepartureLoadNature.value,
            arrivalLoadNatureId: parseInt(selectedMode.value) === truckMode.TRANSFER ? '' : selectedArrivalLoadNature.value,
            departureQuarryId:
                parseInt(selectedMode.value) === truckMode.TRANSFER
                    ? ''
                    : selectedOrigin.group === capitalizeFirstLetter(t('quarries'))
                      ? selectedOrigin.value
                      : '',
            arrivalQuarryId:
                parseInt(selectedMode.value) === truckMode.TRANSFER
                    ? ''
                    : selectedDestination.group === capitalizeFirstLetter(t('quarries'))
                      ? selectedDestination.value
                      : '',
            mode: parseInt(selectedMode.value),
            departureSiteId:
                parseInt(selectedMode.value) === truckMode.TRANSFER
                    ? ''
                    : selectedOrigin.group === capitalizeFirstLetter(t('sites'))
                      ? selectedOrigin.value
                      : '',
            arrivalSiteId:
                parseInt(selectedMode.value) === truckMode.TRANSFER
                    ? ''
                    : selectedDestination.group === capitalizeFirstLetter(t('sites'))
                      ? selectedDestination.value
                      : '',
            truckCompanyId: selectedCompany.value,
            truckId: selectedTruck.value,
            departureDumpId: parseInt(selectedMode.value) === truckMode.TRANSFER ? '' : selectedDepartureDump.value,
            arrivalDumpId: parseInt(selectedMode.value) === truckMode.TRANSFER ? '' : selectedArrivalDump.value,
            observations: observations
        });
    };

    const patchDailyTruckPlanned = async () => {
        updateTruckPlanned({
            DailyTruckPlannedId: currentTruckPlannedId,
            data: {
                trucksAmount: selectedTrucksAmount,
                ridesAmount: selectedRidesAmount,
                truckTypeId: selectedType.value,
                departureLoadNatureId: parseInt(selectedMode.value) === truckMode.TRANSFER ? '' : selectedDepartureLoadNature.value,
                arrivalLoadNatureId: parseInt(selectedMode.value) === truckMode.TRANSFER ? '' : selectedArrivalLoadNature.value,
                departureQuarryId:
                    parseInt(selectedMode.value) === truckMode.TRANSFER
                        ? ''
                        : selectedOrigin.group === capitalizeFirstLetter(t('quarries'))
                          ? selectedOrigin.value
                          : '',
                arrivalQuarryId:
                    parseInt(selectedMode.value) === truckMode.TRANSFER
                        ? ''
                        : selectedDestination.group === capitalizeFirstLetter(t('quarries'))
                          ? selectedDestination.value
                          : '',
                mode: parseInt(selectedMode.value),
                departureSiteId:
                    parseInt(selectedMode.value) === truckMode.TRANSFER
                        ? ''
                        : selectedOrigin.group === capitalizeFirstLetter(t('sites'))
                          ? selectedOrigin.value
                          : '',
                arrivalSiteId:
                    parseInt(selectedMode.value) === truckMode.TRANSFER
                        ? ''
                        : selectedDestination.group === capitalizeFirstLetter(t('sites'))
                          ? selectedDestination.value
                          : '',
                truckCompanyId: selectedCompany.value,
                truckId: selectedTruck.value,
                departureDumpId: parseInt(selectedMode.value) === truckMode.TRANSFER ? '' : selectedDepartureDump.value,
                arrivalDumpId: parseInt(selectedMode.value) === truckMode.TRANSFER ? '' : selectedArrivalDump.value,
                observations: observations
            },
        });
    };

    const removeDailyTransfer = async (transferId: string) => {
        await deleteDailyTransfer(transferId);
        refetch();
    };

    useEffect(() => {
        const dailyTruckPlanned = activity.dailyTrucksPlanned.find((value) => value._id === truckPlannedId);
        if (dailyTruckPlanned) {
            setObservations(dailyTruckPlanned.observations || '');
            setSelectedCompany({ label: dailyTruckPlanned.truckCompanyId?.designation || 'SLTP', value: dailyTruckPlanned.truckCompanyId?._id || '6688062ffd2728b140ab7f1b' });
            setSelectedDepartureLoadNature({
                label: dailyTruckPlanned.departureLoadNatureId?.designation || '',
                value: dailyTruckPlanned.departureLoadNatureId?._id || '',
            });
            setSelectedArrivalLoadNature({
                label: dailyTruckPlanned.arrivalLoadNatureId?.designation || '',
                value: dailyTruckPlanned.arrivalLoadNatureId?._id || '',
            });
            setSelectedMode({
                label:
                    capitalizeFirstLetter(
                        t(getTruckModeInfos(dailyTruckPlanned.mode !== undefined && dailyTruckPlanned.mode !== null ? dailyTruckPlanned.mode : -1))
                    ) || '',
                value: dailyTruckPlanned.mode?.toString() || '',
            });
            setSelectedOrigin({
                label: dailyTruckPlanned.departureQuarryId?.designation || dailyTruckPlanned.departureSiteId?.name || '',
                value: dailyTruckPlanned.departureQuarryId?._id || dailyTruckPlanned.departureSiteId?._id || '',
                group: dailyTruckPlanned.departureQuarryId ? capitalizeFirstLetter(t('quarries')) : capitalizeFirstLetter(t('sites')),
            });
            setSelectedDestination({
                label: dailyTruckPlanned.arrivalQuarryId?.designation || dailyTruckPlanned.arrivalSiteId?.name || '',
                value: dailyTruckPlanned.arrivalQuarryId?._id || dailyTruckPlanned.arrivalSiteId?._id || '',
                group: dailyTruckPlanned.arrivalQuarryId ? capitalizeFirstLetter(t('quarries')) : capitalizeFirstLetter(t('sites')),
            });
            setSelectedDepartureDump({
                value: dailyTruckPlanned.departureDumpId?._id || '',
                label: dailyTruckPlanned.departureDumpId?.designation || '',
            });
            setSelectedArrivalDump({
                value: dailyTruckPlanned.arrivalDumpId?._id || '',
                label: dailyTruckPlanned.arrivalDumpId?.designation || '',
            });
            setSelectedRidesAmount(dailyTruckPlanned.ridesAmount || 0);
            setSelectedTruck({ label: dailyTruckPlanned.truckId?.designation || '', value: dailyTruckPlanned.truckId?._id || '' });
            setSelectedTrucksAmount(dailyTruckPlanned.trucksAmount);
            setSelectedType({ label: dailyTruckPlanned.truckTypeId?.designation || '', value: dailyTruckPlanned.truckTypeId?._id || '' });
        } else {
            switch (truckType) {
                case '8x4':
                    setSelectedType({ label: truckType, value: x84Type });
                    break;
                case 'Ampliroll':
                    setSelectedType({ label: truckType, value: amplirollType });
                    break;
                case 'Semie':
                    setSelectedType({ label: truckType, value: semieType });
                    break;
                default:
                    break;
            }
        }
    }, []);

    return (
        <ModalBody
            title={!!currentTruckPlannedId.length ? t('modifyTrucks') : t('addTrucks')}
            okText={!!currentTruckPlannedId.length ? t('update') : t('create')}
            disabledOk={!selectedType.value.length || !selectedMode.value.length}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !!currentTruckPlannedId.length ? patchDailyTruckPlanned() : postDailyTruckPlanned();
            }}
            okCanClose={!!currentTruckPlannedId.length}
        >
            <div className="">
                <div className="fx-r fx-w fx-justify-sa">
                    <div>
                        <span>
                            {capitalizeFirstLetter(t('quantity'))} : {selectedTrucksAmount}
                        </span>
                        <RangeInput value={selectedTrucksAmount} onChange={(value) => setSelectedTrucksAmount(value)} min={1} max={30} />
                    </div>
                    <div>
                        <span>
                            {capitalizeFirstLetter(t('rides'))} : {selectedRidesAmount || '∅'}
                        </span>
                        <RangeInput value={selectedRidesAmount} onChange={(value) => setSelectedRidesAmount(value)} min={0} max={30} />
                    </div>
                    <DropDown
                        selectedItem={selectedCompany}
                        items={dataCompanies?.map((company) => ({ label: company.designation, value: company._id })) || []}
                        handleChange={(event, value) => setSelectedCompany({ value: value?.value || '', label: value?.label || '' })}
                        inputLabel={t('company')}
                        loading={isLoadingCompanies}
                    />
                    <DropDown
                        selectedItem={selectedType}
                        items={dataTrucksTypes?.map((type) => ({ label: type.designation, value: type._id })) || []}
                        handleChange={(event, value) => {
                            if (value?.value !== selectedType.value) setSelectedTruck({ value: '', label: '' });
                            setSelectedType({ value: value?.value || '', label: value?.label || '' });
                        }}
                        inputLabel={t('type')}
                        loading={isLoadingTrucksTypes}
                    />
                    {selectedCompany.label === 'SLTP' && !!selectedType.value.length && (
                        <DropDown
                            selectedItem={selectedTruck}
                            items={
                                dataTrucks
                                    ?.filter((truck) => {
                                        return (
                                            truck.truckCompanyId.designation.toLowerCase() === 'sltp' && truck.truckTypeId._id === selectedType.value
                                        );
                                    })
                                    .map((truck) => ({ label: truck.designation, value: truck._id })) || []
                            }
                            handleChange={(event, value) => setSelectedTruck({ value: value?.value || '', label: value?.label || '' })}
                            inputLabel={t('truck')}
                            loading={isLoadingTrucks}
                        />
                    )}
                    <DropDown
                        selectedItem={selectedMode}
                        items={Object.values(truckMode).map((mode) => ({
                            label: capitalizeFirstLetter(t(getTruckModeInfos(mode))),
                            value: mode.toString(),
                        }))}
                        handleChange={(event, value) => setSelectedMode({ value: value?.value || '', label: value?.label || '' })}
                        inputLabel={t('mode')}
                        sortItems={false}
                    />
                </div>
                {!!currentTruckPlannedId.length && (
                    <>
                        {[truckMode.APPRO, truckMode.POSE, truckMode.ROTATION, truckMode.DOUBLEFRET].includes(parseInt(selectedMode.value)) && (
                            <>
                                <div className="w-100 pad-15px">
                                    <div className="h-1px bg-lightgray w-100" />
                                </div>
                                <div className="flex-container">
                                    {selectedType.value === amplirollType && (
                                        <DropDown
                                            selectedItem={selectedDepartureDump}
                                            items={dataDumps?.map((dump) => ({ label: dump.designation, value: dump._id })) || []}
                                            handleChange={(event, value) =>
                                                setSelectedDepartureDump({ value: value?.value || '', label: value?.label || '' })
                                            }
                                            inputLabel={t('dump')}
                                        />
                                    )}
                                    <DropDown
                                        selectedItem={selectedDepartureLoadNature}
                                        items={dataLoadNatures?.map((nature) => ({ label: nature.designation, value: nature._id })) || []}
                                        handleChange={(event, value) =>
                                            setSelectedDepartureLoadNature({ value: value?.value || '', label: value?.label || '' })
                                        }
                                        inputLabel={t('loadNature')}
                                    />
                                    <DropDown
                                        selectedItem={selectedOrigin}
                                        items={
                                            dataSites
                                                ?.map((site) => ({ label: site.name, value: site._id, group: capitalizeFirstLetter(t('sites')) }))
                                                .concat(
                                                    dataQuarries?.map((quarry) => ({
                                                        label: quarry.designation,
                                                        value: quarry._id,
                                                        group: capitalizeFirstLetter(t('quarries')),
                                                    })) || []
                                                ) || []
                                        }
                                        handleChange={(event, value) =>
                                            setSelectedOrigin({
                                                value: value?.value || '',
                                                label: value?.label || '',
                                                group: value?.group || '',
                                            })
                                        }
                                        inputLabel={t('origin')}
                                        grouped
                                    />
                                </div>
                            </>
                        )}
                        {[truckMode.EVAC, truckMode.ENLEVEMENT, truckMode.ROTATION, truckMode.DOUBLEFRET].includes(parseInt(selectedMode.value)) && (
                            <>
                                <div className="w-100 pad-15px">
                                    <div className="h-1px bg-lightgray w-100" />
                                </div>
                                <div className="flex-container">
                                    {selectedType.value === amplirollType && (
                                        <DropDown
                                            selectedItem={selectedArrivalDump}
                                            items={dataDumps?.map((dump) => ({ label: dump.designation, value: dump._id })) || []}
                                            handleChange={(event, value) =>
                                                setSelectedArrivalDump({ value: value?.value || '', label: value?.label || '' })
                                            }
                                            inputLabel={t('dump')}
                                        />
                                    )}
                                    <DropDown
                                        selectedItem={selectedArrivalLoadNature}
                                        items={dataLoadNatures?.map((nature) => ({ label: nature.designation, value: nature._id })) || []}
                                        handleChange={(event, value) =>
                                            setSelectedArrivalLoadNature({ value: value?.value || '', label: value?.label || '' })
                                        }
                                        inputLabel={t('loadNature')}
                                    />
                                    <DropDown
                                        selectedItem={selectedDestination}
                                        items={
                                            dataSites
                                                ?.map((site) => ({ label: site.name, value: site._id, group: capitalizeFirstLetter(t('sites')) }))
                                                .concat(
                                                    dataQuarries?.map((quarry) => ({
                                                        label: quarry.designation,
                                                        value: quarry._id,
                                                        group: capitalizeFirstLetter(t('quarries')),
                                                    })) || []
                                                ) || []
                                        }
                                        handleChange={(event, value) =>
                                            setSelectedDestination({
                                                value: value?.value || '',
                                                label: value?.label || '',
                                                group: value?.group || '',
                                            })
                                        }
                                        inputLabel={t('destination')}
                                        grouped
                                    />
                                </div>
                            </>
                        )}

                        <div className="fx-r fx-w fx-justify-sa">
                        <div className="w-100 pad-15px">
                                    <div className="h-1px bg-lightgray w-100" />
                                </div>
                            <CustomTextInput value={observations} onChange={(text) => setObservations(text)} label={t('observations')} multiline />
                        </div>

                        {parseInt(selectedMode.value) === truckMode.TRANSFER && (
                            <>
                                <div className="w-100 pad-15px">
                                    <div className="h-1px bg-lightgray w-100" />
                                </div>
                                <div className="fx-r w-100">
                                    <div className="pad-r-10px">
                                        <Icon
                                            name="addCircle"
                                            onClick={() => {
                                                setShowModal(true);
                                                setSelectedTransfer(null);
                                            }}
                                            title={capitalizeFirstLetter(t('add'))}
                                        />
                                    </div>
                                    <div className="w-100 max-h-300px of-y-auto">
                                        {activity.dailyTrucksPlanned
                                            .find((value) => value._id === currentTruckPlannedId)
                                            ?.transfers.map((transfer, index) => (
                                                <div
                                                    className="border-radius-5px min-h-25px fx-r pad-l-5px fx-items-fs"
                                                    style={{ backgroundColor: getMaterialStateColor(transfer.state), marginTop: index === 0 ? 0 : 5 }}
                                                >
                                                    <div
                                                        onDoubleClick={() => {
                                                            setShowModal(true);
                                                            setSelectedTransfer(transfer);
                                                        }}
                                                    >
                                                        <div className="one-line-container">
                                                            <div className="one-line">
                                                                {transfer.machineId?.designation ||
                                                                    transfer.toolId?.designation ||
                                                                    transfer.rentalName + ' ' + transfer.rentalCompanyId?.designation}
                                                            </div>
                                                        </div>
                                                        <div className="one-line-container">
                                                            <div className="one-line">
                                                                <b>{transfer.state === materialState.NEW ? t('from') + ' : ' : t('to') + ' : '}</b>
                                                                {transfer.siteId?.name || transfer.quarryId?.designation}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Icon name="close" onClick={() => removeDailyTransfer(transfer._id)} />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            {showModal && (
                <ManageDailyTransferModal
                    activity={activity}
                    handleClose={() => setShowModal(false)}
                    open={showModal}
                    refetch={refetch}
                    truckPlannedId={currentTruckPlannedId}
                    transfer={selectedTransfer}
                />
            )}
        </ModalBody>
    );
};

export default ManageDailyTruckPlannedModal;
