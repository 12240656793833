import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailySubContractor } from '../../services/apiSLTP/planning';
import { DataItem } from '../../services/apiSLTP/activities';

interface SubContractorsTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const SubContractorsTable: React.FC<SubContractorsTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const filterActivities = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (activity: DataItem) => {
            const {
                siteId,
                dailySubContractors,
            } = activity;

            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(siteId?.name) ||
                dailySubContractors.some(
                    (subContractor) =>
                        matchesSearchTerm(subContractor.subContractorTypeId.designation) || matchesSearchTerm(subContractor.durationDay.toString())
                )
            );
        };

        return activities.filter(matchesFilter);
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {activities.map((activity) =>
                        activity.dailySubContractors.map((subContractor, index) => (
                            <tr key={subContractor._id} className="row">
                                <td className="pos-sy left-0 z-idx-2">{activity.siteId.name}</td>
                                <td className="row-cell-codeproc">{subContractor.subContractorTypeId.designation}</td>
                                <td className="row-cell-designation">{subContractor.durationDay}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default SubContractorsTable;
