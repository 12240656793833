import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadNature } from '../../services/apiSLTP/materials/loadNatures';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateLoadNatureMutation } from '../../hooks/mutations/useLoadNatureMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateLoadNatureModal from '../modals/UpdateLoadNatureModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';

interface LoadNaturesSettingsRowProps {
    loadNatures: LoadNature[];
    setLoadNatures: React.Dispatch<React.SetStateAction<LoadNature[]>>;
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    refetch: () => void;
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const LoadNaturesSettingsRow: React.FC<LoadNaturesSettingsRowProps> = ({
    setLoadNatures,
    loadNatures,
    isLoading,
    isError,
    isSuccess,
    refetch,
    universalFilter,
    setAddFtn,
}) => {
    const emptyLoadNature: LoadNature = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLoadNature, setSelectedLoadNature] = useState<LoadNature>(emptyLoadNature);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const {user} = useAuth();

    const {
        mutate: mutateLoadNature,
        isSuccess: isSuccessLoadNature,
        isError: isErrorLoadNature,
        isLoading: isLoadingLoadNature,
        error: errorLoadNature,
        data: dataLoadNature,
    } = useUpdateLoadNatureMutation({
        onSuccess: (data, variables) => {
            {
                /*setLoadNatures((prevLoadNatures) => {
                const newLoadNatures = [...prevLoadNatures];
                const tmpIndex = newLoadNatures.findIndex((value) => value._id === variables.loadNatureId);
                newLoadNatures[tmpIndex].crudStatus = crudStatus.DELETED;
                return newLoadNatures;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating loadNature:', error.message);
        },
    });

    const filterLoadNatures = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (loadNature: LoadNature) => {

            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(loadNature?.designation)
            );
        };
        return loadNatures.filter(matchesFilter);
    };

    const sortedLoadNatures = () => {
        return (
            loadNatures
                .sort((a, b) => {
                    const typeA = a?.designation;
                    const typeB = b?.designation;
                    if (typeA < typeB) return -1;
                    if (typeA > typeB) return 1;
                    const designationA = a.designation;
                    const designationB = b.designation;
                    if (designationA < designationB) return -1;
                    if (designationA > designationB) return 1;
                    return 0;
                })
        );
    };

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedLoadNature(emptyLoadNature);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-30px pos-sy left-0 4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedLoadNatures().map((loadNature, index, array) => (
                            <tr key={loadNature._id}>
                                <td scope="row">{loadNature.designation}</td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedLoadNature(loadNature);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={loadNature.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && <Icon
                                        name="delete"
                                        onClick={() => {
                                            setSelectedLoadNature(loadNature);
                                            setShowDeleteModal(true);
                                        }}
                                        disabled={loadNature.crudStatus === crudStatus.DELETED}
                                    />}
                                </td>
                                <td>
                                <div className="fx-r fx-justify-c">
                                    <StateBadge backgroundColor={getStatusColor(loadNature.crudStatus)} title={capitalizeFirstLetter(t(getCrudStatusInfos(loadNature.crudStatus)))} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateLoadNatureModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    loadNature={selectedLoadNature}
                    loadNatures={loadNatures}
                    setLoadNatures={setLoadNatures}
                    isEditMode={isEditMode}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateLoadNature({ loadNatureId: selectedLoadNature._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedLoadNature?.designation}
                />
            )}
        </div>
    );
};

export default LoadNaturesSettingsRow;
