import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRentalsQuery } from '../../hooks/queries/useRentalsQuery';
import { Rental } from '../../services/apiSLTP/materials/rentals';
import { crudStatus, getCrudStatusInfos, permissionLevels } from '../../utils/enums';
import { useUpdateRentalMutation } from '../../hooks/mutations/useRentalMutation';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import DeleteModal from '../modals/DeleteModal';
import UpdateRentalModal from '../modals/UpdateRentalModal';
import { getStatusColor } from '../../utils/colors';
import Icon from '../Icon';
import StateBadge from '../StateBadge';
import { useAuth } from '../../hooks/useAuth';

interface RentalsSettingsRowProps {
    universalFilter: string;
    setAddFtn: (ftn: () => void) => void;
}

const RentalsSettingsRow: React.FC<RentalsSettingsRowProps> = ({ universalFilter, setAddFtn }) => {
    const emptyRental: Rental = {
        _id: '',
        crudStatus: crudStatus.OK,
        designation: '',
    };
    const [rentals, setRentals] = useState<Rental[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRental, setSelectedRental] = useState<Rental>(emptyRental);
    const [isEditMode, setIsEditMode] = useState(false);
    const { t } = useTranslation();
    const {user} = useAuth();

    const { data, isLoading, isError, isSuccess, refetch } = useRentalsQuery({});
    const {
        mutate: mutateRental,
        isSuccess: isSuccessRental,
        isError: isErrorRental,
        isLoading: isLoadingRental,
        error: errorRental,
        data: dataRental,
    } = useUpdateRentalMutation({
        onSuccess: (data, variables) => {
            {
                /*setRentals((prevRentals) => {
                const newRentals = [...prevRentals];
                const tmpIndex = newRentals.findIndex((value) => value._id === variables.rentalId);
                newRentals[tmpIndex].crudStatus = crudStatus.DELETED;
                return newRentals;
            });*/
            }
        },
        onError: (error) => {
            console.error('Error updating rental:', error.message);
        },
    });

    const filterRentals = () => {
        const searchTerm = universalFilter.toLowerCase().trim();

        const matchesSearchTerm = (field: string | undefined) => field?.toLowerCase().includes(searchTerm);

        const matchesFilter = (rental: Rental) => {

            return (
                searchTerm.length === 0 ||
                matchesSearchTerm(rental?.designation)
            );
        };
        return rentals.filter(matchesFilter);
    };

    const sortedRentals = () => {
        return rentals.sort((a, b) => {
            const designationA = a?.designation;
            const designationB = b?.designation;
            if (designationA < designationB) return -1;
            if (designationA > designationB) return 1;
            return 0;
        });
    };

    useEffect(() => {
        if (data && isSuccess) {
            setRentals(data);
        }
    }, [data]);

    useEffect(() => {
        setAddFtn(() => {
            return () => {
                setSelectedRental(emptyRental);
                setIsEditMode(false);
                setShowEditModal(true);
            };
        });
    }, []);

    return (
        <div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr className="">
                            <th scope="col" className="w-200px pos-sy left-0 4">
                                {capitalizeFirstLetter(t('name'))}
                            </th>
                            <th scope="col" className="w-100px">
                                {capitalizeFirstLetter(t('actions'))}
                            </th>
                            <th scope="col" className="w-60px">
                                <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('active'))}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="fu-planning-obj">
                        {sortedRentals().map((rental, index, array) => (
                            <tr key={rental._id}>
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {rental.designation}
                                </td>
                                <td>
                                    <Icon
                                        name="edit"
                                        onClick={() => {
                                            setSelectedRental(rental);
                                            setIsEditMode(true);
                                            setShowEditModal(true);
                                        }}
                                        disabled={rental.crudStatus === crudStatus.DELETED}
                                    />
                                    {user && user.profileId.permsLevel <= permissionLevels.SUPERVISION && <Icon
                                        name="delete"
                                        onClick={() => {
                                            setSelectedRental(rental);
                                            setShowDeleteModal(true);
                                        }}
                                        disabled={rental.crudStatus === crudStatus.DELETED}
                                    />}
                                </td>
                                <td>
                                    <div className="fx-r fx-justify-c txt-align-c">
                                        <StateBadge backgroundColor={getStatusColor(rental.crudStatus)} title={capitalizeFirstLetter(t(getCrudStatusInfos(rental.crudStatus)))} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <UpdateRentalModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    isEditMode={isEditMode}
                    rental={selectedRental}
                    rentals={rentals}
                    setRentals={setRentals}
                    refetch={() => refetch()}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    deleteAction={() => {
                        mutateRental({ rentalId: selectedRental._id, data: { crudStatus: crudStatus.DELETED } });
                    }}
                    verificationWord={selectedRental.designation}
                />
            )}
        </div>
    );
};

export default RentalsSettingsRow;
