import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import DropDown from '../DropDown';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { Site } from '../../services/apiSLTP/planning';
import { crudStatus } from '../../utils/enums';
import { User } from '../../services/apiSLTP/users';
import { Entity } from '../../services/apiSLTP/sites/entities';
import { useCreateSiteMutation, useUpdateSiteMutation } from '../../hooks/mutations/useSiteMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    site: Site;
    sites: Site[];
    setSites: React.Dispatch<React.SetStateAction<Site[]>>;
    users: User[];
    entities: Entity[];
    showEditModal: boolean;
    isEditMode?: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateSiteModal: React.FC<ModalProps> = ({
    site,
    setSites,
    showEditModal,
    setShowEditModal,
    users,
    entities,
    sites,
    isEditMode = true,
    refetch,
}) => {
    const { t } = useTranslation();
    const [tmpSite, setTmpSite] = useState(site);
    const [selectedUser, setSelectedUser] = useState({
        label: '',
        value: '',
    });
    const [selectedEntity, setSelectedEntity] = useState({
        label: '',
        value: '',
    });

    const { user } = useAuth();

    const {
        mutate: mutateSite,
        isSuccess: isSuccessSite,
        isError: isErrorSite,
        isLoading: isLoadingSite,
        error: errorSite,
        data: dataSite,
    } = useUpdateSiteMutation({
        onSuccess: (data, variables) => {
            {
                /*setSites((prevSites) => {
                const newSites = [...prevSites];
                const index = sites.findIndex((value) => value._id === site._id);
                newSites[index] = data.data; // change here
                return newSites;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating site:', error.message);
        },
    });

    const {
        mutate: addSite,
        isSuccess: isSuccessAdd,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateSiteMutation({
        onSuccess: (data, variables) => {
            {
                /*setSites((prevSites) => {
                const newSites = [...prevSites];
                newSites.push(data.data); // change here
                return newSites;
            });*/
            }
            refetch();
        },
        onError: (error) => {
            console.error('Error updating site:', error.message);
        },
    });

    const handleNameChange = (text: string) => {
        setTmpSite((prevSite) => {
            const newSite = { ...prevSite };
            newSite.name = text;
            return newSite;
        });
    };

    const handleCodeProCChange = (text: string) => {
        setTmpSite((prevSite) => {
            const newSite = { ...prevSite };
            newSite.codeProC = text;
            return newSite;
        });
    };

    const handleAddressChange = (text: string) => {
        setTmpSite((prevSite) => {
            const newSite = { ...prevSite };
            newSite.address = text;
            return newSite;
        });
    };

    const verifyName = () => {
        return true;
    };

    const verifyCodeProC = () => {
        if (
            sites
                .filter((value) => value._id !== tmpSite._id)
                .map((value) => value.codeProC)
                .includes(tmpSite.codeProC)
        )
            return false;
        return true;
    };

    const verifyAddress = () => {
        return true;
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpSite((prevSite) => {
                const newSite = { ...prevSite };
                newSite.crudStatus = crudStatus.OK;
                return newSite;
            });
        } else {
            setTmpSite((prevSite) => {
                const newSite = { ...prevSite };
                newSite.crudStatus = crudStatus.INACTIVE;
                return newSite;
            });
        }
    };

    const handleFavoriteChange = () => {
        setTmpSite((prevSite) => {
            const newSite = { ...prevSite };
            newSite.isFavorite = !newSite.isFavorite;
            return newSite;
        });
    };

    useEffect(() => {
        const user = users.find((value) => value?._id === tmpSite.conducId?._id);
        setSelectedUser({ label: `${user?.firstName} ${user?.lastName}`, value: `${user?._id || ''}` });
        const entity = entities.find((value) => value?._id === tmpSite?.entityId);
        setSelectedEntity({ label: `${entity?.designation}`, value: `${entity?._id}` });
    }, []);

    return (
        <ModalBody
            title={isEditMode ? t('updateSite') : t('createSite')}
            closeText={t('cancel')}
            okText={isEditMode ? t('validate') : t('create')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!verifyName() || !verifyCodeProC() || !verifyAddress()}
            handleOk={() => {
                isEditMode
                    ? mutateSite({
                          siteId: site._id,
                          data: {
                              address: tmpSite.address,
                              codeProC: tmpSite.codeProC,
                              name: tmpSite.name,
                              crudStatus: tmpSite.crudStatus,
                              conducId: selectedUser.value || '',
                              entityId: selectedEntity.value || '',
                          },
                      })
                    : addSite({
                          address: tmpSite.address,
                          codeProC: tmpSite.codeProC,
                          name: tmpSite.name,
                          conducId: selectedUser.value,
                          entityId: selectedEntity.value,
                          startTime: new Date(new Date().setHours(7)).toISOString(),
                      });
            }}
        >
            <CustomTextInput value={tmpSite.name} label={t('name')} onChange={handleNameChange} errorText={t('error')} />
            {isEditMode && (
                <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                    <div>{capitalizeFirstLetter(t('active'))}</div>
                    <CustomToggle checked={tmpSite.crudStatus === crudStatus.OK} onChange={handleActiveChange} />
                </div>
            )}
            <CustomTextInput
                value={tmpSite.codeProC}
                label={t('codeProC')}
                onChange={handleCodeProCChange}
                //error={!verifyCodeProC()}
                errorText={t('error')}
            />
            <CustomTextInput
                value={tmpSite.address || ''}
                onChange={handleAddressChange}
                label={t('address')}
                //error={!verifyAddress()}
                errorText={t('error')}
            />

            <DropDown
                items={users.map((user, index) => ({ label: `${user.firstName} ${user?.lastName}`, value: user._id }))}
                selectedItem={selectedUser}
                handleChange={(event, value) => setSelectedUser({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('conduc')}
            />
            <DropDown
                items={entities.map((entity, index) => ({ label: `${entity.designation}`, value: entity._id }))}
                selectedItem={selectedEntity}
                handleChange={(event, value) => setSelectedEntity({ value: value?.value || '', label: value?.label || '' })}
                inputLabel={t('entity')}
            />
            <div className="fx-r fx-items-c fx-justify-sb pad-5px">
                <div>{capitalizeFirstLetter(t('favorite'))}</div>
                <CustomToggle checked={tmpSite.isFavorite || false} onChange={handleFavoriteChange} className="checkbox-star" />
            </div>
        </ModalBody>
    );
};

export default UpdateSiteModal;
